import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ProductsService } from '../Service/products.service';
import { catchError, Subscription, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ResponseMessage } from '../../../../model/interfaces/req.res.interface';
import { TranslateModule } from '@ngx-translate/core';
import { ConstantService } from '../../../../shared/services/constant.service';

@Component({
  selector: 'app-product-view',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    RouterModule,
    TranslateModule
  ],
  templateUrl: './product-view.component.html',
  styleUrls: ['./product-view.component.scss']
})
export class ProductViewComponent implements OnInit {

  subscribeGetAPI!: Subscription;
  productDetail: any;
  productId = 0;
  private productService = inject(ProductsService);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private toastr = inject(ToastrService);
  private constantService = inject(ConstantService);
  constructor() { }

  ngOnInit() {
    const id = this.route.snapshot.params['id'];
    if (id) {
      this.constantService.progressLoader.set(true);
      this.getProductDetail(id);
    }
  }

  getProductDetail(id: number) {
    if (this.subscribeGetAPI) this.subscribeGetAPI.unsubscribe();
    this.subscribeGetAPI = this.productService.getProductDetail(id).pipe(
      catchError((error) => {
        this.constantService.progressLoader.set(false);
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message)
      })).subscribe((res: ResponseMessage) => {
        this.constantService.progressLoader.set(false);
        if (res.code === 200) {
          this.productDetail = res.data;
        } else {
          this.toastr.error(res.message);
        }
      })
  }

  backToProductList() {
    this.router.navigate(['/product-list']);
  }

}
