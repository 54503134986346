import { DOCUMENT } from '@angular/common';
import { Component, inject, Inject, signal } from '@angular/core';
import { RouterLink, RouterModule } from '@angular/router';
import { LangType, ThemeType } from './model/types/GlobalTypes';
import { ConstantService } from './shared/services/constant.service';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  imports: [RouterLink, RouterModule],
  standalone: true
})
export class AppComponent {

  private constantService = inject(ConstantService);

  themeType = signal<ThemeType>('light');
  langType = signal<LangType>('en'); // Default es but temp eng

  //#region Localstorage 
  language = 'selected_language';
  theme = 'selected_theme';
  constructor(@Inject(DOCUMENT) private document: Document) {
    this.initializeTheme();
    this.initializeLang();
  }

  /**
   * Initial theme settings
   */
  private initializeTheme(): void {
    const storedTheme = localStorage.getItem(this.theme) as ThemeType;
    const theme = storedTheme || 'light';
    this.constantService.setTheme(theme);
  }

  /**
   * Initial language settings
   */
  private initializeLang(): void {
    let storedLang = localStorage.getItem(this.language) as LangType;
    let lang:any = storedLang?storedLang: environment.defaultLang; // Default es but temp eng
    this.constantService.translateData(lang);
    this.constantService.setLang(lang);   
  }


  ngOnInit() {
    document.documentElement.style.setProperty(
      '--app-height',
      `${window.innerHeight}px`
    );
    window.addEventListener('resize', () => {
      document.documentElement.style.setProperty(
        '--app-height',
        `${window.innerHeight}px`
      );
    });
  }
}
