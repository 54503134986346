import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { SuccessMessageComponent } from '../success-message/success-message.component';
import { UpdateListComponent } from '../update-list/update-list.component';
import { DeleteWaitingComponent } from '../delete-waiting/delete-waiting.component';
export interface PeriodicElement {
  customerName: string;
  mobileNo: string;
  guestNo: string;
  queueNo: string;
  event: string
}

const ELEMENT_DATA: PeriodicElement[] = [
  {
    customerName:
      'Carla Kenter',
    mobileNo: '(406) 555-0120',
    guestNo: '2',
    queueNo: '2',
    event: 'Birthday'
  },
  {
    customerName:
      'Carla Kenter',
    mobileNo: '(406) 555-0120',
    guestNo: '2',
    queueNo: '2',
    event: 'Birthday'
  },
  {
    customerName:
      'Carla Kenter',
    mobileNo: '(406) 555-0120',
    guestNo: '2',
    queueNo: '2',
    event: 'Birthday'
  },
  {
    customerName:
      'Carla Kenter',
    mobileNo: '(406) 555-0120',
    guestNo: '2',
    queueNo: '2',
    event: 'Birthday'
  },
  {
    customerName:
      'Carla Kenter',
    mobileNo: '(406) 555-0120',
    guestNo: '2',
    queueNo: '2',
    event: 'Birthday'
  },
  {
    customerName:
      'Carla Kenter',
    mobileNo: '(406) 555-0120',
    guestNo: '2',
    queueNo: '2',
    event: 'Birthday'
  },
  {
    customerName:
      'Carla Kenter',
    mobileNo: '(406) 555-0120',
    guestNo: '2',
    queueNo: '2',
    event: 'Birthday'
  },
  {
    customerName:
      'Carla Kenter',
    mobileNo: '(406) 555-0120',
    guestNo: '2',
    queueNo: '2',
    event: 'Birthday'
  },
  {
    customerName:
      'Carla Kenter',
    mobileNo: '(406) 555-0120',
    guestNo: '2',
    queueNo: '2',
    event: 'Birthday'
  },
  {
    customerName:
      'Carla Kenter',
    mobileNo: '(406) 555-0120',
    guestNo: '2',
    queueNo: '2',
    event: 'Birthday'
  },
  {
    customerName:
      'Carla Kenter',
    mobileNo: '(406) 555-0120',
    guestNo: '2',
    queueNo: '2',
    event: 'Birthday'
  },
];
@Component({
  selector: 'app-waiting-list',
  standalone: true,
  imports: [
    MatButtonModule,
    MatInputModule,
    MatTableModule,
    RouterModule
  ],
  templateUrl: './waiting-list.component.html',
  styleUrl: './waiting-list.component.scss'
})
export class WaitingListComponent {
  displayedColumns: string[] = ['customerName','queueNo',  'guestNo', 'mobileNo', 'event', 'action'];
  dataSource = ELEMENT_DATA;

  readonly dialog = inject(MatDialog);
  openDialog() {
    const dialogRef = this.dialog.open(SuccessMessageComponent, {
      width: '564px',
      panelClass: ['modal-wrapper'],
      scrollStrategy: new NoopScrollStrategy()
    });
  }
 
  openDialogEdit() {
    const dialogRef = this.dialog.open(UpdateListComponent, {
      width: '564px',
      panelClass: ['modal-wrapper'],
      scrollStrategy: new NoopScrollStrategy()
    });
  }
  openDialogDelete() {
    const dialogRef = this.dialog.open(DeleteWaitingComponent, {
      width: '564px',
      panelClass: ['modal-wrapper'],
      scrollStrategy: new NoopScrollStrategy()
    });
  }
}
