<div class="flex flex-justify-between flex-wrap gap-8px mb-16 flex-align-center section-box">
  <h1 class="mb-0 mobile-full h3 fw-bold">Menu</h1>
  <div class="flex gap-16px flex-grow-1 flex-justify-end">
    <mat-form-field appearance="outline" subscriptSizing="dynamic" class="small-input search-inputbx">
      <div class="w-full relative input-rightspace">
        <input type="search" class="form-control" matInput placeholder="Search Menu"  />
        <mat-icon class="ic-input cursor-pointer">search</mat-icon>
      </div>
    </mat-form-field>
    <button mat-flat-button class="flex-shrink-0 createmenu-btn" >Create Menu</button>
  </div>
</div>

<div class="flex flex-wrap filterbox-wrapper flex-justify-end">
  <mat-form-field subscriptSizing="dynamic" class="filter-category filterbox select-bgwrap">
    <mat-select placeholder="Category">
      <mat-option value="option1">Option 1</mat-option>
      <mat-option value="option2">Option 2</mat-option>
      <mat-option value="option3">Option 3</mat-option>
      <mat-option value="option3">Option 4</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field subscriptSizing="dynamic" class="filter-location filterbox select-bgwrap">
    <mat-select placeholder="Locations">
      <mat-option value="option1">Option 1</mat-option>
      <mat-option value="option2">Option 2</mat-option>
      <mat-option value="option3">Option 3</mat-option>
      <mat-option value="option3">Option 4</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field subscriptSizing="dynamic" class="filter-status filterbox select-bgwrap">
    <mat-select placeholder="Inventory Status">
      <mat-option value="option1">Option 1</mat-option>
      <mat-option value="option2">Option 2</mat-option>
      <mat-option value="option3">Option 3</mat-option>
      <mat-option value="option3">Option 4</mat-option>
    </mat-select>
  </mat-form-field>
</div>

<!-- Starts Food List Section -->
<div class="menu-listwrap">
  <div class="menu-item text-decoration-none">
    <button mat-fab aria-label="Edit" class="edit-productbtn" routerLink="/food-menu/edit">
      <span class="icon-edit-pencil"></span>
    </button>
    <div class="image-box">
    <div class="flex product-imagewrap">
      <div class="product-img">
        <img src="https://images.pexels.com/photos/6261272/pexels-photo-6261272.jpeg?auto=compress&cs=tinysrgb&w=600" alt="American Style Burger" class="img-fit" />
      </div>
    </div>
    <div class="curve-wrapper"><div class="curve"></div></div>
  </div>
    <div class="product-textwrap block">
      <h5 class="mb-4 text-white fw-semibold">Soft Drinks Combo</h5>
    </div>
    <div class="items-shape"></div>
  </div>

  <div class="menu-item text-decoration-none">
    <button mat-fab aria-label="Edit" class="edit-productbtn" routerLink="/food-menu/edit">
      <span class="icon-edit-pencil"></span>
    </button>
    <div class="image-box">
    <div class="flex product-imagewrap">
      <div class="product-img">
        <img src="https://images.pexels.com/photos/699953/pexels-photo-699953.jpeg?auto=compress&cs=tinysrgb&w=600" alt="American Style Burger" class="img-fit" />
      </div>
    </div>
    <div class="curve-wrapper"><div class="curve"></div></div>
  </div>
    <div class="product-textwrap block">
      <h5 class="mb-4 text-white fw-semibold">Soft Drinks Combo</h5>
    </div>
    <div class="items-shape"></div>
  </div>
 
  <div class="menu-item text-decoration-none">
    <button mat-fab aria-label="Edit" class="edit-productbtn" routerLink="/food-menu/edit">
      <span class="icon-edit-pencil"></span>
    </button>
    <div class="image-box">
    <div class="flex product-imagewrap">
      <div class="product-img">
        <img src="https://images.pexels.com/photos/1199957/pexels-photo-1199957.jpeg?auto=compress&cs=tinysrgb&w=600" alt="American Style Burger" class="img-fit" />
      </div>
    </div>
    <div class="curve-wrapper"><div class="curve"></div></div>
  </div>
    <div class="product-textwrap block">
      <h5 class="mb-4 text-white fw-semibold">Soft Drinks Combo</h5>
    </div>
    <div class="items-shape"></div>
  </div>

  <div class="menu-item text-decoration-none">
    <button mat-fab aria-label="Edit" class="edit-productbtn" routerLink="/food-menu/edit">
      <span class="icon-edit-pencil"></span>
    </button>
    <div class="image-box">
    <div class="flex product-imagewrap">
      <div class="product-img">
        <img src="https://images.pexels.com/photos/291528/pexels-photo-291528.jpeg?auto=compress&cs=tinysrgb&w=600" alt="American Style Burger" class="img-fit" />
      </div>
    </div>
    <div class="curve-wrapper"><div class="curve"></div></div>
  </div>
    <div class="product-textwrap block">
      <h5 class="mb-4 text-white fw-semibold">Soft Drinks Combo</h5>
    </div>
    <div class="items-shape"></div>
  </div>
 
  <div class="menu-item text-decoration-none">
    <button mat-fab aria-label="Edit" class="edit-productbtn" routerLink="/food-menu/edit">
      <span class="icon-edit-pencil"></span>
    </button>
    <div class="image-box">
    <div class="flex product-imagewrap">
      <div class="product-img">
        <img src="https://images.pexels.com/photos/808941/pexels-photo-808941.jpeg?auto=compress&cs=tinysrgb&w=600" alt="American Style Burger" class="img-fit" />
      </div>
    </div>
    <div class="curve-wrapper"><div class="curve"></div></div>
  </div>
    <div class="product-textwrap block">
      <h5 class="mb-4 text-white fw-semibold">Soft Drinks Combo</h5>
    </div>
    <div class="items-shape"></div>
  </div>
 
  <div class="menu-item text-decoration-none">
    <button mat-fab aria-label="Edit" class="edit-productbtn" routerLink="/food-menu/edit">
      <span class="icon-edit-pencil"></span>
    </button>
    <div class="image-box">
    <div class="flex product-imagewrap">
      <div class="product-img">
        <img src="https://images.pexels.com/photos/1583884/pexels-photo-1583884.jpeg?auto=compress&cs=tinysrgb&w=600" alt="American Style Burger" class="img-fit" />
      </div>
    </div>
    <div class="curve-wrapper"><div class="curve"></div></div>
  </div>
    <div class="product-textwrap block">
      <h5 class="mb-4 text-white fw-semibold">Soft Drinks Combo</h5>
    </div>
    <div class="items-shape"></div>
  </div>
 
  <div class="menu-item text-decoration-none">
    <button mat-fab aria-label="Edit" class="edit-productbtn" routerLink="/food-menu/edit">
      <span class="icon-edit-pencil"></span>
    </button>
    <div class="image-box">
    <div class="flex product-imagewrap">
      <div class="product-img">
        <img src="https://images.pexels.com/photos/3186654/pexels-photo-3186654.jpeg?auto=compress&cs=tinysrgb&w=600" alt="American Style Burger" class="img-fit" />
      </div>
    </div>
    <div class="curve-wrapper"><div class="curve"></div></div>
  </div>
    <div class="product-textwrap block">
      <h5 class="mb-4 text-white fw-semibold">Soft Drinks Combo</h5>
    </div>
    <div class="items-shape"></div>
  </div>
  
  <div class="menu-item text-decoration-none">
    <button mat-fab aria-label="Edit" class="edit-productbtn" routerLink="/food-menu/edit">
      <span class="icon-edit-pencil"></span>
    </button>
    <div class="image-box">
    <div class="flex product-imagewrap">
      <div class="product-img">
        <img src="https://images.pexels.com/photos/1854652/pexels-photo-1854652.jpeg?auto=compress&cs=tinysrgb&w=600" alt="American Style Burger" class="img-fit" />
      </div>
    </div>
    <div class="curve-wrapper"><div class="curve"></div></div>
  </div>
    <div class="product-textwrap block">
      <h5 class="mb-4 text-white fw-semibold">Soft Drinks Combo</h5>
    </div>
    <div class="items-shape"></div>
  </div>
  
  <div class="menu-item text-decoration-none">
    <button mat-fab aria-label="Edit" class="edit-productbtn" routerLink="/food-menu/edit">
      <span class="icon-edit-pencil"></span>
    </button>
    <div class="image-box">
    <div class="flex product-imagewrap">
      <div class="product-img">
        <img src="https://images.pexels.com/photos/3434523/pexels-photo-3434523.jpeg?auto=compress&cs=tinysrgb&w=600" alt="American Style Burger" class="img-fit" />
      </div>
    </div>
    <div class="curve-wrapper"><div class="curve"></div></div>
  </div>
    <div class="product-textwrap block">
      <h5 class="mb-4 text-white fw-semibold">Soft Drinks Combo</h5>
    </div>
    <div class="items-shape"></div>
  </div>

  <div class="menu-item text-decoration-none">
    <button mat-fab aria-label="Edit" class="edit-productbtn" routerLink="/food-menu/edit">
      <span class="icon-edit-pencil"></span>
    </button>
    <div class="image-box">
    <div class="flex product-imagewrap">
      <div class="product-img">
        <img src="https://images.pexels.com/photos/588776/pexels-photo-588776.jpeg?auto=compress&cs=tinysrgb&w=600" alt="American Style Burger" class="img-fit" />
      </div>
    </div>
    <div class="curve-wrapper"><div class="curve"></div></div>
  </div>
    <div class="product-textwrap block">
      <h5 class="mb-4 text-white fw-semibold">Soft Drinks Combo</h5>
    </div>
    <div class="items-shape"></div>
  </div>

</div>
<!-- Ends Food List Section -->

<!-- HTML for Product Curve Design -->
<svg style="visibility: hidden; position: absolute;" width="0" height="0" xmlns="http://www.w3.org/2000/svg" version="1.1">
  <defs>
        <filter id="goo"><feGaussianBlur in="SourceGraphic" stdDeviation="8" result="blur" />    
            <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="goo" />
            <feComposite in="SourceGraphic" in2="goo" operator="atop"/>
        </filter>
    </defs>
</svg>
<!-- Ends HTML for Product Curve Design -->