
<div class="section-box mb-24 flex flex-wrap flex-justify-between flex-align-center header-section gap-8px header-box">
  <h1 class="text-white mb-0 mobile-full text-truncate">Reservation Management</h1>
  <div class="flex filter-wrapper flex-align-center flex-grow-1 flex-justify-end">
    <mat-form-field subscriptSizing="dynamic" class="filter-selection">
      <mat-select placeholder="Filter by">
        <mat-option value="option1">Option 1</mat-option>
        <mat-option value="option2" disabled>Option 2</mat-option>
        <mat-option value="option3">Option 3</mat-option>
      </mat-select>
    </mat-form-field>
    <div class="flex gap-16px flex-grow-1 flex-justify-end flex-align-center w-full search-catwrapper">
      <mat-form-field appearance="outline" subscriptSizing="dynamic" class="small-input search-inputbx">
        <div class="w-full relative input-rightspace">
          <input type="search" class="form-control" matInput placeholder="Search by Customer Name"  />
          <mat-icon class="ic-input cursor-pointer">search</mat-icon>
        </div>
      </mat-form-field>
      <a mat-flat-button class="flex-shrink-0 btn-lg" routerLink="/reservation/add">Add Reservation</a>
    </div>
  </div>
</div>

    <div class="table-responsive custom-scroll table-wrapper">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

        <!-- Position Column -->
        <ng-container matColumnDef="customerName">
          <th mat-header-cell *matHeaderCellDef>CUSTOMER NAME</th>
          <td mat-cell *matCellDef="let element" class="nowrap"> {{element.customerName}} </td>
        </ng-container>
      
        <!-- Name Column -->
        <ng-container matColumnDef="mobileNo">
          <th mat-header-cell *matHeaderCellDef>MOBILE NUMBER</th>
          <td mat-cell *matCellDef="let element" class="nowrap"> {{element.mobileNo}} </td>
        </ng-container>
      
        <!-- Weight Column -->
        <ng-container matColumnDef="guestNo">
          <th mat-header-cell *matHeaderCellDef class="text-center">NO. OF GUESTS</th>
          <td mat-cell *matCellDef="let element" class="text-center"> {{element.guestNo}} </td>
        </ng-container>
      
        <!-- Symbol Column -->
        <ng-container matColumnDef="reservedTime">
          <th mat-header-cell *matHeaderCellDef>RESERVATION DATE & TIME</th>
          <td mat-cell *matCellDef="let element" class="nowrap"> {{element.reservedTime}} </td>
        </ng-container>
        <ng-container matColumnDef="reseredTable">
          <th mat-header-cell *matHeaderCellDef class="text-center">RESERVED TABLE</th>
          <td mat-cell *matCellDef="let element" [innerHTML]="element.reseredTable" class="text-center"></td>
        </ng-container>
        <ng-container matColumnDef="event">
          <th mat-header-cell *matHeaderCellDef>EVENT</th>
          <td mat-cell *matCellDef="let element">{{element.event}} </td>
        </ng-container>
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef class="action-col">ACTION</th>
          <td mat-cell *matCellDef="let element">
            <div class="flex table-actionbtn">
                <button  mat-icon-button aria-label="Edit" matTooltip="Edit"  routerLink="/reservation/add"><span class="icon-edit-pencil"></span></button>
                <button (click)="openDialog()" mat-icon-button aria-label="Delete" matTooltip="Delete"><span class="icon-ic-trash"></span></button>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>

  
