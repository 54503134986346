import { Component, Inject, inject, Optional } from '@angular/core';
import { FormGroup, FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ConstantService } from '../../../../shared/services/constant.service';
import { PaymentService } from '../payment.service';
import { FlotNumberDirective } from '../../../../shared/directives/flot-number.directive';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-split-payment',
  standalone: true,
  imports: [
    RouterModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    TranslateModule,
    ReactiveFormsModule,
    CommonModule,
    FlotNumberDirective,
  ],
  templateUrl: './split-payment.component.html',
  styleUrl: './split-payment.component.scss'
})
export class SplitPaymentComponent {
  splitPaymentFormGroup!: FormGroup;
  subscribeAPI!: Subscription;
  localData: any;
  public paymentService = inject(PaymentService);
  public constantService = inject(ConstantService);
  splitAmountOptions = this.paymentService.splitAmountOption;
  activeSplitOption: any = 2; // Default active button value
  private dialog = inject(MatDialog);
  private fb = inject(FormBuilder);
  private toastr = inject(ToastrService);
  private dialogRef = inject(MatDialogRef<SplitPaymentComponent>);

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: SplitPaymentComponent) {
    this.localData = { ...data };
    this.initialOrderForm();
  }

  initialOrderForm() {
    this.splitPaymentFormGroup = this.fb.group({
      amount: [this.localData.grandTotal, [Validators.required]],
      splitType: [this.activeSplitOption, Validators.required],
      customSplit: ['']
    })
  }

  ngOnInit(): void {
  }

  selectSplit(value: any) {
    console.log(value);
    this.activeSplitOption = value;

    const customSplitControl = this.splitPaymentFormGroup.get('customSplit');
    this.splitPaymentFormGroup.controls['splitType'].setValue(value);
    if (customSplitControl) {
      if (value == 'Custom') {
        customSplitControl.setValidators([Validators.required]);
      } else {
        this.splitPaymentFormGroup.controls['customSplit'].setValue('');
        customSplitControl.clearValidators();
      }
      customSplitControl.updateValueAndValidity();
    }
  }

  paymentSplit() {
    const { order_id } = this.localData;
    const { amount, splitType, customSplit } = this.splitPaymentFormGroup.value;

    const obj = {
      order_id,
      amount,
      splitType,
      custom_split: customSplit === 'Custom' ? customSplit : ''
    };
    console.log(obj);
    // this.dialogRef.close();
  }



  ngOnDestroy(): void {
    if (this.subscribeAPI) this.subscribeAPI.unsubscribe();
  }
}
