import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ResponseMessage } from '../../../../model/interfaces/req.res.interface';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  private httpClient = inject(HttpClient);

  isShowProduct = new BehaviorSubject<any>({});
  isShowProductList = this.isShowProduct.asObservable();

  inventoryStatusArray = [
    { id: 1, name: 'Available' },
    { id: 2, name: 'Not Available' },
  ];
  //#region Product API's

  // Get Product List
  getProductList(data: any): Observable<ResponseMessage> {
    let params = new HttpParams();
    params = params.append('page', data.page);
    params = params.append('per_page', data.per_page);
    params = params.append('business_location_id', data.business_location_id);
    data.search_text ? (params = params.append('search_name', data.search_text)) : '';
    data.category_text ? (params = params.append('category', data.category_text)) : '';
    data.branch_text ? (params = params.append('branch_location', data.branch_text)) : '';
    data.inventory_text ? (params = params.append('available_to_sell', data.inventory_text)) : '';
    return this.httpClient.get<ResponseMessage>('get-item', { params: params });
  }

  // Add New Product
  addProduct(data: any): Observable<ResponseMessage> {
    return this.httpClient.post<ResponseMessage>('add-item', data);
  }

  // Get Product Detail
  getProductDetail(id: number): Observable<ResponseMessage> {
    return this.httpClient.get<ResponseMessage>(`get-item-by-id/${id}`);
  }

  // update Product
  updateProduct(data: any): Observable<ResponseMessage> {
    return this.httpClient.post<ResponseMessage>('update-item', data);
  }

  // Delete Product
  deleteProduct(id: number): Observable<ResponseMessage> {
    return this.httpClient.delete<ResponseMessage>(`delete-item/${id}`);
  }

  // Search Product by name
  searchProduct(data: any): Observable<ResponseMessage> {
    return this.httpClient.post<ResponseMessage>('search-product', data);
  }

  // ------- Filter and Add products--------------

  // Get Category List
  getCategoryList(): Observable<ResponseMessage> {
    return this.httpClient.get<ResponseMessage>('get-category');
  }

  // Get Item Units
  getItemUnitsList(): Observable<ResponseMessage> {
    return this.httpClient.get<ResponseMessage>('get-item-unit');
  }

  // Get Menu
  getMenuList(data: any): Observable<ResponseMessage> {
    let params = new HttpParams();
    params = params.append('business_location_id', data.business_location_id);
    return this.httpClient.get<ResponseMessage>('get-food-menu', { params: params });
  }

  // Get Branch List
  getBranceList(): Observable<ResponseMessage> {
    return this.httpClient.get<ResponseMessage>('view-branch');
  }

  //#endregion Product API's
}
