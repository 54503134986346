<div class="section-box mb-24 flex flex-wrap order-infowrapper">
  <div class="order-infobox radius-12">
    <span class="order-infoicon">
      <img src="assets/images/icons/ic-checkout.svg" alt="Number of Orders" />
    </span>
    <div class="fs-14 text-grey ">
      <h3 class="order-count text-white fw-bold">225</h3>
      <span>Number of Orders</span>
    </div>
  </div>
  <div class="order-infobox radius-12">
    <span class="order-infoicon">
      <img src="assets/images/icons/ic-money.svg" alt="Total Net Sales After Fees" />
    </span>
    <div class="fs-14 text-grey ">
      <h3 class="order-count text-white fw-bold">$1,20,000.25</h3>
      <span>Total Net Sales After Fees</span>
    </div>
  </div>
  <div class="order-infobox radius-12">
    <span class="order-infoicon">
      <img src="assets/images/icons/icon-money.svg" alt="Total Collection Before Fees" />
    </span>
    <div class="fs-14 text-grey ">
      <h3 class="order-count text-white fw-bold">$1,20,000.25</h3>
      <span>Total Collection Before Fees</span>
    </div>
  </div>
</div>

<!-- Starts Filter Section -->
<div class="filter-boxwrapper section-box flex flex-justify-end flex-wrap mb-20">
  <mat-form-field subscriptSizing="dynamic" class="filter-date date-field">
    <div class="w-full relative input-rightspace">
      <input matInput [matDatepicker]="picker" placeholder="From">
      <mat-datepicker-toggle matIconSuffix [for]="picker" class="ic-input cursor-pointer"></mat-datepicker-toggle>
    </div>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
  <mat-form-field subscriptSizing="dynamic" class="filter-date date-field">
    <div class="w-full relative input-rightspace">
      <input matInput [matDatepicker]="picker2" placeholder="To">
      <mat-datepicker-toggle matIconSuffix [for]="picker2" class="ic-input cursor-pointer"></mat-datepicker-toggle>
    </div>
    <mat-datepicker #picker2></mat-datepicker>
  </mat-form-field>
  <mat-form-field subscriptSizing="dynamic" class="filter-day">
    <mat-select placeholder="Day">
      <mat-option value="option1">Sunday</mat-option>
      <mat-option value="option2">Monday</mat-option>
      <mat-option value="option3">Tuesday</mat-option>
      <mat-option value="option3">Wednesday</mat-option>
      <mat-option value="option3">Thursday</mat-option>
      <mat-option value="option3">Friday</mat-option>
      <mat-option value="option3">Saturday</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field subscriptSizing="dynamic" class="filter-paymenttype">
    <mat-select placeholder="Payment Methods">
      <mat-option value="option1">Debit Cards</mat-option>
      <mat-option value="option2">Crypto Currency</mat-option>
      <mat-option value="option3">Wallet</mat-option>
      <mat-option value="option3">Cash</mat-option>
      <mat-option value="option3">Cheque</mat-option>
      <mat-option value="option3">Credit Cards</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field subscriptSizing="dynamic" class="filter-paymenttype">
    <mat-select placeholder="Payment Types">
      <mat-option value="option1">Debit Cards</mat-option>
      <mat-option value="option2">Crypto Currency</mat-option>
      <mat-option value="option3">Wallet</mat-option>
      <mat-option value="option3">Cash</mat-option>
      <mat-option value="option3">Cheque</mat-option>
      <mat-option value="option3">Credit Cards</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field subscriptSizing="dynamic" class="filter-location">
    <mat-select placeholder="Locations">
      <mat-option value="option1">Amsterdam</mat-option>
      <mat-option value="option2">Bangkok</mat-option>
      <mat-option value="option3">Dallas</mat-option>
      <mat-option value="option3">Dubai</mat-option>
      <mat-option value="option3">Frankfurt</mat-option>
      <mat-option value="option3">Hong Kong</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field subscriptSizing="dynamic" class="filter-item">
    <mat-select placeholder="Items">
      <mat-option value="option1">Fruit</mat-option>
      <mat-option value="option2">Eggs</mat-option>
      <mat-option value="option3">Nuts and seeds</mat-option>
      <mat-option value="option3">Veggies</mat-option>
      <mat-option value="option3">Seafood</mat-option>
      <mat-option value="option3">Grains</mat-option>
    </mat-select>
  </mat-form-field>
</div>
<!-- Ends Filter Section -->

<div class="table-sectionbox">
  <div class="flex flex-justify-between flex-wrap gap-8px flex-align-center section-box table-sectionbx">
    <h1 class="mb-0 mobile-full h3">Order History</h1>
    <div class="flex gap-16px flex-grow-1 flex-justify-end">
      <mat-form-field appearance="outline" subscriptSizing="dynamic" class="small-input search-inputbx">
        <div class="w-full relative input-rightspace">
          <input type="search" class="form-control" matInput placeholder="Search Transaction"  />
          <mat-icon class="ic-input cursor-pointer">search</mat-icon>
        </div>
      </mat-form-field>
      <button mat-flat-button class="flex-shrink-0 export-btn" >Export</button>
    </div>
  </div>
    <div class="table-responsive custom-scroll table-wrapper">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    
        <!-- Position Column -->
        <ng-container matColumnDef="amount">
          <th mat-header-cell *matHeaderCellDef>AMOUNT</th>
          <td mat-cell *matCellDef="let element" class="nowrap"> {{element.amount}} </td>
        </ng-container>
      
        <!-- Name Column -->
        <ng-container matColumnDef="dateTime">
          <th mat-header-cell *matHeaderCellDef>DATE & TIME</th>
          <td mat-cell *matCellDef="let element" class="nowrap"> {{element.dateTime}} </td>
        </ng-container>
      
        <!-- Weight Column -->
        <ng-container matColumnDef="collectedFrom">
          <th mat-header-cell *matHeaderCellDef>COLLECTED FROM</th>
          <td mat-cell *matCellDef="let element"> {{element.collectedFrom}} </td>
        </ng-container>
      
        <!-- Symbol Column -->
        <ng-container matColumnDef="paymentMethod">
          <th mat-header-cell *matHeaderCellDef>PAYMENT METHOD</th>
          <td mat-cell *matCellDef="let element" class="nowrap"> {{element.paymentMethod}} </td>
        </ng-container>
        <ng-container matColumnDef="cashierName">
          <th mat-header-cell *matHeaderCellDef>CASHIER NAME</th>
          <td mat-cell *matCellDef="let element" [innerHTML]="element.cashierName"></td>
        </ng-container>
        <ng-container matColumnDef="totalAmount">
          <th mat-header-cell *matHeaderCellDef>TOTAL AMOUNT</th>
          <td mat-cell *matCellDef="let element">{{element.totalAmount}} </td>
        </ng-container>
        <ng-container matColumnDef="viewReceipt">
          <th mat-header-cell *matHeaderCellDef class="action-col">VIEW RECEIPT</th>
          <td mat-cell *matCellDef="let element">
            <div class="flex table-actionbtn flex-justify-center">
                <button mat-icon-button aria-label="View" matTooltip="View" (click)="openDialog()"><span class="icon-eye"></span></button>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
</div>
