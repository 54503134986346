import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioGroup, MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { DeleteVendorComponent } from '../../components/delete-vendor/delete-vendor.component';

export interface PeriodicElement {
  vendorName: string;
  email: string;
  phoneNumber: string;
  description: string;
  businessDetail: string,
}

const ELEMENT_DATA: PeriodicElement[] = [
  {
    vendorName: 'Makenna Schleifer',
    email: 'alma.lawson@example.com',
    phoneNumber: '(480) 555-0103',
    description: 'lorem ipsum dolor sit amet consectetur adipiscing elit',
    businessDetail: 'Restaurants',
  },
  {
    vendorName: 'Makenna Schleifer',
    email: 'alma.lawson@example.com',
    phoneNumber: '(480) 555-0103',
    description: 'lorem ipsum dolor sit amet consectetur adipiscing elit',
    businessDetail: 'Restaurants',
  },
  {
    vendorName: 'Makenna Schleifer',
    email: 'alma.lawson@example.com',
    phoneNumber: '(480) 555-0103',
    description: 'lorem ipsum dolor sit amet consectetur adipiscing elit',
    businessDetail: 'Restaurants',
  },
  {
    vendorName: 'Makenna Schleifer',
    email: 'alma.lawson@example.com',
    phoneNumber: '(480) 555-0103',
    description: 'lorem ipsum dolor sit amet consectetur adipiscing elit',
    businessDetail: 'Restaurants',
  },
  {
    vendorName: 'Makenna Schleifer',
    email: 'alma.lawson@example.com',
    phoneNumber: '(480) 555-0103',
    description: 'lorem ipsum dolor sit amet consectetur adipiscing elit',
    businessDetail: 'Restaurants',
  },
  {
    vendorName: 'Makenna Schleifer',
    email: 'alma.lawson@example.com',
    phoneNumber: '(480) 555-0103',
    description: 'lorem ipsum dolor sit amet consectetur adipiscing elit',
    businessDetail: 'Restaurants',
  },
  {
    vendorName: 'Makenna Schleifer',
    email: 'alma.lawson@example.com',
    phoneNumber: '(480) 555-0103',
    description: 'lorem ipsum dolor sit amet consectetur adipiscing elit',
    businessDetail: 'Restaurants',
  },
  {
    vendorName: 'Makenna Schleifer',
    email: 'alma.lawson@example.com',
    phoneNumber: '(480) 555-0103',
    description: 'lorem ipsum dolor sit amet consectetur adipiscing elit',
    businessDetail: 'Restaurants',
  },
  {
    vendorName: 'Makenna Schleifer',
    email: 'alma.lawson@example.com',
    phoneNumber: '(480) 555-0103',
    description: 'lorem ipsum dolor sit amet consectetur adipiscing elit',
    businessDetail: 'Restaurants',
  },
];
@Component({
  selector: 'app-vendor-list',
  standalone: true,
  imports: [
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    MatTableModule,
    MatTooltipModule,
    MatRadioModule,
    MatRadioGroup,
    RouterModule
  ],
  templateUrl: './vendor-list.component.html',
  styleUrl: './vendor-list.component.scss'
})
export class VendorListComponent {
  displayedColumns: string[] = ['vendorName','email',  'phoneNumber', 'description', 'businessDetail', 'action'];
  dataSource = ELEMENT_DATA;

  readonly dialog = inject(MatDialog);

  openDialog() {
    const dialogRef = this.dialog.open(DeleteVendorComponent, {
      width: '564px',
      panelClass: ['modal-wrapper'],
    });
  }
}
