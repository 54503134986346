<ng-container *ngIf="businessQuestions$ | async"></ng-container>
<ng-container *ngIf="physicalLocationInputChange$ | async"></ng-container>
<div class="authentication-box">
  <form [formGroup]="signupBusinessDetailsForm">
    <div class="auth-headerwrapper flex">
      <div class="authlogo-box">
        <div class="auth-box flex flex-align-center flex-justify-center">
          <img src="./assets/images/logo-small.svg" alt="MuyBi" />
        </div>
      </div>
      <div class="auth-header">
        <h1>{{ "sign_step_two.title" | translate }}</h1>
        <p class="text-grey">
          {{ "sign_step_two.sub_title" | translate }}
        </p>
      </div>
    </div>
    <div class="auth-innerbox">
      <div class="mb-28 block">
        <div class="mb-16">
          <label id="example-radio-group-label" class="text-grey-medium block mb-10">
            {{ "sign_step_two.type_of_business" | translate }}
          </label>
          <mat-radio-group aria-label="Select an option" formControlName="businessType">
            <mat-radio-button value="1">{{
              "retail" | translate
              }}</mat-radio-button>
            <mat-radio-button value="2">{{
              "restaurant" | translate
              }}</mat-radio-button>
          </mat-radio-group>
        </div>
        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
          <mat-label>{{
            "sign_step_two.enter_your_business_name" | translate
            }}</mat-label>
          <input matInput formControlName="businessName" />
        </mat-form-field>
        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
          <mat-label>{{ "phone_number" | translate }}*</mat-label>
          <ngx-mat-intl-tel-input class="w-full" formControlName="phone" [preferredCountries]="preferredCountries"
            [enablePlaceholder]="true" [enableSearch]="true" name="userNamePhone" #userNamePhone>
          </ngx-mat-intl-tel-input>
        </mat-form-field>
        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
          <mat-label>{{
            "sign_step_two.estimated_annual_revenue" | translate
            }}</mat-label>
          <input matInput formControlName="estimatedAnnualRevenue" min="1" allowNumberOnly />
        </mat-form-field>

        <ng-container formArrayName="questionAnswer">
          <ng-container *ngFor="let question of questionAnswer.controls; let i = index" [formGroupName]="i">
            <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
              <mat-label>{{ question.get("question")?.value }}</mat-label>
              <input matInput formControlName="answer" />
            </mat-form-field>
          </ng-container>
        </ng-container>

        <div class="mb-16">
          <label id="example-radio-group-label" class="text-grey-medium block mb-10">
            {{ "sign_step_two.business_location" | translate }}*
          </label>
          <mat-radio-group aria-label="Select an option" formControlName="isPhysicalLocation">
            <mat-radio-button value="2">
              {{ "sign_step_two.without_physical_location" | translate }}
            </mat-radio-button>
            <mat-radio-button value="1">
              {{ "sign_step_two.with_physical_location" | translate }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
        @if(signupBusinessDetailsForm.value.isPhysicalLocation === '1'){
        <div class="grid2">
          <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
            <mat-label>{{ "sign_step_two.number" | translate }}</mat-label>
            <input matInput formControlName="streetNumber" />
          </mat-form-field>
          <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
            <mat-label>{{ "sign_step_two.street_name" | translate }}</mat-label>
            <input matInput formControlName="streetName" />
          </mat-form-field>
        </div>
        <div class="grid2">
          <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
            <mat-label>{{
              "sign_step_two.apartment_suite" | translate
              }}</mat-label>
            <input matInput formControlName="apartment" />
          </mat-form-field>
          <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
            <mat-label>{{ "sign_step_two.building" | translate }}</mat-label>
            <input matInput formControlName="building" />
          </mat-form-field>
        </div>
        <div class="grid2">
          <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
            <mat-label>{{ "sign_step_two.unit" | translate }}</mat-label>
            <input matInput formControlName="unit" />
          </mat-form-field>
          <mat-form-field subscriptSizing="dynamic" appearance="outline"
            class="filter-location filterbox select-bgwrap small-select">
            <mat-label>{{ "sign_step_two.state" | translate }}</mat-label>
            <mat-select placeholder="State" formControlName="stateId" (valueChange)="getCities($event)">
              @for (state of states(); track state) {
              <mat-option value="{{ state.id }}">{{ state.name }}</mat-option>
              } @empty {
              <mat-option value="">{{ "select" | translate }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        <div class="grid2">
          <mat-form-field subscriptSizing="dynamic" appearance="outline"
            class="filter-location filterbox select-bgwrap small-select">
            <mat-label>{{ "sign_step_two.city" | translate }}</mat-label>
            <mat-select formControlName="cityId">
              <mat-option value="">{{ "select" | translate }}</mat-option>
              @for (city of cities(); track city) {
              <mat-option value="{{ city.id }}">{{ city.name }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
            <mat-label>{{ "sign_step_two.zip_code" | translate }}</mat-label>
            <input matInput formControlName="postcode" type="number" />
          </mat-form-field>
        </div>
        }
        <div class="mb-16">
          <label id="example-radio-group-label" class="text-grey-medium block mb-10">{{
            "sign_step_two.business_branches" | translate }}*</label>
          <mat-radio-group aria-label="Select an option" formControlName="businessBranch">
            <mat-radio-button value="1">{{
              "single" | translate
              }}</mat-radio-button>
            <mat-radio-button value="2">{{
              "multiple" | translate
              }}</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <button mat-flat-button class="w-full" type="submit" [disabled]="submitDisabled()"
        (click)="formSubmit(signupBusinessDetailsForm.value)">
        {{ "next" | translate }}
      </button>
    </div>
  </form>
</div>