<div class="bg-grey-lighter header-balancewrap section-box mb-24 flex flex-align-center flex-wrap">
  <h2 class="h1 text-white mb-0 mobile-full">
    {{greeting | translate }}, {{ userDetail.name | titlecase }} 😊
  </h2>
  <div class="flex flex-grow-1 flex-align-center gap-8px">
    <span class="relative">
      <div class="spinner-background">{{profileCompletePer}}%</div>
      <mat-progress-spinner strokeWidth="2" diameter="40" class="example-margin" mode="determinate"
        [value]="profileCompletePer">
      </mat-progress-spinner>
    </span>
    <div class="progress-barwrap">
      <div class="flex flex-justify-between mb-4">
        <span class="fs-13 text-grey-medium">{{
          "profile_completed" | translate
          }}</span>
        <button mat-button class="fs-13 text-primary fw-normal" (click)="redirectToModule()">
          {{ "edit" | translate }}
        </button>
      </div>
      <mat-progress-bar mode="determinate" [value]="profileCompletePer"></mat-progress-bar>
    </div>
  </div>
  <button mat-flat-button class="btn-outline-primary mybalance-btn">
    {{ "my_balance" | translate }}
  </button>
</div>
<ng-container *ngIf="data$ | async"></ng-container>
<div class="bg-grey-lighter section-box">
  <div class="flex flex-align-center flex-wrap mb-20 gap-8px">
    <h3 class="text-white mb-0 mobile-full">
      {{ "recent_transactions" | translate }}
    </h3>
    <div class="flex flex-grow-1 flex-align-center flex-justify-end gap-28px flex-wrap">
      <mat-form-field subscriptSizing="dynamic" class="month-selection radius-12 mobile-halfwidth">
        <mat-select [(ngModel)]="selectedMonth" disableOptionCentering placeholder="{{ 'select_month' | translate }}"
          (valueChange)="changeSelectedMonth($event)">
          <mat-option value="">{{ "select_month" | translate }}</mat-option>
          @for (month of months; track month) {
          <mat-option value="{{ month.value }}">{{ month.name }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
      <mat-form-field subscriptSizing="dynamic" class="monthyear-selection radius-12 mobile-halfwidth">
        <mat-select [(ngModel)]="selectedPrevMonth" disableOptionCentering
          placeholder="{{ 'vs_previous_month' | translate }}" [disabled]="selectedMonth === ''"
          (valueChange)="changeSelectedPrevMonth($event)">
          <mat-option value="">{{
            "vs_previous_month" | translate
            }}</mat-option>
          @for (month of months; track month) {
          <mat-option value="{{ month.value }}" [disabled]="month.value === selectedMonth">
            {{ month.name }}
          </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="transition-box">
    <div class="card-box">
      <div class="flex flex-justify-between gap-8px mb-20 text-white graphbox-info">
        <h2 class="mb-0">{{ "gross_sales" | translate }}</h2>
        <h2 class="mb-0">
          {{
          graphData.gross_sales_module?.data
          | currency : "USD" : "symbol" : "1.0-0"
          }}
        </h2>
      </div>
      <div class="">
        <canvas id="grossSalesCanvas"></canvas>
      </div>
    </div>
    <div class="card-box">
      <div class="flex flex-justify-between gap-8px mb-20 text-white graphbox-info">
        <h2 class="mb-0">{{ "returns" | translate }}</h2>
        <h2 class="mb-0">
          {{
          graphData.returns_module?.data
          | currency : "USD" : "symbol" : "1.0-0"
          }}
        </h2>
      </div>
      <div class="">
        <canvas id="returnsCanvas"></canvas>
      </div>
    </div>
    <div class="card-box">
      <div class="flex flex-justify-between gap-8px mb-20 text-white graphbox-info">
        <h2 class="mb-0">{{ "transaction" | translate }}</h2>
        <h2 class="mb-0">
          {{
          graphData.transaction_module?.data
          | currency : "USD" : "symbol" : "1.0-0"
          }}
        </h2>
      </div>
      <div class="">
        <canvas id="transactionCanvas"></canvas>
      </div>
    </div>
    <div class="card-box">
      <div class="flex flex-justify-between gap-8px mb-20 text-white graphbox-info">
        <h2 class="mb-0">{{ "net_sales" | translate }}</h2>
        <h2 class="mb-0">
          {{
          graphData.net_sales_module?.data
          | currency : "USD" : "symbol" : "1.0-0"
          }}
        </h2>
      </div>
      <div class="">
        <canvas id="netSalesCanvas"></canvas>
      </div>
    </div>
  </div>
</div>