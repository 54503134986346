import { Component, inject, Inject, Optional } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-confirmation',
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    TranslateModule
  ],
  templateUrl: './confirmation.component.html',
  styleUrl: './confirmation.component.scss'
})
export class ConfirmationComponent {
  localData: any;
  private dialog = inject(MatDialog);
  private dialogRef = inject(MatDialogRef<ConfirmationComponent>);

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: ConfirmationComponent) {
    this.localData = { ...data };
  }


  submit() {
    this.dialogRef.close({
      success: true,
      type: this.localData.type
    })
  }


  cancel() {

  }
}
