<mat-dialog-content class="text-center">
  <div class="mb-24 radius-12 flex flex-justify-center">
    <img src="assets/images/order.png" alt="Order #231128001" class="dialog-smallimg" />
  </div>
  <div class="block text-grey-light mb-24">
    <h3 class="h1 mb-24 text-white">Order #231128001</h3>
  </div>
  <div class="flex flex-column gap-20px mb-20 text-left">
    <div class="ordered-product gap-16px flex">
      <div class="ordered-productimg flex-shrink-0">
        <img src="assets/images/product/img1.jpg" alt="American Style Burger" class="img-fit" />
      </div>
      <div class="ordered-detailbox flex flex-column flex-justify-between flex-grow-1">
        <h5 class="text-white product-name mb-4">American Style Burger</h5>
        <div>
          <span class="text-primary mb-6 d-block fs-13">2 items</span>
          <div class="flex flex-justify-between flex-wrap flex-align-center">
            <span class="fs-12 text-grey-medium">Total Price :</span>
            <span class="text-primary fs-16 fw-medium">$27.09</span>
          </div> 
        </div>
      </div>
    </div>
    <div class="ordered-product gap-16px flex">
      <div class="ordered-productimg flex-shrink-0">
        <img src="assets/images/product/img1.jpg" alt="Mocktail" class="img-fit" />
      </div>
      <div class="ordered-detailbox flex flex-column flex-justify-between flex-grow-1">
        <h5 class="text-white product-name mb-4">Mocktail</h5>
        <div>
          <span class="text-primary mb-6 d-block fs-13">2 items</span>
          <div class="flex flex-justify-between flex-wrap flex-align-center">
            <span class="fs-12 text-grey-medium">Total Price :</span>
            <span class="text-primary fs-16 fw-medium">$55.17</span>
          </div> 
        </div>
      </div>
    </div>
    <div class="ordered-product gap-16px flex">
      <div class="ordered-productimg flex-shrink-0">
        <img src="assets/images/product/img1.jpg" alt="Burger French Fries Combo" class="img-fit" />
      </div>
      <div class="ordered-detailbox flex flex-column flex-justify-between flex-grow-1">
        <h5 class="text-white product-name mb-4">Burger French Fries Combo</h5>
        <div>
          <span class="text-primary mb-6 d-block fs-13">2 items</span>
          <div class="flex flex-justify-between flex-wrap flex-align-center">
            <span class="fs-12 text-grey-medium">Total Price :</span>
            <span class="text-primary fs-16 fw-medium">$55.17</span>
          </div> 
        </div>
      </div>
    </div>
  </div>
  <div class="totalcount-wrapper mb-12">
    <div class="text-grey-medium fs-16 ordered-totalbx mb-12 flex flex-justify-between">
      <span>Total Guest</span>
      <span>4</span>
    </div>
    <div class="text-grey-medium fs-16 ordered-totalbx mb-12 flex flex-justify-between">
      <span>Subtotal</span>
      <span>$54.18</span>
    </div>
    <div class="text-grey-medium fs-16 ordered-totalbx mb-12 flex flex-justify-between">
      <span>Discount</span>
      <span>$00.00</span>
    </div>
    <div class="text-grey-medium fs-16 ordered-totalbx mb-12 flex flex-justify-between">
      <span>Tax</span>
      <span>$1.99</span>
    </div>
    <div class="text-grey-medium fs-16 ordered-totalbx mb-12 flex flex-justify-between">
      <span>Tip</span>
      <span>$1.99</span>
    </div>
  </div>
  <div class="text-grey-medium fs-16 ordered-totalbx mb-12 flex flex-justify-between mb-24">
    <span class="h3 text-grey-light fw-bold">Total</span>
    <span class="text-primary h3 fw-bold">$55.17</span>
  </div>
  
    <button mat-flat-button  mat-dialog-close class="w-full btn-outline-primary">Back</button>
  
</mat-dialog-content>