<div class="authentication-page flex flex-align-center flex-justify-center">
    @if(!accountProtection){
    <button mat-flat-button class="btn-dark skip-btn" (click)="redirectToDashboard()">
        {{ "skip" | translate }}
    </button>
    }
    <form [formGroup]="signupAccountProtectionForm">
        <div class="authentication-box">
            <div class="auth-headerwrapper flex">
                <div class="authlogo-box">
                    <div class="auth-box flex flex-align-center flex-justify-center">
                        <img src="./assets/images/logo-small.svg" alt="MuyBi" />
                    </div>
                </div>
                <div class="auth-header">
                    <h1>{{ "sign_step_three.title" | translate }}</h1>
                    <p class="text-grey">
                        {{ "sign_step_three.sub_title" | translate }}
                    </p>
                </div>
            </div>
            <div class="auth-innerbox">
                <div class="mb-20">
                    <label id="example-radio-group-label" class="text-grey-medium block mb-10">
                        {{ "sign_step_three.business_protection" | translate }}
                    </label>
                    <mat-radio-group aria-label="Select an option" formControlName="protectWith"
                        (change)="protectWithValueChange($event)">
                        <mat-radio-button value="1">
                            {{ "sign_step_three.protect_with_mobile_number" | translate }}
                        </mat-radio-button>
                        <mat-radio-button value="2">
                            {{ "sign_step_three.protect_with_microsoft" | translate }}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
                @if(signupAccountProtectionForm.controls.protectWith.value === '1'){
                <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-20">
                    <mat-label>{{
                        "sign_step_three.enter_mobile_number" | translate
                        }}</mat-label>
                    <ngx-mat-intl-tel-input formControlName="phone" class="w-full"
                        [preferredCountries]="preferredCountries" [enablePlaceholder]="true" [enableSearch]="true"
                        name="userNamePhone" #userNamePhone #phoneEle (input)="getCountryCode(userNamePhone)">
                    </ngx-mat-intl-tel-input>
                </mat-form-field>
                }
                <div class="mb-20" *ngIf="signupAccountProtectionForm.controls.protectWith.value === '2'">
                    @if(qrCodeSVG() === ''){
                    <mat-progress-bar mode="indeterminate" class="table-loader mb-24"></mat-progress-bar>
                    }@else {
                    <div class="text-center">
                        <div [innerHTML]="qrCodeSVG()"></div>
                    </div>
                    }
                </div>
                <div class="btn-box mb-24">
                    <button mat-stroked-button class="w-full" (click)="back()">
                        {{ "back" | translate }}
                    </button>
                    <button mat-flat-button class="w-full" type="submit" [disabled]="submitDisabled()"
                        (click)="formSubmit(signupAccountProtectionForm.value)">
                        {{checkRotrExists?'submit':'next' | translate }}
                    </button>
                </div>
                <div class="bg-dark linkinfo-wrap flex text-grey-light radius-12 fs-14">
                    <mat-icon class="flex-shrink-0">
                        <img src="./assets/images/icons/ic-warning.svg" alt="Warning" />
                    </mat-icon>
                    <span>
                        {{ "sign_step_three.term_1" | translate }}
                        <a class="text-link" routerLink="/privacy-policy" target="_blank">
                            Privacy Policy
                        </a>
                        {{ "sign_step_three.term_2" | translate }}
                        <a class="text-link" routerLink="/terms-and-conditions" target="_blank">
                            {{ "sms_terms_conditions" | translate }}.
                        </a>
                    </span>
                </div>
            </div>
        </div>
    </form>
</div>