import { AbstractControl, ValidatorFn } from '@angular/forms';
import { numberPattern } from '../../model/const/common.const';


export function numberValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    if (!value) return null;

    const isValidNumber = numberPattern.test(value);

    return isValidNumber ? null : { number: true };
  };
}
