<div id="menuItem" class="sidebar-wrapper custom-scroll" [class.active]="isOpenSideBar">
  <span class="close-sidebar" (click)="closeSidemenu()">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="30px" height="30px">
      <path
        d="M 7 4 C 6.744125 4 6.4879687 4.0974687 6.2929688 4.2929688 L 4.2929688 6.2929688 C 3.9019687 6.6839688 3.9019687 7.3170313 4.2929688 7.7070312 L 11.585938 15 L 4.2929688 22.292969 C 3.9019687 22.683969 3.9019687 23.317031 4.2929688 23.707031 L 6.2929688 25.707031 C 6.6839688 26.098031 7.3170313 26.098031 7.7070312 25.707031 L 15 18.414062 L 22.292969 25.707031 C 22.682969 26.098031 23.317031 26.098031 23.707031 25.707031 L 25.707031 23.707031 C 26.098031 23.316031 26.098031 22.682969 25.707031 22.292969 L 18.414062 15 L 25.707031 7.7070312 C 26.098031 7.3170312 26.098031 6.6829688 25.707031 6.2929688 L 23.707031 4.2929688 C 23.316031 3.9019687 22.682969 3.9019687 22.292969 4.2929688 L 15 11.585938 L 7.7070312 4.2929688 C 7.5115312 4.0974687 7.255875 4 7 4 z" />
    </svg>
  </span>
  <div class="sidebar-top-header">
    <button mat-flat-button class="flex-shrink-0 btn-sidebar">
      Create an Order
    </button>
    <button mat-flat-button class="btn-outline-primary btn-sidebar">
      Take a Payment
    </button>

    <!-- <mat-form-field appearance="outline" subscriptSizing="dynamic">
      <mat-select placeholder="Select Waiter" disableOptionCentering>
        <mat-option value="option1">Option 1</mat-option>
        <mat-option value="option2">Option 2</mat-option>
        <mat-option value="option3">Option 3</mat-option>
      </mat-select>
    </mat-form-field> -->

    <!-- @if (deviceType =='mobile') { -->
    <div class="hide-mobile header-selection location-selection mobilew-full">
      <app-branch-location></app-branch-location>
    </div>
    <!-- } -->

  </div>

  <div class="sidebar-links custom-scroll">
    <!-- @for (module of moduleList; track $index) {
    <a class="navlink" routerLinkActive="active" [routerLink]="module.route" (click)="setModuleTitle(module.name)">
      <span class="{{ module.icon }} nav-icon"></span>
      <span>{{ module.translateVar| translate }}</span>
    </a>
    } -->
    <!-- Start Temp underdevelopment Code -->
    @for (module of moduleList; track $index) {
    <a class="navlink" [class.active]="tempModuleName == module.name" [routerLink]="module.route"
      (click)="setModuleTitle(module.name)">
      <span class="{{ module.icon }} nav-icon"></span>
      <span>{{ module.translateVar| translate }}</span>
    </a>
    }
    <!-- End Temp underdevelopment Code -->
    <a class="navlink" (click)="openDialog()">
      <span class="icon-logout nav-icon">
        <span class="path1"></span>
        <span class="path2"></span></span>
      <span>{{ "log_out" | translate }}</span>
    </a>
  </div>
</div>

<div class="overlay-drawer"></div>