<div class="section-box flex flex-justify-between flex-wrap gap-8px mb-24 flex-align-center header-box backbtn-wrap">
  <h1 class="text-white mb-0 text-truncate">Add Vendor</h1>
  
    <button mat-flat-button class="btn-outline-secondary backbtn" routerLink="/vendor-list" ><svg width="7" height="15" viewBox="0 0 7 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.2876 13.4386L1.3976 8.54859C0.820097 7.97109 0.820097 7.02609 1.3976 6.44859L6.2876 1.55859" stroke="currentColor" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      Back</button>
  
</div>

<form class="section-box form-box">
  <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-20">
    <mat-label>Vendor Name</mat-label>
    <input matInput>
  </mat-form-field>
  <div class="form-grid2">
    <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-20">
      <mat-label>Vendor Number</mat-label>
      <input matInput>
    </mat-form-field>
    <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-20">
      <mat-label>Vendor Email</mat-label>
      <input matInput type="email">
    </mat-form-field>
  </div>
  <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-20">
    <mat-label>Description</mat-label>
    <textarea matInput rows="3"></textarea>
  </mat-form-field>
  <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-20">
    <mat-label>Business Address 1</mat-label>
    <input matInput>
  </mat-form-field>
  <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-20">
    <mat-label>Business Address 2</mat-label>
    <input matInput>
  </mat-form-field>
  <div class="text-end">
    <button mat-flat-button class="btn-lg mobile-full">Add Now</button>
  </div>
</form>