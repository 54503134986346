@if (constantService.progressLoader()) {
  <mat-progress-bar mode="indeterminate" class="table-loader"></mat-progress-bar>
  }
<div class="flex flex-justify-between flex-wrap gap-8px mb-24 flex-align-center section-box  card-boxblock">
  <h1 class="mb-0 mobile-full h3 fw-bold">{{"menu" | translate}}</h1>
  <div class="flex gap-16px flex-grow-1 flex-justify-end">
    <mat-form-field appearance="outline" subscriptSizing="dynamic" class="small-input search-inputbx">
      <div class="w-full relative input-rightspace">
        <input type="search" class="form-control" matInput (keyup)="serachMenus()" [(ngModel)]="serachMenu" matInput
          placeholder="{{'search_menu' | translate}}" />
        <mat-icon class="ic-input cursor-pointer">{{'serach' | translate}}</mat-icon>
      </div>
    </mat-form-field>
    <button mat-flat-button class="btn-outline-primary" (click)="resetSearch()">Reset</button>
    <button mat-flat-button class="flex-shrink-0 createmenu-btn" (click)="createEditMenu('Add')">
      {{"create_menu" | translate}}
    </button>
  </div>
</div>
<!-- Starts Food List Section -->

<div class="menu-listwrap" infinite-scroll (scrolled)="menuListScrollDown()" [immediateCheck]="true"
  [scrollWindow]="false" [infiniteScrollDistance]="20" [infiniteScrollThrottle]="0">
  @for (menu of menuListData; track $index) {
  <div class="menu-item text-decoration-none">
    <button mat-fab aria-label="Edit" class="edit-productbtn" (click)="createEditMenu('Edit',menu.menu_id)">
      <span class="icon-edit-pencil"></span>
    </button>
    <div class="image-box">
      <div class="flex product-imagewrap">
        <div class="product-img" [ngClass]="{'no-image': !menu.image_url }">
          <img [src]="menu.image_url ? menu.image_url : muybiLogo" [alt]="menu.title"
            [ngClass]="{'img-fit': menu.image_url }" />
        </div>
      </div>
      <div class="curve-wrapper">
        <div class="curve"></div>
      </div>
    </div>
    <div class="product-textwrap block">
      <h5 class="mb-4 text-white fw-semibold">{{menu.title}}</h5>
    </div>
    <div class="items-shape"></div>
  </div>
  }
</div>
@if (totalRecord == 0 && !constantService.progressLoader()) {
<div class="no-records flex flex-align-center flex-justify-center flex-column">
  <img src="assets/images/empty-state.png" alt="No Record Found" class="mb-24" />
  <div class="text-primary fw-medium">{{'no_records' | translate}}</div>
</div>
}

<!-- Ends Food List Section -->

<!-- HTML for Product Curve Design -->
<svg style="visibility: hidden; position: absolute;" width="0" height="0" xmlns="http://www.w3.org/2000/svg"
  version="1.1">
  <defs>
    <filter id="goo">
      <feGaussianBlur in="SourceGraphic" stdDeviation="8" result="blur" />
      <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="goo" />
      <feComposite in="SourceGraphic" in2="goo" operator="atop" />
    </filter>
  </defs>
</svg>
<!-- Ends HTML for Product Curve Design -->