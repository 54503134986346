
import { Directive, HostListener, ElementRef, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appTrim]',
  standalone: true,
})
export class TrimValueDirective {

  constructor(
    private el: ElementRef,
    @Optional() private ngControl: NgControl
  ) { }

  @HostListener('input') onInput() {
    if (this.ngControl && this.ngControl.control) {
      const trimmedValue = this.el.nativeElement.value.trim();
      this.ngControl.control.setValue(trimmedValue, { emitEvent: false });
    }
  }
}
