<mat-dialog-content class="text-center">
    <div class="mb-20 flex flex-justify-center">
      <img src="assets/images/verification.png" alt="OTP Verification" class="dialog-smallimg" />
    </div>
    <div class="block text-grey mb-20">
      <h3 class="h1 mb-4 text-white">
        {{ modalData.title | translate }}
      </h3>
    </div>
    <form [formGroup]="shareViaMobileEmailtForm" >
      <div class="mb-16">
          <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-20">
            @if(modalData.share_via==1){
            <mat-label>{{modalData.enter_email | translate}}</mat-label>
            }@else{
              <mat-label>{{modalData.enter_mobile | translate}}</mat-label>
            }
            <input matInput type="text" formControlName="share_email_phone_number">
          </mat-form-field>
      </div>
      <div class="btn-box mb-24">
        <button type="button" mat-stroked-button  class="w-full" (click)="close()">
          {{ "cancel" | translate }}
        </button>
        <button type="button" mat-flat-button class="w-full" (click)="submitForm()">
          {{ "submit" | translate }}
        </button>
      </div>
     
    </form>
  </mat-dialog-content>