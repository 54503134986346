<div
  class="section-box mb-24 flex flex-wrap flex-justify-between flex-align-center header-section gap-8px backbtn-wrap">
  <h1 class="text-white mb-0">#{{refundDetailData.order_id}}</h1>
  <a mat-flat-button class="btn-outline-secondary backbtn" (click)="backToRefundList()">
    <svg width="7" height="15" viewBox="0 0 7 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.2876 13.4386L1.3976 8.54859C0.820097 7.97109 0.820097 7.02609 1.3976 6.44859L6.2876 1.55859"
        stroke="currentColor" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>
    Back</a>
</div>

<div class="section-box refund-boxwrap mb-24">
  <div class="refund-box">
    <div class="text-grey-light">
      <span class="text-grey-medium d-block mb-4 fw-medium">Sub Total</span>
      <span>${{refundDetailData.sub_total > 0?refundDetailData.sub_total.toFixed(2):'00.00'}}</span>
    </div>
    <div class="text-grey-light">
      <span class="text-grey-medium d-block mb-4 fw-medium">Discount</span>
      <span>${{refundDetailData.discount > 0?refundDetailData.discount.toFixed(2):'00.00'}}</span>
    </div>

    <div class="text-grey-light">
      <span class="text-grey-medium d-block mb-4 fw-medium">Gift Card</span>
      <span>${{refundDetailData.giftcard > 0?refundDetailData.giftcard.toFixed(2):'00.00'}}</span>
    </div>

    <div class="text-grey-light">
      <span class="text-grey-medium d-block mb-4 fw-medium">Tax ({{taxPer}}%)</span>
      <span>${{refundDetailData.tax > 0?refundDetailData.tax.toFixed(2):'00.00'}}</span>
    </div>

    <div class="text-grey-light">
      <span class="text-grey-medium d-block mb-4 fw-medium">Credit Card Processing Fee</span>
      <span>${{refundDetailData.processing_fee > 0?refundDetailData.processing_fee.toFixed(2):'00.00'}}</span>
    </div>
    <div class="text-grey-light">
      <span class="text-grey-medium d-block mb-4 fw-medium">Shipping Fee</span>
      <span>${{refundDetailData.shipping_fee > 0?refundDetailData.shipping_fee.toFixed(2):'00.00'}}</span>
    </div>

    <div class="text-grey-light">
      <span class="text-grey-medium d-block mb-4 fw-medium">Late Fee</span>
      <span>${{refundDetailData.late_fee > 0?refundDetailData.late_fee.toFixed(2):'00.00'}}</span>
    </div>

    <div class="text-grey-light">
      <span class="text-grey-medium d-block mb-4 fw-medium">Tip</span>
      <span>${{refundDetailData.tip > 0?refundDetailData.tip.toFixed(2):'00.00'}}</span>
    </div>

    <div class="text-grey-light">
      <span class="text-grey-medium d-block mb-4 fw-medium">Additional Amount</span>
      <span>${{refundDetailData.additional_amount > 0?refundDetailData.additional_amount.toFixed(2):'00.00'}}</span>
    </div>
    <div class="text-grey-light">
      <span class="text-grey-medium d-block mb-4 fw-medium">Payment Method</span>
      <span> {{ refundDetailData.payment_method | paymentMethodType }} </span>
    </div>
  </div>

  <div class="flex flex-justify-between flex-align-center flex-wrap gap-8px text-primary fs-20 pricebox">
    <span>Total</span>
    <span>${{refundDetailData.total_amount > 0 ? refundDetailData.total_amount.toFixed(2):'00.00'}}</span>
    <!-- <span>{{refundFormGroup.controls['refund_amount'].value}}</span> -->
  </div>
</div>

<div class="main-grid2 flex-align-start">
  <div class="items-select">
    <div class="table-sectionbox">
      <div class="flex flex-justify-between flex-wrap gap-8px flex-align-center section-box table-sectionbx">
        <h1 class="mb-0 mobile-full h1 text-grey-light">Select Items for Refund</h1>
      </div>
      <div class="table-responsive custom-scroll table-wrapper refund-llist">
        <table mat-table [dataSource]="dataSourceRefundDetail" class="mat-elevation-z8">

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef class="action-col check-select">
              <!-- Master Checkbox -->
              <mat-checkbox class="example-margin" [checked]="isAllSelected()" [indeterminate]="isIndeterminate()"
                (change)="masterToggle()"></mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let element" class="nowrap">
              <!-- Row Checkbox -->
              <mat-checkbox class="example-margin" [(ngModel)]="element.isSelected"
                (ngModelChange)="onCheckboxChange(element)"></mat-checkbox>
            </td>
          </ng-container>

          <!-- ITEMS Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef class="item-namecol">ITEMS</th>
            <td mat-cell *matCellDef="let element" class="nowrap">{{element.name}}</td>
          </ng-container>

          <!-- PRICE Column -->
          <ng-container matColumnDef="price">
            <th mat-header-cell *matHeaderCellDef>PRICE</th>
            <td mat-cell *matCellDef="let element">${{element.price.toFixed(2)}} </td>
          </ng-container>

          <!-- QTY Column -->
          <ng-container matColumnDef="quantity">
            <th mat-header-cell *matHeaderCellDef class="action-col">QTY.</th>
            <td mat-cell *matCellDef="let element" class="nowrap">
              <div class="product-updatewrap flex flex-align-center">
                <span class="flex-shrink-0 counterupdate-btn decrese-btn" (click)="decrementQuantity(element)">
                  <svg width="12" height="2" viewBox="0 0 12 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.04999 1H10.95" stroke="white" stroke-width="1.2" stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </span>
                <span
                  class="form-control counter-updater flex-shrink-0 flex flex-align-center flex-justify-center">{{element.quantity}}</span>
                <span class="flex-shrink-0 counterupdate-btn increase-btn" (click)="incrementQuantity(element)">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.04999 8H12.95" stroke="white" stroke-width="1.2" stroke-linecap="round"
                      stroke-linejoin="round" />
                    <path d="M8 3.0498V12.9498" stroke="white" stroke-width="1.2" stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </span>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="total_amount">
            <th mat-header-cell *matHeaderCellDef class="text-center">ITEM TOTAL</th>
            <td mat-cell *matCellDef="let element" class="text-center">
              ${{ (element.price * element.quantity).toFixed(2) }}
            </td>
          </ng-container>


          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>

    </div>
  </div>
  <div class="section-box">
    <h2 class="h1 text-grey-light mb-24">Reason for Refund</h2>
    <form [formGroup]="refundFormGroup">
      <div class="form-grid2">
        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-20">
          <mat-label>Refund to</mat-label>
          <input matInput formControlName="refund_to">
        </mat-form-field>
        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-20">
          <mat-label>Refund Amount</mat-label>
          <input matInput formControlName="refund_amount">
        </mat-form-field>
      </div>
      <mat-form-field appearance="outline" subscriptSizing="dynamic" class="small-select mb-20">
        <mat-label>Selct Reason for Refund</mat-label>
        <mat-select formControlName="select_reason">
          @for (que of questionList; track que.id) {
          <mat-option [value]="que.id" (click)="selectQuestion(que)">{{que.question}}</mat-option>
          }
        </mat-select>
      </mat-form-field>
      @if (isSelectOtherReason) {
      <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-20">
        <mat-label>Reason for Refund</mat-label>
        <textarea matInput rows="2" formControlName="reason"></textarea>
      </mat-form-field>
      }
      <div class="flex flex-justify-end">
        <button mat-flat-button class="btn-lg mobile-full" (click)="sendRefundRequest()"
          [disabled]="refundFormGroup.invalid || (refundFormGroup.controls['refund_amount'].value.slice(1)) <= 0">
          Submit Request
        </button>
      </div>
    </form>
  </div>
</div>