import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-update-list',
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    RouterModule,
    MatFormFieldModule,
    MatInputModule
  ],
  templateUrl: './update-list.component.html',
  styleUrl: './update-list.component.scss'
})
export class UpdateListComponent {

}
