import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../../modules/auth/auth.service';

export const authGuard: CanActivateFn = (route, state) => {
  if (!inject(AuthService).getToken()) {
    inject(Router).navigate(['/login'])
    return false;
  }
  return true;
};
export const authGuardafterLoggin: CanActivateFn = (route, state) => {
  if (inject(AuthService).getToken()) {
    if (route.routeConfig?.path == 'login' || route.routeConfig?.path == 'signup') {
      inject(Router).navigate(['/dashboard'])
      return true;
    }
  }
  return true
};
