import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from '../../shared/components/common/header/header.component';
import { SidebarComponent } from '../../shared/components/common/sidebar/sidebar.component';


@Component({
  selector: 'app-kitchen-station',
  standalone: true,
  imports: [
    RouterModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    HeaderComponent,
    SidebarComponent,
    MatSelectModule
  ],
  templateUrl: './kitchen-station.component.html',
  styleUrl: './kitchen-station.component.scss'
})
export class KitchenStationComponent {
  isClassAdded = false;

  toggleClass() {
    this.isClassAdded = !this.isClassAdded;
  }
}
