<div class="section-box payment-statuswrap">
  <h1 class="payment-title">#231128001</h1>
  <div class="cardgrid-4 mb-24">
    <div class="card-box">
      <div class="mb-20 text-grey-light cardinfo-box">
        <div><span class="badge badge-small badge-light-success">Complete</span></div>
        <div class="flex flex-justify-between cardinfo">
          <span>Payment - 1</span>
          <span>$10.00</span>
        </div>
        <div class="flex flex-justify-between cardinfo">
          <span>Mode</span>
          <span>Cash</span>
        </div>
        <div class="flex flex-justify-between cardinfo">
          <span>Total Guest</span>
          <span>15</span>
        </div>
      </div>
      <button mat-flat-button class="btn-outline-primary w-full" disabled>Send Payment Link</button>
    </div>
    <div class="card-box">
      <div class="mb-20 text-grey-light cardinfo-box">
        <div><span class="badge badge-small badge-light-success">Complete</span></div>
        <div class="flex flex-justify-between cardinfo">
          <span>Payment - 2</span>
          <span>$10.00</span>
        </div>
        <div class="flex flex-justify-between cardinfo">
          <span>Mode</span>
          <span>Cash</span>
        </div>
        <div class="flex flex-justify-between cardinfo">
          <span>Total Guest</span>
          <span>15</span>
        </div>
      </div>
      <button mat-flat-button class="btn-outline-primary w-full" disabled>Send Payment Link</button>
    </div>
    <div class="card-box">
      <div class="mb-20 text-grey-light cardinfo-box">
        <div><span class="badge badge-small badge-light-warning">Pending</span></div>
        <div class="flex flex-justify-between cardinfo">
          <span>Payment - 3</span>
          <span>$10.00</span>
        </div>
        <div class="flex flex-justify-between cardinfo">
          <span>Mode</span>
          <span>--</span>
        </div>
        <div class="flex flex-justify-between cardinfo">
          <span>Total Guest</span>
          <span>15</span>
        </div>
      </div>
      <button mat-flat-button class="btn-outline-primary w-full">Send Payment Link</button>
    </div>
    <div class="card-box">
      <div class="mb-20 text-grey-light cardinfo-box">
        <div><span class="badge badge-small badge-light-warning">Pending</span></div>
        <div class="flex flex-justify-between cardinfo">
          <span>Payment - 3</span>
          <span>$10.00</span>
        </div>
        <div class="flex flex-justify-between cardinfo">
          <span>Mode</span>
          <span>--</span>
        </div>
        <div class="flex flex-justify-between cardinfo">
          <span>Total Guest</span>
          <span>15</span>
        </div>
      </div>
      <button mat-flat-button class="btn-outline-primary w-full">Send Payment Link</button>
    </div>
  </div>

  <div class="btn-box flex flex-justify-end flex-wrap payment-btnbox">
    <button mat-flat-button class="btn-outline-secondary">View Transactions</button>
    <button mat-flat-button class="btn-secondary">Print Receipt</button>
    <button mat-flat-button class="" (click)="openDialog()">Send Receipt</button>
  </div>
</div>