import { Component, inject, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatFormField } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { HeaderComponent } from '../../shared/components/common/header/header.component';
import { SidebarComponent } from '../../shared/components/common/sidebar/sidebar.component';
import { EncryptionService } from '../../shared/services/encryption.service';
import { CommonModule, CurrencyPipe, TitleCasePipe } from '@angular/common';
import { DashboardService } from './services/dashboard.service';
import { Chart, ChartConfiguration, ChartType, registerables } from 'chart.js';
Chart.register(...registerables);
import { BehaviorSubject, switchMap, tap } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { ConstantService } from '../../shared/services/constant.service';
import { FormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    CurrencyPipe,
    HeaderComponent,
    MatButtonModule,
    MatFormField,
    MatInputModule,
    MatProgressBarModule,
    MatSelectModule,
    RouterModule,
    SidebarComponent,
    TitleCasePipe,
    TranslateModule,
    MatProgressSpinnerModule
  ],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss',
  providers: [EncryptionService, DashboardService],
})
export class DashboardComponent implements OnInit {
  private encDecObj = inject(EncryptionService);
  private dashboardService = inject(DashboardService);
  private constantService = inject(ConstantService);
  private router = inject(Router);
  public userDetail: any = {};
  public graphData: any = {};
  public months: any = this.constantService.getPrevious12Months();
  public selectedMonth: any = '';
  public selectedPrevMonth: any = '';
  public greeting: any = '';
  public profileCompletePer: number = 40;

  private labels: string[] = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  private _data$ = new BehaviorSubject<void>(undefined);
  data$ = this._data$.asObservable().pipe(
    switchMap(() =>
      this.dashboardService.getHomeDetails(
        this.selectedMonth,
        this.selectedPrevMonth
      )
    ),
    tap((res) => {
      // Refresh graph data - Destroy current graph if exist
      if (this.graphData.grossSalesCanvas) {
        this.graphData.grossSalesCanvas.destroy();
        this.graphData.returnsCanvas.destroy();
        this.graphData.transactionCanvas.destroy();
        this.graphData.netSalesCanvas.destroy();
      }

      this.graphData = res.data;
      this.chartReady();
    })
  );

  ngOnInit(): void {
    this.constantService.progressLoader.set(true);
    if (localStorage.getItem('userDetail')) {
      this.userDetail = JSON.parse(
        this.encDecObj.getDecrypt(localStorage.getItem('userDetail'))
      );
    }
    this.getGreeting();
  }

  refreshHomeData() {
    this._data$.next();
  }

  changeSelectedMonth(val: any) {
    this.selectedMonth = val;
    if (val === '') {
      this.selectedPrevMonth = val;
    }
    this.refreshHomeData();
  }

  changeSelectedPrevMonth(val: any) {
    this.selectedPrevMonth = val;
    this.refreshHomeData();
  }

  chartReady() {
    const modules = [
      {
        canvasId: 'grossSalesCanvas',
        data: this.graphData.gross_sales_module.content,
      },
      {
        canvasId: 'returnsCanvas',
        data: this.graphData.returns_module.content,
      },
      {
        canvasId: 'transactionCanvas',
        data: this.graphData.transaction_module.content,
      },
      {
        canvasId: 'netSalesCanvas',
        data: this.graphData.net_sales_module.content,
      },
    ];

    modules.forEach((module) => {
      const config = this.prepareChartDataset(this.labels, module.data);
      this.graphData[`${module.canvasId}`] = new Chart(module.canvasId, config);
    });
    this.constantService.progressLoader.set(false);
  }

  prepareChartDataset(
    labels: Array<string>,
    value: Array<{ label: string; data: Array<string> }>
  ): ChartConfiguration {
    const dataset = value.map((info, key) => {
      const backgroundColor = key % 2 === 0 ? '#52ce5e' : '#0787c4';
      return {
        ...info,
        backgroundColor,
        borderWidth: 1,
        borderRadius: 10,
      };
    });

    return this.getChartConfiguration(labels, dataset);
  }

  getChartConfiguration(
    labels: Array<string>,
    datasets: any
  ): ChartConfiguration {
    return {
      type: 'bar' as ChartType,
      data: { labels, datasets },
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: false
          }
        },
        scales: {
          x: { grid: { display: false }, beginAtZero: true },
          y: { grid: { display: false }, beginAtZero: true },
        },
      },
    };
  }
  getGreeting() {
    const currentHour = new Date().getHours();
    if (currentHour < 12) {
      this.greeting = 'good_morning';
    } else if (currentHour < 18) {
      this.greeting = 'good_afternoon';
    } else {
      this.greeting = 'good_evening';
    }
  }

  redirectToModule() {
    let res = {
      success: true,
      title: 'Settings'
    }
    this.constantService.selectMenu.next(res);
    this.router.navigate(['/settings'])
  }

}
