<mat-dialog-content class="text-center">
  <div class="mb-24 popup-image">
    <img src="assets/images/logout.png" alt="Are you sure you want to logout?" />
  </div>
  <div class="block text-grey mb-24">
    <h3 class="h1 mb-4 text-white">{{'logout_confirmation' | translate}}</h3>
  </div>
  <div class="btn-box">
    <button mat-flat-button mat-dialog-close class="w-full btn-outline-primary">{{'cancel' | translate}}</button>
    <button mat-flat-button class="w-full" (click)="logout()">
      {{'confirm' | translate}}
    </button>
  </div>
</mat-dialog-content>