import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[allowFlotNumberOnly]',
    standalone: true
})
export class FlotNumberDirective {

    constructor(private el: ElementRef) { }

    @HostListener('input', ['$event'])
    onInputChange(event: Event): void {
        const input = event.target as HTMLInputElement;
        let filteredValue = input.value.replace(/[^0-9.]/g, '');

        // Ensure only one decimal point is allowed
        const parts = filteredValue.split('.');
        if (parts.length > 2) {
            filteredValue = parts[0] + '.' + parts.slice(1).join('');
        }

        // Restrict to 7 digits before the decimal point
        if (parts[0].length > 10) {
            parts[0] = parts[0].substring(0, 10);
        }

        // Restrict to two decimal places
        if (parts.length === 2 && parts[1].length > 2) {
            parts[1] = parts[1].substring(0, 2);
        }

        // Reassemble the filtered value
        filteredValue = parts.join('.');

        // Prevent value from being just "0" or "."
        if (filteredValue === '0' || filteredValue === '.') {
            filteredValue = '';
        }

        if (input.value !== filteredValue) {
            input.value = filteredValue;

            // Dispatching a new 'input' event to update the model
            const newEvent = new Event('input', { bubbles: true });
            input.dispatchEvent(newEvent);
        }
    }
}
