<mat-dialog-content class="text-center">
  <div class="mb-24 radius-12">
    <img src="assets/images/delete-icon.png" alt="Delete Reservation" class="dialog-smallimg" />
  </div>
  <div class="block text-grey-light mb-24">
    <h3 class="h1 mb-24 text-white">Delete Reservation</h3>
    <p>Are you sure you want to delete this reservation?</p>
  </div>
  <div class="btn-box">
    <button mat-stroked-button  mat-dialog-close class="w-full">Cancel</button>
    <button mat-flat-button class="w-full" mat-dialog-close>Yes, Confirm</button>
  </div>
</mat-dialog-content>