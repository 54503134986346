<div class="section-box mb-24 card-boxblock">
  <h1 class="text-grey-light mb-20">{{'change_password' | translate}}</h1>
  <div class="">
    <form [formGroup]="passwordFormGroup">
      <div class="grid3-box mb-20">
        <mat-form-field appearance="outline" subscriptSizing="dynamic">
          <mat-label>{{"old_password" | translate}}</mat-label>
          <div class="password-field w-full" [class.active]="hideOldPass">
            <input matInput [type]="hideOldPass ? 'text' : 'password'" formControlName="oldPassword" required />
            <span class="eye-icon" (click)="hideOldPass = !hideOldPass"></span>
          </div>
        </mat-form-field>
        <mat-form-field appearance="outline" subscriptSizing="dynamic">
          <mat-label>{{"new_password" | translate}}</mat-label>
          <div class="password-field w-full" [class.active]="hidePass">
            <input matInput [type]="hidePass ? 'text' : 'password'" formControlName="password" required />
            <span class="eye-icon" (click)="hidePass = !hidePass"></span>
          </div>
        </mat-form-field>
        <mat-form-field appearance="outline" subscriptSizing="dynamic">
          <mat-label>{{"confirm_password" | translate}}</mat-label>
          <div class="password-field w-full" [class.active]="hideCPass">
            <input matInput [type]="hideCPass ? 'text' : 'password'" formControlName="confirmPassword" required />
            <span class="eye-icon" (click)="hideCPass = !hideCPass"></span>
          </div>
        </mat-form-field>
      </div>
      <div class="flex flex-justify-end">
        <button mat-flat-button class="mobile-full btn-lg" (click)="changePassword()">
          {{'update_now' |translate}}
        </button>
      </div>
    </form>
  </div>
</div>