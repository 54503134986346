
<div class="section-box mb-24 flex flex-wrap flex-justify-between flex-align-center header-section gap-8px">
  <h1 class="text-grey-light mb-0 mobile-full text-truncate">Bar Inventory</h1>
  <div class="flex filter-wrapper flex-wrap">
    <mat-form-field subscriptSizing="dynamic" class="filter-selection">
      <mat-select placeholder="Filter by">
        <mat-option value="option1">Option 1</mat-option>
        <mat-option value="option2" disabled>Option 2</mat-option>
        <mat-option value="option3">Option 3</mat-option>
      </mat-select>
    </mat-form-field>
    <button mat-flat-button class="btn-outline-primary hide-mobile create-menubtn mobile-half">Create Menu</button>
    <a mat-flat-button class="flex-shrink-0 additem-btn mobile-half" routerLink="/bar-inventory/edit">Add Item</a>
  </div>
</div>

<div class="table-responsive custom-scroll table-wrapper">
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

    <!-- ITEM NAME Column -->
    <ng-container matColumnDef="itemname">
      <th mat-header-cell *matHeaderCellDef class="item-namecol"> ITEM NAME </th>
      <td mat-cell *matCellDef="let element" class="nowrap">
        <div [innerHTML]="element.itemname" class="item-infobox"></div>
      </td>
    </ng-container>
  
    <!-- CATEGORY Column -->
    <ng-container matColumnDef="category">
      <th mat-header-cell *matHeaderCellDef> CATEGORY </th>
      <td mat-cell *matCellDef="let element"> {{element.category}} </td>
    </ng-container>
  
    <!-- BRANCH LOCATION Column -->
    <ng-container matColumnDef="branchLocation">
      <th mat-header-cell *matHeaderCellDef> BRANCH LOCATION </th>
      <td mat-cell *matCellDef="let element"> {{element.branchLocation}} </td>
    </ng-container>
  
    <!-- STOCK Column -->
    <ng-container matColumnDef="stock">
      <th mat-header-cell *matHeaderCellDef> STOCK </th>
      <td mat-cell *matCellDef="let element"> {{element.stock}} </td>
    </ng-container>

     <!-- AVAILABLE TO SELL Column -->
    <ng-container matColumnDef="availability">
      <th mat-header-cell *matHeaderCellDef> AVAILABLE TO SELL </th>
      <td mat-cell *matCellDef="let element" [innerHTML]="element.availability"></td>
    </ng-container>

     <!-- PRICE Column -->
    <ng-container matColumnDef="price">
      <th mat-header-cell *matHeaderCellDef>PRICE</th>
      <td mat-cell *matCellDef="let element">${{element.price}} </td>
    </ng-container>

    <!-- ACTION Column -->
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef class="action-col">ACTION</th>
      <td mat-cell *matCellDef="let element">
        <div class="flex table-actionbtn">
            <button (click)="openDialog()" mat-icon-button aria-label="View" matTooltip="View"><span class="icon-eye"></span></button>
            <button mat-icon-button aria-label="Edit" matTooltip="Edit"><span class="icon-development"></span></button>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
