<mat-dialog-content class="text-center">
  <div class="mb-24 radius-12">
    <img src="assets/images/split-ic.png" alt="Total Amount" class="dialog-smallimg" />
  </div>
  <div class="block text-grey-light mb-24">
    <h3 class="h1 text-white">${{localData.grandTotal}} Total Amount</h3>
  </div>
  <form [formGroup]="splitPaymentFormGroup">
    <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
      <mat-label>Enter Amount for Split</mat-label>
      <input matInput formControlName="amount" allowFlotNumberOnly />
    </mat-form-field>
    <div class="fs-16 split-btnwrap mb-16">
      @for (option of splitAmountOptions; track $index) {
      <span class="split-btn bg-grey-lighter fw-medium text-grey-medium cursor-pointer"
        [ngClass]="{'active': activeSplitOption === option.value}" (click)="selectSplit(option.value)">
        {{ option.value }}
      </span>
      }
    </div>
    @if (activeSplitOption == 'Custom') {
    <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
      <mat-label>Enter Custom Split Value</mat-label>
      <input matInput formControlName="customSplit" allowFlotNumberOnly />
    </mat-form-field>
    }
    <div class="btn-box text-center">
      <button mat-flat-button mat-dialog-close class="btn-outline-primary">Cancel</button>
      <button mat-flat-button (click)="paymentSplit()">Continue</button>
      <!-- <button mat-flat-button mat-dialog-close routerLink="/billing/payment">Continue</button> -->
    </div>
  </form>
</mat-dialog-content>