import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseMessage } from '../../../../model/interfaces/req.res.interface';

@Injectable({
  providedIn: 'root',
})
export class ManageRefundService {
  private httpClient = inject(HttpClient);

  //#region Manage Refund API's

  // Get Manage Refund List
  getRefundList(data: any): Observable<ResponseMessage> {
    let params = new HttpParams();
    params = params.append('page', data.page);
    params = params.append('per_page', data.per_page);
    data.search ? (params = params.append('search', data.search)) : '';
    data.transaction_type ? (params = params.append('transaction_type', data.transaction_type)) : '';
    data.user_business_location_id ? (params = params.append('user_business_location_id', data.user_business_location_id)) : '';
    return this.httpClient.get<ResponseMessage>('refund-list', { params: params });
  }


  // Get Refund Detail
  getRefundDetail(id: number): Observable<ResponseMessage> {
    return this.httpClient.get<ResponseMessage>(`refund-detail/${id}`);
  }

  // Get Refund Detail
  getRefundQuestion(): Observable<ResponseMessage> {
    return this.httpClient.get<ResponseMessage>(`refund-question-list`);
  }

  //Send Refund request
  sendRefundRequest(data: any) {
    return this.httpClient.post<ResponseMessage>(`refund-request`, data);
  }

  //#endregion Manage Refund API's
}
