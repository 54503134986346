<!-- Header -->
<div class="section-box mb-24 flex flex-wrap flex-justify-between flex-align-center header-section gap-8px header-box backbtn-wrap">
  <h1 class="text-white mb-0 text-truncate">Add Reservation</h1>
  <a mat-flat-button class="btn-outline-secondary backbtn" routerLink="/reservation">
    <svg width="7" height="15" viewBox="0 0 7 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.2876 13.4386L1.3976 8.54859C0.820097 7.97109 0.820097 7.02609 1.3976 6.44859L6.2876 1.55859" stroke="currentColor" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    Back</a>
</div>

<!-- Form -->
<form class="section-box form-box">
  <div class="form-grid3">
    <mat-form-field appearance="outline" subscriptSizing="dynamic">
      <mat-label>Enter Customer Name</mat-label>
      <input matInput>
    </mat-form-field>
    <mat-form-field appearance="outline" subscriptSizing="dynamic">
      <mat-label>Enter Mobile Number</mat-label>
      <input matInput>
    </mat-form-field>
    <mat-form-field subscriptSizing="dynamic" appearance="outline" class="small-select">
      <mat-label>No. of Guests</mat-label>
      <mat-select placeholder="Select">
        <mat-option value="option1">Option 1</mat-option>
        <mat-option value="option2" disabled>Option 2</mat-option>
        <mat-option value="option3">Option 3</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="example-form-field" subscriptSizing="dynamic" appearance="outline">
      <mat-label>Select Date</mat-label>
      <div class="w-full relative input-rightspace">
        <input matInput [matDatepicker]="datepicker" />
        <mat-datepicker-toggle matIconSuffix [for]="datepicker" class="ic-input cursor-pointer"></mat-datepicker-toggle>
      </div>
      <mat-datepicker #datepicker>
        <mat-datepicker-actions>
          <button mat-flat-button class="btn-outline-primary" matDatepickerCancel>Cancel</button>
          <button mat-flat-button matDatepickerApply>Apply</button>
        </mat-datepicker-actions>
      </mat-datepicker>
    </mat-form-field>
    <div class="mat-form-field-invalid">
      <label class="form-label">Select Time</label>
      <input [owlDateTimeTrigger]="dt4" [owlDateTime]="dt4" class="form-control">
      <owl-date-time [pickerType]="'timer'" #dt4 ></owl-date-time>
    </div>
    <mat-form-field subscriptSizing="dynamic" appearance="outline" class="small-select">
      <mat-label>Select Table</mat-label>
      <mat-select placeholder="Select">
        <mat-option value="option1">Option 1</mat-option>
        <mat-option value="option2">Option 2</mat-option>
        <mat-option value="option3">Option 3</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" subscriptSizing="dynamic">
      <mat-label>Event</mat-label>
      <input matInput>
    </mat-form-field>
    <div class="text-end flex flex-align-end flex-justify-end update-btnwrap">
      <button mat-flat-button class="btn-lg mobile-full">Update Reservation</button>
    </div>
  </div>
 
</form>