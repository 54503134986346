<div class="section-box mb-24 flex flex-wrap order-infowrapper">
  <div class="order-infobox radius-12">
    <span class="order-infoicon">
      <img src="assets/images/icons/ic-transaction.svg" alt="Number of Transactions" />
    </span>
    <div class="fs-14 text-grey ">
      <h3 class="order-count text-white fw-bold">{{trasactionTotalCount?.transaction_count}}</h3>
      <span>Number of Transactions</span>
    </div>
  </div>
  <div class="order-infobox radius-12">
    <span class="order-infoicon">
      <img src="assets/images/icons/ic-money.svg" alt="Total Collection Before Fees" />
    </span>
    <div class="fs-14 text-grey ">
      <h3 class="order-count text-white fw-bold">${{trasactionTotalCount?.amount_before_fees.toFixed(2)}}</h3>
      <span>Total Collection Before Fees</span>
    </div>
  </div>
  <div class="order-infobox radius-12">
    <span class="order-infoicon">
      <img src="assets/images/icons/graph.svg" alt="Total Net sales After Fees" />
    </span>
    <div class="fs-14 text-grey ">
      <h3 class="order-count text-white fw-bold">${{trasactionTotalCount?.amount_after_fees.toFixed(2)}}</h3>
      <span>Total Net sales After Fees</span>
    </div>
  </div>
</div>

<!-- Starts Filter Section -->
<div class="filter-boxwrapper section-box flex flex-justify-end flex-wrap mb-20">
  <mat-form-field subscriptSizing="dynamic" class="filter-date date-field">
    <div class="w-full relative input-rightspace">
      <input matInput [matDatepicker]="fromDate" [max]="" placeholder="From" (click)="fromDate.open()"
        [(ngModel)]="selectFromDate" readonly (dateChange)="selectSearch(1, selectFromDate)">
      <mat-datepicker-toggle matIconSuffix [for]="fromDate" class="ic-input cursor-pointer"></mat-datepicker-toggle>
    </div>
    <mat-datepicker #fromDate></mat-datepicker>
  </mat-form-field>
  <mat-form-field subscriptSizing="dynamic" class="filter-date date-field">
    <div class="w-full relative input-rightspace">
      <input matInput [matDatepicker]="toDate" [max]="currentDate" [min]="selectFromDate" placeholder="To"
        (click)="toDate.open()" [disabled]="!selectFromDate" [(ngModel)]="selectToDate"
        (dateChange)="selectSearch(2, selectToDate)" readonly>
      <mat-datepicker-toggle matIconSuffix [for]="toDate" class="ic-input cursor-pointer"></mat-datepicker-toggle>
    </div>
    <mat-datepicker #toDate></mat-datepicker>
  </mat-form-field>
  <mat-form-field subscriptSizing="dynamic" class="filter-day">
    <mat-select placeholder="Day" [(ngModel)]="selectDay">
      <mat-option value="" (click)="selectSearch(3, 0)">
        Day
      </mat-option>
      @for (day of dayLists; track day.id) {
      <mat-option (click)="selectSearch(3, day.id)" [value]="day.id">
        {{ day.name }}
      </mat-option>
      }
    </mat-select>
  </mat-form-field>

  <mat-form-field subscriptSizing="dynamic" class="filter-paymenttype">
    <mat-select placeholder="Payment Method" [(ngModel)]="selectPaymentMenthod">
      <mat-option value="" (click)="selectSearch(4, 0)">
        Payment Method
      </mat-option>
      @for (pay of paymentMethods; track pay.id) {
      <mat-option (click)="selectSearch(4, pay.id)" [value]="pay.id">
        {{ pay.name }}
      </mat-option>
      }
    </mat-select>
  </mat-form-field>
  <mat-form-field subscriptSizing="dynamic" class="filter-paymenttype">
    <mat-select placeholder="Payment Type" [(ngModel)]="selectPaymentType">
      <mat-option value="" (click)="selectSearch(5, 0)">
        Payment Type
      </mat-option>
      @for (type of paymentTypes; track type.id) {
      <mat-option (click)="selectSearch(5, type.id)" [value]="type.id">
        {{ type.name }}
      </mat-option>
      }
    </mat-select>
  </mat-form-field>
  <mat-form-field subscriptSizing="dynamic" class="filter-location">
    <mat-select placeholder="Locations" [(ngModel)]="selectBranch">
      <mat-option value="" (click)="selectSearch(6, 0)">
        Locations
      </mat-option>
      @for (branch of branchList; track $index) {
      <mat-option (click)="selectSearch(6, branch.business_location_id)" [value]="branch.business_location_id">
        {{ branch.name }}
      </mat-option>
      }
    </mat-select>
  </mat-form-field>
  <mat-form-field subscriptSizing="dynamic" class="filter-item">
    <mat-select placeholder="Items" [(ngModel)]="selectProduct">
      <mat-option value="" (click)="selectSearch(7, 0)">
        Items
      </mat-option>
      @for (item of productList; track $index) {
      <mat-option (click)="selectSearch(7, item.id)" [value]="item.id">
        {{ item.name }}
      </mat-option>
      }
    </mat-select>
  </mat-form-field>
  <div>
    <button mat-flat-button class="flex-shrink-0 export-btn" [disabled]="isResetButtonDisabled"
      (click)="resetFilter()">Reset</button>
  </div>
</div>
<!-- Ends Filter Section -->

<div class="table-sectionbox">
  <div class="flex flex-justify-between flex-wrap gap-8px flex-align-center section-box table-sectionbx">
    <h1 class="mb-0 mobile-full h3">Transaction</h1>
    <div class="flex gap-16px flex-grow-1 flex-justify-end">
      <mat-form-field appearance="outline" subscriptSizing="dynamic" class="small-input search-inputbx">
        <div class="w-full relative input-rightspace">
          <input type="search" class="form-control" matInput (keyup)="serachTransactions()"
            placeholder="Search Transaction" [(ngModel)]="serachTransaction" />
          <mat-icon class="ic-input cursor-pointer">search</mat-icon>
        </div>
      </mat-form-field>
      <button mat-flat-button class="flex-shrink-0 export-btn" [disabled]="totalRecord == 0"
        (click)="exportTransaction()">Export Transaction</button>
    </div>
  </div>
  <div class="table-responsive custom-scroll table-wrapper" infinite-scroll (scrolled)="transactionListScrollDown()"
    [immediateCheck]="true" [scrollWindow]="false" [infiniteScrollDistance]="20" [infiniteScrollThrottle]="0">
    <table mat-table [dataSource]="dataSourceTransactions" class="mat-elevation-z8">

      <!-- Order ID Column -->
      <ng-container matColumnDef="order_id">
        <th mat-header-cell *matHeaderCellDef>#Order ID</th>
        <td mat-cell *matCellDef="let element" class="nowrap"> {{element.order_id}} </td>
      </ng-container>

      <!-- AMOUNT Column -->
      <ng-container matColumnDef="sub_total">
        <th mat-header-cell *matHeaderCellDef>AMOUNT</th>
        <td mat-cell *matCellDef="let element" class="nowrap"> ${{element.order_total_amount?element.order_total_amount?.toFixed(2):0}} </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="transaction_date_time">
        <th mat-header-cell *matHeaderCellDef>DATE & TIME</th>
        <td mat-cell *matCellDef="let element" class="nowrap"> {{element.transaction_date_time | dateTime24Hr:true}} </td>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="collected_from">
        <th mat-header-cell *matHeaderCellDef>COLLECTED FROM</th>
        <td mat-cell *matCellDef="let element"> {{element.collected_from}} </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="payment_method">
        <th mat-header-cell *matHeaderCellDef>PAYMENT METHOD</th>
        <td mat-cell *matCellDef="let element" class="nowrap"> {{ element.payment_method | paymentMethodType }} </td>
      </ng-container>
      <ng-container matColumnDef="cashier_name">
        <th mat-header-cell *matHeaderCellDef>CASHIER NAME</th>
        <td mat-cell *matCellDef="let element"> {{element.cashier_name}}</td>
      </ng-container>
      <ng-container matColumnDef="total_amount">
        <th mat-header-cell *matHeaderCellDef>TOTAL AMOUNT</th>
        <td mat-cell *matCellDef="let element">${{element.total_amount.toFixed(2)}} </td>
      </ng-container>
      <ng-container matColumnDef="viewReceipt">
        <th mat-header-cell *matHeaderCellDef class="action-col">VIEW RECEIPT</th>
        <td mat-cell *matCellDef="let element">
          <div class="flex table-actionbtn flex-justify-center">
            <button mat-icon-button aria-label="View" matTooltip="View" (click)="viewReceipt(element.order_id,element.id)">
              <span class="icon-eye"></span>
            </button>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    @if (totalRecord == 0 && !constantService.progressLoader()) {
    <div class="no-records flex flex-align-center flex-justify-center flex-column">
      <img src="assets/images/empty-state.png" alt="No Record Found" class="mb-24" />
      <div class="text-primary fw-medium">{{'no_records' | translate}}</div>
    </div>
    }
  </div>
</div>