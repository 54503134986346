import { Pipe, PipeTransform } from '@angular/core';
import { paymentMethod } from '../../model/const/common.const';

@Pipe({
  name: 'paymentMethodType',
  standalone: true,
})
export class PaymentMethodTypePipe implements PipeTransform {

  private paymentMethodLookup: { [key: number]: string } = paymentMethod.reduce((acc, curr) => {
    acc[curr.id] = curr.name;
    return acc;
  }, {} as { [key: number]: string });

  transform(id: number): string {
    return this.paymentMethodLookup[id] || 'Unknown';
  }
}
