import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
export interface PeriodicElement {
  moduleName: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {moduleName: 'Food',},
  {moduleName: 'Food',},
  {moduleName: 'Food',},
  {moduleName: 'Food',},
  {moduleName: 'Food',},
  {moduleName: 'Food',},
  {moduleName: 'Food',},
  {moduleName: 'Food',},
  {moduleName: 'Food',},
];
@Component({
  selector: 'app-create-edit-sub-vendor',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatTableModule,
    MatButtonModule,
    MatInputModule
  ],
  templateUrl: './create-edit-sub-vendor.component.html',
  styleUrls: ['./create-edit-sub-vendor.component.scss']
})
export class CreateEditSubVendorComponent implements OnInit {
  displayedColumns: string[] = ['moduleName',  'viewReport', 'editOrder'];
  dataSource = ELEMENT_DATA;
  constructor() { }

  ngOnInit() {
  }

}
