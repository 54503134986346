<mat-dialog-content class="text-center">
  <div class="mb-24 radius-12 flex flex-justify-center">
    <img src="assets/images/delete-ic.png" alt="Delete Menu" class="dialog-smallimg" />
  </div>
  <div class="block text-grey-light mb-24">
    <h3 class="h1 mb-24 text-white">Delete Menu</h3>
    <p>Are you sure you want to delete this menu?</p>
  </div>
  <div class="btn-box text-center">
    <button mat-flat-button mat-dialog-close class="btn-outline-primary">Cancel</button>
    <button mat-flat-button mat-dialog-close>Confirm</button>
  </div>
</mat-dialog-content>