import { Component, OnInit } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-create-order-restaurant',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    TranslateModule
  ],
  templateUrl: './create-order-restaurant.component.html',
  styleUrls: ['./create-order-restaurant.component.scss']
})
export class CreateOrderRestaurantComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
