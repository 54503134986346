<mat-dialog-content class="text-center">
  <form [formGroup]="paymentFormGroup">
    <div class="mb-24 radius-12">
      <img src="assets/images/send-icon.png" alt="Send Payment Link" class="dialog-smallimg" />
    </div>
    <div class="block text-grey-light mb-24">
      <h3 class="h1 text-white">Send Payment Link</h3>
    </div>
    <div class="mb-20 text-center">
      <label id="example-radio-group-label" class="text-grey-light d-block mb-12 option-label">Choose Option</label>
      <mat-radio-group aria-label="Select an option" class="flex-justify-center"
        formControlName="selectedPaymentOption">
        @for (payment of paymentOptions; track $index) {
        <mat-radio-button [value]="payment.id" (click)="setDynamicValidation(payment.id)">{{payment.translateLabel |
          translate}}</mat-radio-button>
        }
      </mat-radio-group>
    </div>
    @if (paymentFormGroup.value.selectedPaymentOption == 1) {
    <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-20">
      <mat-label>Email</mat-label>
      <input matInput formControlName="email" />
    </mat-form-field>
    }@else if (paymentFormGroup.value.selectedPaymentOption == 2) {
    <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-20">
      <mat-label>Phone Number</mat-label>
      <input matInput formControlName="phoneNumber" />
    </mat-form-field>
    }
  </form>
  <div class="btn-box text-center">
    <button mat-flat-button mat-dialog-close class="btn-outline-primary">Cancel</button>
    <button mat-flat-button (click)="paymentLink()">Send Now</button>
  </div>
</mat-dialog-content>