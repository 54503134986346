<div class="section-box">
  <div>
    <div class="mb-20">
      <label
        id="example-radio-group-label"
        class="text-grey-medium d-block mb-10"
        >Type of Business</label
      >
      <mat-radio-group aria-label="Select an option">
        <mat-radio-button value="1">Retail</mat-radio-button>
        <mat-radio-button value="2">Restaurant</mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="order-grid3">
      <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
        <mat-label>Enter Your Business Name</mat-label>
        <input matInput>
      </mat-form-field> 
      <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
        <mat-label>Phone Number</mat-label>
        <input matInput>
      </mat-form-field> 
      <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
        <mat-label>Estimated Annual Revenue</mat-label>
        <input matInput>
      </mat-form-field> 
    </div>
    <div class="mb-20">
      <label
        id="example-radio-group-label"
        class="text-grey-medium d-block mb-10"
        >Business Location</label
      >
      <mat-radio-group aria-label="Select an option">
        <mat-radio-button value="1">Without Physical Location</mat-radio-button>
        <mat-radio-button value="2">With Physical Location</mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="mb-20">
      <label
        id="example-radio-group-label"
        class="text-grey-medium d-block mb-10"
        >Business Branch(S)</label
      >
      <mat-radio-group aria-label="Select an option">
        <mat-radio-button value="1">Single</mat-radio-button>
        <mat-radio-button value="2">Multiple</mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="order-grid3">
      <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
        <mat-label>Add Tag Line</mat-label>
        <input matInput>
      </mat-form-field> 
      <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
        <mat-label>Shipping Cost</mat-label>
        <input matInput>
      </mat-form-field> 
      <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
        <mat-label>Shipping Cost</mat-label>
        <input matInput>
      </mat-form-field> 
      
    </div>

    <div class="flex url-box mb-20 flex-align-end">
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-label>Store URL</mat-label>
        <div class="w-full relative input-rightspace">
        <input matInput type="url">
        <span class="ic-input cursor-pointer">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M4.09602 7.81017C4.3152 8.03033 4.3144 8.38648 4.09425 8.60566C2.62921 10.0642 2.63289 12.438 4.09602 13.9077C5.55454 15.3727 7.92837 15.369 9.39802 13.9059C10.8642 12.4463 10.8655 10.0731 9.39714 8.60478C9.17747 8.38511 9.17747 8.02895 9.39714 7.80928C9.61681 7.58961 9.97296 7.58961 10.1926 7.80928C12.0993 9.71596 12.1006 12.8028 10.1917 14.7032C8.2864 16.6 5.20023 16.6114 3.29875 14.7014C1.40188 12.7961 1.39056 9.70988 3.30052 7.8084C3.52068 7.58922 3.87684 7.59001 4.09602 7.81017Z" fill="#989898"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M13.9128 4.08875C12.3682 2.55075 9.87442 2.54691 8.34082 4.08706C6.80389 5.63055 6.80511 8.12505 8.33997 9.65991C8.55964 9.87958 8.55964 10.2357 8.33997 10.4554C8.1203 10.6751 7.76415 10.6751 7.54448 10.4554C5.56934 8.48027 5.57056 5.27476 7.54363 3.29326C9.52003 1.30841 12.7262 1.31957 14.7066 3.29156C16.6915 5.26797 16.6803 8.47414 14.7083 10.4546C14.4891 10.6747 14.133 10.6755 13.9128 10.4563C13.6927 10.2371 13.6919 9.8809 13.9111 9.66076C15.4491 8.11618 15.453 5.62235 13.9128 4.08875Z" fill="#989898"/>
          </svg>
        </span>
        </div>

          
      </mat-form-field> 
      <button mat-flat-button class="btn-outline-primary flex-shrink-0" (click)="openDialog()">Generate QR Code</button>
    </div>

    <div>
      <div class="order-grid2">
        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="small-select">
          <mat-label>Add Item</mat-label>
          <mat-select >
            <mat-option value="option1">Option 1</mat-option>
            <mat-option value="option2">Option 2</mat-option>
            <mat-option value="option3">Option 3</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
          <mat-label>Category</mat-label>
          <input matInput disabled>
        </mat-form-field> 
      </div>
      <div class="text-end addremove-btn mb-20">
        <span class="text-danger fs-14 cursor-pointer fw-bold">x Remove</span>
      </div>
    </div>

    <div>
      <div class="order-grid2">
        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="small-select">
          <mat-label>Add Item</mat-label>
          <mat-select >
            <mat-option value="option1">Option 1</mat-option>
            <mat-option value="option2">Option 2</mat-option>
            <mat-option value="option3">Option 3</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
          <mat-label>Category</mat-label>
          <input matInput>
        </mat-form-field> 
      </div>
      <div class="text-end addremove-btn mb-20">
        <span class="text-success-light fs-14 cursor-pointer fw-bold">+ Add More</span>
      </div>
    </div>

    <div class="text-end">
      <button mat-flat-button class="btn-lg mobile-full">Save</button>
    </div>
  </div>
</div>