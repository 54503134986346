<mat-dialog-content class="text-center">
  <div class="mb-24 radius-12">
    <img src="assets/images/table-ic.png" alt="Add Table" class="dialog-smallimg" />
  </div>
  <div class="block text-grey-light mb-24">
    <h3 class="h1 mb-24 text-white">Add Table</h3>
    <p>Fill in the details to add new table</p>
  </div>
  <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
    <mat-label>Table Name</mat-label>
    <input matInput />
  </mat-form-field>
  <div class="btn-box text-center">
    <button mat-flat-button mat-dialog-close class="btn-outline-primary">Cancel</button>
    <button mat-flat-button mat-dialog-close>Add Now</button>
  </div>
</mat-dialog-content>