import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-create-purchase-order',
  standalone: true,
  providers: [provideNativeDateAdapter()],
  imports: [
    RouterModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatRadioGroup,
    MatRadioButton,
    MatDatepickerModule
  ],
  templateUrl: './create-purchase-order.component.html',
  styleUrl: './create-purchase-order.component.scss'
})
export class CreatePurchaseOrderComponent {

}
