import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-waiting-tables',
  standalone: true,
  imports: [
    RouterModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
  ],
  templateUrl: './waiting-tables.component.html',
  styleUrl: './waiting-tables.component.scss'
})
export class WaitingTablesComponent {

}
