import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appUppercaseAlphaNumeric]',
  standalone: true,
})
export class UppercaseAlphaNumericDirective {

  constructor(private ngControl: NgControl) {}

  @HostListener('input', ['$event']) onInputChange(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;

    // Convert input to uppercase and remove non-alphanumeric characters
    const sanitizedValue = input.value.toUpperCase().replace(/[^A-Z0-9]/g, '');

    // Update the input field display
    input.value = sanitizedValue;

    // Update the form control value
    if (this.ngControl && this.ngControl.control) {
      this.ngControl.control.setValue(sanitizedValue);
    }
  }
}
