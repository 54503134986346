<div class="video-container">
    <video
      [class.show]="!isCaptured"
      [class.hide]="isCaptured"
      #video
      id="video"
      [width]="WIDTH"
      [height]="HEIGHT"
      autoplay
    ></video>
    <canvas
      [class.show]="isCaptured"
      [class.hide]="!isCaptured"
      #canvas
      id="canvas"
      [width]="WIDTH"
      [height]="HEIGHT"
    ></canvas>
  </div>
  
  <div class="snap-container">
    <button  mat-flat-button class="btn-danger btn-lg"  *ngIf="!isCaptured" (click)="capture()">
      {{'snap_photo' | translate}}
    </button>
  </div>
