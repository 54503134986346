<!-- Starts Header -->
<div class="section-box mb-24 flex flex-wrap flex-justify-between flex-align-center header-section gap-8px backbtn-wrap">
  <h1 class="text-grey-light mb-0 text-truncate">Edit Vendor</h1>
  <a mat-flat-button class="btn-outline-secondary backbtn" routerLink="/bar-inventory">
    <svg width="7" height="15" viewBox="0 0 7 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.2876 13.4386L1.3976 8.54859C0.820097 7.97109 0.820097 7.02609 1.3976 6.44859L6.2876 1.55859" stroke="currentColor" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    Back</a>
</div>

<div class="section-box gridform-2 mb-24">
  <mat-form-field appearance="outline" subscriptSizing="dynamic">
    <mat-label>Name</mat-label>
    <input matInput />
  </mat-form-field>
  <mat-form-field appearance="outline" subscriptSizing="dynamic">
    <mat-label>Email</mat-label>
    <input matInput />
  </mat-form-field>
</div>

<div class="table-responsive custom-scroll table-wrapper mb-20">
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

    <!-- MODULE NAME Column -->
    <ng-container matColumnDef="moduleName">
      <th mat-header-cell *matHeaderCellDef class="module-name">MODULE NAME</th>
      <td mat-cell *matCellDef="let element">{{element.moduleName}}</td>
    </ng-container>
  
    <!-- VIEW REPORT Column -->
    <ng-container matColumnDef="viewReport">
      <th mat-header-cell *matHeaderCellDef class="text-center">VIEW REPORT</th>
      <td mat-cell *matCellDef="let element" class="text-center">--</td>
    </ng-container>
 
    <!-- ACTION Column -->
    <ng-container matColumnDef="editOrder">
      <th mat-header-cell *matHeaderCellDef class="text-center">EDIT ORDER</th>
      <td mat-cell *matCellDef="let element">
        <div class="flex table-actionbtn flex-justify-center">
            <button mat-icon-button aria-label="Edit Order" matTooltip="Edit Order">
              <img src="assets/images/icons/edit-order.svg" alt="Edit Order" />
            </button>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true;"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
<div class="flex flex-justify-end">
  <button mat-flat-button class="mobile-full btn-lg update-btn">Update</button>
</div>
