<!-- Starts Header -->
<div class="section-box mb-24 flex flex-wrap flex-justify-between flex-align-center header-section gap-8px backbtn-wrap">
  <h1 class="text-white mb-0 text-truncate">Add Item</h1>
  <a mat-flat-button class="btn-outline-secondary backbtn" routerLink="/bar-inventory">
    <svg width="7" height="15" viewBox="0 0 7 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.2876 13.4386L1.3976 8.54859C0.820097 7.97109 0.820097 7.02609 1.3976 6.44859L6.2876 1.55859" stroke="currentColor" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    Back</a>
</div>

<!-- STarts Form -->
<form class="section-box block edit-formbox">
  <div class="mb-20">
    <label
      id="example-radio-group-label"
      class="text-grey-medium d-block mb-10"
      >Item For</label
    >
    <mat-radio-group aria-label="Select an option">
      <mat-radio-button value="1">Restaurant</mat-radio-button>
      <mat-radio-button value="2">Bar</mat-radio-button>
    </mat-radio-group>
  </div>
  <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-20">
    <mat-label>Item Name</mat-label>
    <input matInput>
  </mat-form-field>
  <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-20">
    <mat-label>Item Description</mat-label>
    <textarea matInput rows="3"></textarea>
  </mat-form-field>
  <div class="mb-20 mat-form-field-invalid ">
    <label for="upload-image" class="form-label">Upload Image</label>
    <label for="upload-image" class="form-control w-full flex flex-align-center w-full relative">
      <input type="file" class="form-control" id="upload-image" hidden />
      <span class="text-white file-text">Browse</span>
      <span class="icon-image-plus ic-input cursor-pointer"></span>
    </label>
  </div>
  <div class="grid-5 mb-20">
    <mat-form-field appearance="outline" subscriptSizing="dynamic">
      <mat-label>Price</mat-label>
      <div class="w-full relative input-rightspace">
        <input matInput>
        <span class="icon-ic-price ic-input cursor-pointer"></span>
      </div>
    </mat-form-field>
    <mat-form-field appearance="outline" subscriptSizing="dynamic">
      <mat-label>Item Unit</mat-label>
      <input matInput type="number">
    </mat-form-field>
    <mat-form-field appearance="outline" subscriptSizing="dynamic" class="small-select">
      <mat-label>Available Stock</mat-label>
      <mat-select >
        <mat-option value="option1">Option 1</mat-option>
        <mat-option value="option2" disabled>Option 2</mat-option>
        <mat-option value="option3">Option 3</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" subscriptSizing="dynamic" class="small-select">
      <mat-label>Add it to Menu</mat-label>
      <mat-select >
        <mat-option value="option1">Option 1</mat-option>
        <mat-option value="option2">Option 2</mat-option>
        <mat-option value="option3">Option 3</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" subscriptSizing="dynamic" class="small-select">
      <mat-label>Add Categories</mat-label>
      <mat-select >
        <mat-option value="option1">Option 1</mat-option>
        <mat-option value="option2">Option 2</mat-option>
        <mat-option value="option3">Option 3</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="text-end">
    <button mat-flat-button class="btn-lg mobile-full">Update</button>
  </div>
</form>