<mat-dialog-content class="text-center">
  <div class="mb-24 radius-12">
    <div class="mb-24 radius-12">
      <img src="assets/images/delete-icon.png" alt="Delete Reservation" class="dialog-smallimg" />
    </div>
  </div>
  <div class="block text-grey mb-24">
    <h3 class="h1 mb-4 text-white">{{localData?.title | translate}}</h3>
    <p>{{localData?.message | translate : {id:localData?.id} }}</p>
  </div>
  <div class="btn-box">
    <button mat-stroked-button mat-dialog-close class="w-full">{{localData.buttonName.cancel | translate}}</button>
    <button mat-flat-button class="w-full" (click)="submit()">{{localData.buttonName.yes | translate}}</button>
  </div>
</mat-dialog-content>