<div class="section-box mb-24 flex flex-wrap  flex-align-center gap-8px header-bx">
  <h1 class="text-white mb-0 mobile-full fs-20 text-truncate">Waiting List</h1>
  <div class="flex gap-16px flex-grow-1 flex-justify-end mobile-full">
    <button mat-flat-button class="btn-outline-primary hide-mobile btn-lg mobile-half">Show Table's </button>
    <button mat-flat-button class="flex-shrink-0 btn-lg mobile-half">Add Waiting</button>
  </div>
</div>

<div class="table-responsive custom-scroll table-wrapper">
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

    <!-- CUSTOMER NAME Column -->
    <ng-container matColumnDef="customerName">
      <th mat-header-cell *matHeaderCellDef>CUSTOMER NAME</th>
      <td mat-cell *matCellDef="let element" class="nowrap"> {{element.customerName}} </td>
    </ng-container>
  
  
  
    <!-- NO. OF GUESTS Column -->
    <ng-container matColumnDef="guestNo">
      <th mat-header-cell *matHeaderCellDef class="text-center">NO. OF GUESTS</th>
      <td mat-cell *matCellDef="let element" class="text-center"> {{element.guestNo}} </td>
    </ng-container>
  
      <!-- MOBILE NUMBER Column -->
      <ng-container matColumnDef="mobileNo">
        <th mat-header-cell *matHeaderCellDef>MOBILE NUMBER</th>
        <td mat-cell *matCellDef="let element" class="nowrap"> {{element.mobileNo}} </td>
      </ng-container>

    <!-- QUEUE NUMBER Column -->
    <ng-container matColumnDef="queueNo">
      <th mat-header-cell *matHeaderCellDef class="text-center">QUEUE NUMBER</th>
      <td mat-cell *matCellDef="let element" class="nowrap text-center"> {{element.queueNo}} </td>
    </ng-container>

    <ng-container matColumnDef="event">
      <th mat-header-cell *matHeaderCellDef>EVENT</th>
      <td mat-cell *matCellDef="let element">{{element.event}} </td>
    </ng-container>
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef class="action-col">ACTION</th>
      <td mat-cell *matCellDef="let element">
        <div class="flex table-actionbtn">
          <span class="badge badge-success cursor-pointer" (click)="openDialog()">Ready</span>
            <button (click)="openDialogEdit()"  mat-icon-button aria-label="Edit" matTooltip="Edit" ><span class="icon-edit-pencil"></span></button>
            <button (click)="openDialogDelete()" mat-icon-button aria-label="Delete" matTooltip="Delete"><span class="icon-ic-trash"></span></button>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>