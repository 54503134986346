import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ResponseMessage } from '../../../../model/interfaces/req.res.interface';

@Injectable({
  providedIn: 'root'
})
export class MenuService {


  private httpClient = inject(HttpClient)
  
  //#region Menu API's

  // Get Menu List
  getMenuList(data: any): Observable<ResponseMessage> {
    let params = new HttpParams();
    params = params.append('page', data.page);
    params = params.append('per_page', data.per_page);
    params = params.append('business_location_id', data.business_location_id);
    data.search_text ? params = params.append('search_name', data.search_text) : '';
    return this.httpClient.get<ResponseMessage>('get-food-menu', { params: params });
  }

  // Get Menu Code
  getMenuCode(id: number): Observable<ResponseMessage> {
    return this.httpClient.get<ResponseMessage>(`get-code-business-wise/${id}`);
  }

  // Get Branch List
  getBranceList(): Observable<ResponseMessage> {
    return this.httpClient.get<ResponseMessage>('view-branch');
  }

  // Get Category List
  getCategoryList(): Observable<ResponseMessage> {
    return this.httpClient.get<ResponseMessage>('get-category');
  }

  // Get Product List filter
  getProduct(data: any): Observable<ResponseMessage> {
    let params = new HttpParams();
    params = params.append('business_location_id', data.business_location_id);
    return this.httpClient.get<ResponseMessage>('get-items', { params: params });
  }

  // Add New Menu 
  addMenu(data: any): Observable<ResponseMessage> {
    return this.httpClient.post<ResponseMessage>('add-food-menu', data);
  }

  // Get Menu Detail
  getMenuDetail(id: number): Observable<ResponseMessage> {
    return this.httpClient.get<ResponseMessage>(`get-food-menu-by-id/${id}`);
  }

  // Update Menu
  updateMenu(data: any): Observable<ResponseMessage> {
    return this.httpClient.post<ResponseMessage>('update-food-menu', data);
  }

  // Delete Menu 
  deleteMenu(id: number): Observable<ResponseMessage> {
    return this.httpClient.delete<ResponseMessage>(`delete-food-menu/${id}`);
  }

  // Search Menu by name
  searchMenu(data: any): Observable<ResponseMessage> {
    return this.httpClient.post<ResponseMessage>('search-food-menu', data);
  }

  //#endregion Menu API's

}
