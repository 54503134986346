import { Component, inject } from '@angular/core';
import { ConstantService } from '../../shared/services/constant.service';
import { combineLatest, map, of, switchMap } from 'rxjs';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-policy-and-term-conditions',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './policy-and-term-conditions.component.html',
  styleUrl: './policy-and-term-conditions.component.scss',
})
export class PolicyAndTermConditionsComponent {
  private constantService = inject(ConstantService);
  private route = inject(ActivatedRoute);

  data$ = this.route.url.pipe(
    switchMap((event: any) =>
      combineLatest([
        of(event),
        this.constantService.getPolicyAndTermConditions(),
      ])
    ),
    map(([event, res]) => {
      const activeRoute = event[0].path;
      if (activeRoute === 'privacy-policy') {
        return res.data.policy;
      } else {
        return res.data.terms;
      }
    })
  );
}
