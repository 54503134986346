import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioGroup, MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { ManageOnlineOrderComponent } from './components/manage-online-order/manage-online-order.component';
import { SetupOnlineOrderComponent } from './components/setup-online-order/setup-online-order.component';
import { MyOrdersComponent } from './components/my-orders/my-orders.component';

@Component({
  selector: 'app-online-ordering',
  standalone: true,
  imports: [
    MatRadioGroup,
    MatRadioModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    RouterModule,
    SetupOnlineOrderComponent,
    ManageOnlineOrderComponent,
    MyOrdersComponent
  ],
  templateUrl: './online-ordering.component.html',
  styleUrl: './online-ordering.component.scss'
})
export class OnlineOrderingComponent {

}
