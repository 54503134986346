import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
@Injectable({ providedIn: 'root' })
export class EncryptionService {
  secret_key_encryption = 'secret_key_Muybi_12345';

  setEncrypt(value: any) {
    var iv = CryptoJS.enc.Hex.parse('0000000000000000');
    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), this.secret_key_encryption);
    return encrypted.toString();
  }

  getDecrypt(value: any) {
    var iv = CryptoJS.enc.Hex.parse('0000000000000000');
    var decrypted = CryptoJS.AES.decrypt(value, this.secret_key_encryption);
    return decrypted.toString(CryptoJS.enc.Utf8);
  }

}
