import { inject, Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResponseMessage } from '../../../model/interfaces/req.res.interface';

@Injectable({ providedIn: 'root' })
export class DashboardService {
  private httpClient = inject(HttpClient);
  apiUrl = environment.apiUrl;

  // Add home page details
  getHomeDetails(
    selectedMonth: any,
    selectedPrevMonth: any
  ): Observable<ResponseMessage> {
    let queryParam = '';
    if (selectedMonth || selectedPrevMonth) {
      if (selectedMonth) {
        let [year, month] = selectedMonth.split('-');
        selectedMonth = Number(month);
      }
      if (selectedPrevMonth) {
        let [year, month] = selectedPrevMonth.split('-');
        selectedPrevMonth = Number(month);
      }
      queryParam = `?month=${selectedMonth}&previous_month=${selectedPrevMonth}`;
    }
    return this.httpClient.get<ResponseMessage>(`home${queryParam}`);
  }
}
