import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-delete-menu',
  standalone: true,
  imports: [
    RouterModule,
    MatDialogModule,
    MatButtonModule
  ],
  templateUrl: './delete-menu.component.html',
  styleUrl: './delete-menu.component.scss'
})
export class DeleteMenuComponent {

}
