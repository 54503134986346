import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from '../../../shared/components/common/header/header.component';
import { SidebarComponent } from '../../../shared/components/common/sidebar/sidebar.component';


@Component({
  selector: 'app-edit',
  standalone: true,
  imports: [
    MatRadioGroup,
    MatRadioButton,
    HeaderComponent,
    SidebarComponent,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    RouterModule,
  ],
  templateUrl: './edit.component.html',
  styleUrl: './edit.component.scss'
})
export class EditComponent {

}
