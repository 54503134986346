<!-- Invoice Header Boxes -->
<div class="section-box mb-24 flex flex-justify-between flex-align-center gap-16px flex-wrap">
  <div class="flex flex-wrap order-infowrapper flex-grow-1 flex-align-start">
    <div class="order-infobox radius-12">
      <span class="order-infoicon">
        <img src="assets/images/icons/icon-money.svg" alt="Paid" />
      </span>
      <div class="fs-14 text-grey ">
        <h3 class="order-count text-white fw-bold">${{last30DayAmount}}</h3>
        <span>{{'paid_last_30_days' | translate}}</span>
      </div>
    </div>
    <div class="order-infobox radius-12">
      <span class="order-infoicon">
        <img src="assets/images/icons/document.svg" alt="Draft" />
      </span>
      <div class="fs-14 text-grey ">
        <h3 class="order-count text-white fw-bold">${{draftAmount}}</h3>
        <span>{{'draft' | translate}}</span>
      </div>
    </div>
  </div>
  <button mat-flat-button class="btn-lg create-invoicebtn" routerLink="/invoice/create">{{'create_new_invoice' | translate}}</button>
</div>

<!-- Starts Filter Section -->
<div class="filter-boxwrapper section-box flex flex-justify-end flex-wrap mb-20">
  <mat-form-field subscriptSizing="dynamic" class="filter-date date-field">
    <div class="w-full relative input-rightspace">
      <input matInput [matDatepicker]="picker" placeholder="From" [(ngModel)]="fromDate"
        (dateChange)="dateChange('from')">
      <mat-datepicker-toggle matIconSuffix [for]="picker" class="ic-input cursor-pointer"></mat-datepicker-toggle>
    </div>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
  <mat-form-field subscriptSizing="dynamic" class="filter-date date-field">
    <div class="w-full relative input-rightspace">
      <input matInput [matDatepicker]="picker2" placeholder="To" [(ngModel)]="toDate" (dateChange)="dateChange('to')"
        [disabled]="!fromDate" [min]="fromDate" [max]="toDay">
      <mat-datepicker-toggle matIconSuffix [for]="picker2" class="ic-input cursor-pointer"></mat-datepicker-toggle>
    </div>
    <mat-datepicker #picker2></mat-datepicker>
  </mat-form-field>
  <mat-form-field subscriptSizing="dynamic" class="filter-day">
    <mat-select placeholder="{{'product' | translate}}" [(ngModel)]="filterProductId" (selectionChange)="filterBy('product')">
      <mat-option value="">{{'product' | translate}} </mat-option>
      @for(product of productList;track product.name){
      <mat-option [value]="product.id">{{product.name}}</mat-option>
      }
    </mat-select>
  </mat-form-field>
  <mat-form-field subscriptSizing="dynamic" class="filter-paymenttype">
    <mat-select placeholder="Vendor" [(ngModel)]="filterVendorId" (selectionChange)="filterBy('vendor')">
      <mat-option value="">{{'vendor' | translate}}</mat-option>
      @for(vendor of vendorList;track vendor.user_id){
      <mat-option [value]="vendor.user_id">{{vendor.name}}</mat-option>
      }
    </mat-select>
  </mat-form-field>
  <mat-form-field subscriptSizing="dynamic" class="filter-paymenttype">
    <mat-select placeholder="{{'branch_location' | translate}}" [(ngModel)]="filterBranchLocationId"
      (selectionChange)="filterBy('branch')">
      <mat-option value="">{{'branch_location' | translate}}</mat-option>
      @for(branch of branchLocationList();track branch){
      <mat-option [value]="branch.business_location_id">{{branch.name}}</mat-option>
      }
    </mat-select>
  </mat-form-field>
  <mat-form-field subscriptSizing="dynamic" class="filter-paymenttype">
    <mat-select placeholder="Sort By" [(ngModel)]="sortBy" (selectionChange)="filterBy('sortby')">
      <mat-option value="">{{'sort_by' | translate}}</mat-option>
      <mat-option value="1">ASC</mat-option>
      <mat-option value="2">DESC</mat-option>
    </mat-select>
  </mat-form-field>
  <button mat-flat-button class="btn-outline-primary" (click)="resetFilters()">{{'reset' | translate}}</button>
</div>
<!-- Ends Filter Section -->

<div class="table-sectionbox">
  <!-- Header , Search and Filter -->
  <div class="flex flex-justify-between flex-wrap gap-8px flex-align-center section-box table-sectionbx">
    <h1 class="mb-0 mobile-full h3">{{'invoices' | translate}}</h1>
    <div class="flex gap-28px flex-grow-1 flex-justify-end flex-align-center filterbox-wrap">
      <mat-form-field appearance="outline" subscriptSizing="dynamic" class="small-input search-inputbx">
        <div class="w-full relative input-rightspace">
          <input type="search" class="form-control" matInput placeholder="Search Invoice" [(ngModel)]="searchText"
            (input)="searchByText()" />
          <mat-icon class="ic-input cursor-pointer">search</mat-icon>
        </div>
      </mat-form-field>
    </div>
  </div>

  <!-- Starts Table -->
  <div class="table-responsive custom-scroll table-wrapper" infinite-scroll (scrolled)="InvoiceListScrollDown()"
    [immediateCheck]="true" [scrollWindow]="false" [infiniteScrollDistance]="20" [infiniteScrollThrottle]="0">
    <table mat-table [dataSource]="dataSourceInvoice" class="mat-elevation-z8">

      <!-- DATE Column -->
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef class="item-namecol">DATE</th>
        <td mat-cell *matCellDef="let element" class="nowrap">
          <div [innerHTML]="element.created_at | date:'dd/MM/YYYY'" class="item-infobox"></div>
        </td>
      </ng-container>

      <!-- CUSTOMER NAME Column -->
      <ng-container matColumnDef="customerName">
        <th mat-header-cell *matHeaderCellDef>CUSTOMER NAME</th>
        <td mat-cell *matCellDef="let element"> {{element.customer_name}} </td>
      </ng-container>

      <!-- ID Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>#ID</th>
        <td mat-cell *matCellDef="let element"> {{element.invoice_id}} </td>
      </ng-container>

      <!-- TITLE Column -->
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef>TITLE</th>
        <td mat-cell *matCellDef="let element" class="nowrap">
          <div class="text-truncate title-text">{{element.invoice_title}}</div>
        </td>
      </ng-container>

      <!-- STATUS Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>STATUS</th>
        <td mat-cell *matCellDef="let element">
          <span class="badge badge-light-success badge-small"
            [innerHTML]="element.invoice_status==1?'Send':'Draft'"></span>
        </td>
      </ng-container>

      <!-- AMOUNT Column -->
      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef>AMOUNT</th>
        <td mat-cell *matCellDef="let element">${{element.total_amount}} </td>
      </ng-container>

      <!-- CUSTOMIZE INVOICE Column -->
      <!-- <ng-container matColumnDef="customerInvoice">
        <th mat-header-cell *matHeaderCellDef>CUSTOMIZE INVOICE</th>
        <td mat-cell *matCellDef="let element">-</td>
      </ng-container> -->

      <!-- ACTION Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="action-col">ACTION</th>
        <td mat-cell *matCellDef="let element">
          <div class="flex table-actionbtn flex-justify-center">
            <div class="flex table-actionbtn">
              <button mat-icon-button aria-label="Download PDF" matTooltip="Download PDF">
                <img src="assets/images/icons/download_pdf.svg" alt="Download Pdf" />
              </button>
              @if(element.invoice_status!=1){
              <button mat-icon-button aria-label="Edit" matTooltip="{{ 'edit' | translate }}"
                (click)="ediInvoice(element.id)">
                <span class="icon-development"> </span>
              </button>
              }
            </div>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    @if(dataSourceInvoice?.data?.length==0){
    <div class="no-records">
      {{'no_records' | translate}}
    </div>
    }
  </div>
</div>