<!-- Starts Header -->
<div
  class="section-box mb-24 flex flex-wrap flex-justify-between flex-align-center header-section gap-8px backbtn-wrap">
  <h1 class="text-white mb-0 text-truncate">{{"view_item" | translate}}</h1>
  <a mat-flat-button class="btn-outline-secondary backbtn" (click)="backToProductList()">
    <svg width="7" height="15" viewBox="0 0 7 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.2876 13.4386L1.3976 8.54859C0.820097 7.97109 0.820097 7.02609 1.3976 6.44859L6.2876 1.55859"
        stroke="currentColor" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>
    {{"back" | translate}}
  </a>
</div>

<div class="section-box product-infowrapper card-boxblock">
  <div class="fs-14 text-white mb-20">
    <span class="text-grey-medium mb-6 d-block">{{"item_name" | translate}}</span>
    <div>{{productDetail?.name}}</div>
  </div>
  <div class="fs-14 text-white mb-20">
    <span class="text-grey-medium mb-6 d-block">{{"item_description" | translate}}</span>
    <div>{{productDetail?.description}}</div>
  </div>
  <div class="fs-14 text-white mb-20">
    <span class="text-grey-medium mb-6 d-block">{{'image' |translate}}</span>
      <div class="flex gap-8px flex-wrap">
        @for (imageData of productDetail?.item_image; track $index) {
        <span class="overflow-hidden food-smallimg d-block">
          <img [src]="imageData.url" [alt]="imageData?.image_name" class="img-fit" />
        </span>
        }
      </div>
  </div>
  <div class="info-rowbx">
    <div class="fs-14 text-white">
      <span class="text-grey-medium mb-6 d-block">{{"price" | translate}}</span>
      <div>${{productDetail?.price}}</div>
    </div>
    <div class="fs-14 text-white">
      <span class="text-grey-medium mb-6 d-block">{{"item_unit" | translate}}</span>
      <div>{{productDetail?.unit}}</div>
    </div>
    <div class="fs-14 text-white">
      <span class="text-grey-medium mb-6 d-block">{{"available_stock" | translate}}</span>
      <div>{{productDetail?.available_stock}}</div>
    </div>
    <div class="fs-14 text-white">
      <span class="text-grey-medium mb-6 d-block">{{"menu_list" | translate}}</span>
      <div>{{productDetail?.food_menu_title}}</div>
    </div>
    <div class="fs-14 text-white">
      <span class="text-grey-medium mb-6 d-block">{{"category" | translate}}</span>
      <div>{{productDetail?.category_name}}</div>
    </div>
    <div class="fs-14 text-white">
      <span class="text-grey-medium mb-6 d-block">{{"available_to_sell" | translate}}</span>
      <div>{{productDetail?.available_to_sell == 1?'Yes':'No'}}</div>
    </div>
  </div>
</div>