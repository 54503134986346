import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from '../../../../shared/components/common/header/header.component';
import { SidebarComponent } from '../../../../shared/components/common/sidebar/sidebar.component';
import { DeleteReservationComponent } from '../../../../shared/components/dialog/delete-reservation/delete-reservation.component';

export interface PeriodicElement {
  customerName: string;
  mobileNo: string;
  guestNo: string;
  reservedTime: string;
  reseredTable: string,
  event: string
}

const ELEMENT_DATA: PeriodicElement[] = [
  {
    customerName:
      'Carla Kenter',
    mobileNo: '(406) 555-0120',
    guestNo: '2',
    reservedTime: '28/05/2024 03:30PM',
    reseredTable: 'T-01',
    event: 'Birthday'
  },
  {
    customerName:
      'Carla Kenter',
    mobileNo: '(406) 555-0120',
    guestNo: '2',
    reservedTime: '28/05/2024 03:30PM',
    reseredTable: 'T-01',
    event: 'Birthday'
  },
  {
    customerName:
      'Carla Kenter',
    mobileNo: '(406) 555-0120',
    guestNo: '2',
    reservedTime: '28/05/2024 03:30PM',
    reseredTable: 'T-01',
    event: 'Birthday'
  },
  {
    customerName:
      'Carla Kenter',
    mobileNo: '(406) 555-0120',
    guestNo: '2',
    reservedTime: '28/05/2024 03:30PM',
    reseredTable: 'T-01',
    event: 'Birthday'
  },
  {
    customerName:
      'Carla Kenter',
    mobileNo: '(406) 555-0120',
    guestNo: '2',
    reservedTime: '28/05/2024 03:30PM',
    reseredTable: 'T-01',
    event: 'Birthday'
  },
  {
    customerName:
      'Carla Kenter',
    mobileNo: '(406) 555-0120',
    guestNo: '2',
    reservedTime: '28/05/2024 03:30PM',
    reseredTable: 'T-01',
    event: 'Birthday'
  },
  {
    customerName:
      'Carla Kenter',
    mobileNo: '(406) 555-0120',
    guestNo: '2',
    reservedTime: '28/05/2024 03:30PM',
    reseredTable: 'T-01',
    event: 'Birthday'
  },
  {
    customerName:
      'Carla Kenter',
    mobileNo: '(406) 555-0120',
    guestNo: '2',
    reservedTime: '28/05/2024 03:30PM',
    reseredTable: 'T-01',
    event: 'Birthday'
  },
  {
    customerName:
      'Carla Kenter',
    mobileNo: '(406) 555-0120',
    guestNo: '2',
    reservedTime: '28/05/2024 03:30PM',
    reseredTable: 'T-01',
    event: 'Birthday'
  },
  {
    customerName:
      'Carla Kenter',
    mobileNo: '(406) 555-0120',
    guestNo: '2',
    reservedTime: '28/05/2024 03:30PM',
    reseredTable: 'T-01',
    event: 'Birthday'
  },
  {
    customerName:
      'Carla Kenter',
    mobileNo: '(406) 555-0120',
    guestNo: '2',
    reservedTime: '28/05/2024 03:30PM',
    reseredTable: 'T-01',
    event: 'Birthday'
  },
];
@Component({
  selector: 'app-reservation-list',
  templateUrl: './reservation-list.component.html',
  styleUrl: './reservation-list.component.scss',
  standalone: true,
  imports: [
    HeaderComponent,
    SidebarComponent,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTableModule,
    MatTooltipModule,
    MatIconModule,
    RouterModule
  ],
})
export class ReservationListComponent {
  displayedColumns: string[] = ['customerName','mobileNo',  'guestNo', 'reservedTime', 'reseredTable', 'event', 'action'];
  dataSource = ELEMENT_DATA;

  readonly dialog = inject(MatDialog);

  openDialog() {
    const dialogRef = this.dialog.open(DeleteReservationComponent, {
      width: '564px',
      panelClass: ['modal-wrapper'],
    });
  }
}
