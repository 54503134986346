// import { Pipe, PipeTransform } from '@angular/core';
// import { DatePipe } from '@angular/common';
// import { environment } from '../../../environments/environment';
// export const CUSTOM_DATE_PIPE_PROVIDERS = [
//   { provide: DatePipe, useFactory: () => new DatePipe('es') }
// ];
// @Pipe({
//   name: 'dateTime24Hr',
//   standalone: true,
  
// })
// export class DateTime24HrPipe implements PipeTransform {

//   //#region Localstorage 
//   language = 'selected_language';
//   constructor(private datePipe: DatePipe) {

//   }

//   transform(value: Date | string | number): string | null {
//     if (!value) return null;

//     // Set the desired locale
//     const storedLang = localStorage.getItem(this.language)
//     const locale = storedLang || environment.defaultLang;
//     // Use a default format without time
//     // const format = 'dd-MMM-yyyy HH:mm';
//     const format = 'dd/MM/yyyy HH:mm';
//     return this.datePipe.transform(value, format, undefined, locale);
//   }
// }

import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { environment } from '../../../environments/environment';

export const CUSTOM_DATE_PIPE_PROVIDERS = [
  { provide: DatePipe, useFactory: () => new DatePipe('es') }
];

@Pipe({
  name: 'dateTime24Hr',
  standalone: true,
})
export class DateTime24HrPipe implements PipeTransform {

  //#region Localstorage 
  language = 'selected_language';

  constructor(private datePipe: DatePipe) {}

  transform(value: Date | string | number, showTime: boolean = false): string | null {
    if (!value) return null;

    // Set the desired locale
    const storedLang = localStorage.getItem(this.language);
    const locale = storedLang || environment.defaultLang;

    // Choose format based on the `showTime` argument
    const format = showTime ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy';
    
    return this.datePipe.transform(value, format, undefined, locale);
  }
}

