import { CommonModule } from '@angular/common';
import { Component, ElementRef, inject, signal, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { catchError, Subscription, throwError } from 'rxjs';
import { bankAccountType } from '../../../model/const/common.const';
import { ResponseMessage } from '../../../model/interfaces/req.res.interface';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

@Component({
  selector: 'app-primary-setup',
  standalone: true,
  imports: [CommonModule, MatButtonModule, TranslateModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule, MatSelectModule,MatButtonModule],
  templateUrl: './primary-setup.component.html',
  styleUrl: './primary-setup.component.scss'
})
export class PrimarySetupComponent {
  private authService = inject(AuthService);
  private fb = inject(FormBuilder);
  private toastr = inject(ToastrService);
  public route = inject(Router);
  private subscribe!: Subscription;
  public profileCompleteStatus$ = this.authService.getProfileCompleteStatus();
  public submitBankAccountDisabled = signal<boolean>(false);
  public checkRouteExist:any;
  public bankAccountForm = this.fb.group({
    idProof: [''],
    bankName: ['', Validators.required],
    routingNumber: ['', Validators.required],
    accountNumber: ['', Validators.required],
    accountType: ['', Validators.required],
    accountHolderName: ['', Validators.required],
  });
  public bankAccountType = bankAccountType;
  fileTypeError = '';
  imageFileName: any;
  @ViewChild('fileInput') fileInput!: ElementRef;
  imageFile: any;
  profileImageUrl: any;
  step: any = 1;
  ngOnInit(){
    if(this.route.url=='/primary-setup'){
      this.checkRouteExist = true;
    }else{
      this.checkRouteExist = false;
    }
  }
  uploadImage(event: any) {
    const file = event.target.files[0];
    const fileSizeMB = file.size / (1024 * 1024);
    const imageExtensions = ['image/png', 'image/jpeg', 'image/jpg'];

    if (fileSizeMB > 5) {
      this.fileTypeError = 'image_file_5mb_validation';
      return;
    }

    if (!imageExtensions.includes(file.type.toLowerCase())) {
      this.fileTypeError = 'image_file_format_validation';
      return;
    }

    const reader = new FileReader();
    this.imageFile = file;
    this.fileTypeError = '';
    this.imageFileName = file.name;
    reader.readAsDataURL(this.imageFile);
    reader.onload = () => {
      this.profileImageUrl = reader.result;
    };
  }

  bankAccountFormSubmit(value: any) {
    this.bankAccountForm.markAllAsTouched();
    // if (!this.imageFile) {
    //   this.fileTypeError = ' ';
    //   return;
    // }
    if (this.bankAccountForm.invalid) return;

    let formData = new FormData();
    formData.append('bank_name', value.bankName);
    formData.append('routing_number', value.routingNumber);
    formData.append('account_number', value.accountNumber);
    formData.append('account_type', value.accountType);
    formData.append('account_holder_name', value.accountHolderName);
    if (this.imageFile && this.imageFile != undefined)
      formData.append('image', this.imageFile);

    this.submitBankAccountDisabled.set(true);
    if (this.subscribe) this.subscribe.unsubscribe();
    this.subscribe = this.authService
      .addEditBankAccount(formData)
      .pipe(
        catchError((error) => {
          this.submitBankAccountDisabled.set(false);
          this.toastr.error(error.error.message);
          return throwError(() => error.error.message);
        })
      )
      .subscribe((res: ResponseMessage) => {
        this.submitBankAccountDisabled.set(false);
        if (res.code === 200) {
          this.toastr.success(res.message);
          this.redirectToPrimarySetup();
        } else {
          this.toastr.error(res.message);
        }
      });
  }
  imageCheck() {
    this.fileInput.nativeElement.value = '';
    return null;
  }
  redirectToPrimarySetup() {
    this.bankAccountForm.reset();
    this.bankAccountForm.patchValue({idProof:''})
    this.fileInput.nativeElement.value = '';
    this.imageFileName='';
    this.step = 1;
  }

  redirectToSetting() {
    this.route.navigate(['/settings']);
  }
  redirectToMenu() {
    this.route.navigate(['/menu/create']);
  }
  redirectToDashboard(){
    this.route.navigate(['/dashboard'])
  }
  redirectToSecondStep() {
    this.step = 2;
  }

}
