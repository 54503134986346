<mat-dialog-content class="text-center">
  <div class="mb-20 flex flex-justify-center">
    <img src="assets/images/verification.png" alt="OTP Verification" class="dialog-smallimg" />
  </div>
  <div class="block text-grey mb-20">
    <h3 class="h1 mb-4 text-white">
      {{ modalData.title | translate }}
    </h3>
    <p innerHTML="{{ modalData.sub_title | translate : {phone: modalData.phone_number} }}"></p>
  </div>
  <form [formGroup]="otpForm" (ngSubmit)="verify()">
    <div class="mb-16">
      <ng-otp-input (onInputChange)="onOtpChange($event)"
        [config]="{ length: 6 ,disableAutoFocus:true,allowNumbersOnly:true}"></ng-otp-input>
    </div>
    <div class="btn-box mb-24">
      <button type="button" mat-stroked-button (click)="close()" class="w-full">
        {{ "cancel" | translate }}
      </button>
      <button type="submit" mat-flat-button class="w-full" [disabled]="sendSubmitDisabled()">
        {{ "verify" | translate }}
      </button>
    </div>
    @if(modalData.resend){
    <div class="flex flex-justify-between otp-timerwrap">
      <span class="fs-13 remaining-time">{{ formattedRemainingTime }}</span>
      <span class="text-grey-light fs-14">
        {{ modalData.dont_receive | translate }}
        <a mat-button class="text-link" (click)="resend()" [disabled]="sendResendDisabled()">
          {{ "resend" | translate }}
        </a>
      </span>
    </div>
    }
  </form>
</mat-dialog-content>