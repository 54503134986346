import { HttpInterceptorFn, HttpErrorResponse, } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { IPurl } from '../model/const/common.const';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { LangType, ThemeType } from '../model/types/GlobalTypes';
import { EncryptionService } from '../shared/services/encryption.service';


// @Injectable({
//   providedIn: 'root',
// })
export const TokenInterceptor: HttpInterceptorFn = (req, next) => {
  const router = inject(Router);
  const encDecObj = inject(EncryptionService);
  const language = 'selected_language';
  const theme = 'selected_theme';
  const authToken = localStorage.getItem('token')?localStorage.getItem('token'):localStorage.getItem('userDetail') ? JSON.parse(encDecObj.getDecrypt(localStorage.getItem('userDetail')))?.token : '';
  const lang: any = localStorage.getItem('selected_language');
  const offset = new Date().getTimezoneOffset().toString();
 
  let authReq: any;
  if (req.url.startsWith('http') || req.url.includes('assets') || req.url.includes(IPurl)) {
    authReq = req.clone();
  } else {
    let newHeaders = req.headers
        .append('Authorization', `Bearer ${authToken}`)
        .append('Accept-Language', lang)
        .append('Timezone-Offset', offset);
      authReq = req.clone({
        headers: newHeaders,
        url: `${environment.apiUrl}${req.url}`,
      });
    
  }
  return next(authReq).pipe(
    catchError((error: HttpErrorResponse) => {
      if (error.status == 422) {
        const storedTheme = localStorage.getItem(theme) as ThemeType;
        const storedLanguage = localStorage.getItem(language) as LangType;
        localStorage.clear();
        localStorage.setItem(theme, storedTheme);
        localStorage.setItem(language, storedLanguage);
        router.navigate(['/login']);
      }
      // Return the error to the calling service
      return throwError(() => error);
    })
  );
};


