<div class="authentication-page flex flex-align-center flex-justify-center">
  <!-- Starts Signup Step 1 -->
  <div class="authentication-box" [hidden]="currentStep() != 1">
    <form [formGroup]="signupForm">
      <div class="auth-headerwrapper flex">
        <div class="authlogo-box">
          <div class="auth-box flex flex-align-center flex-justify-center">
            <img src="./assets/images/logo-small.svg" alt="MuyBi" />
          </div>
        </div>
        <div class="auth-header">
          <h1>{{ "sign_step_one.title" | translate }}</h1>
          <p class="text-grey">{{ "sign_step_one.sub_title" | translate }}</p>
        </div>
      </div>
      <div class="auth-innerbox">
        <div class="mb-20">
          <label id="example-radio-group-label" class="text-grey-medium block mb-10">
            {{ "sign_step_one.sign_up_via" | translate }}
          </label>
          <mat-radio-group>
            <mat-radio-button [value]="true" [checked]="isEmail()" (change)="onInputChange(true)">
              {{ "email" | translate }}
            </mat-radio-button>
            <mat-radio-button [value]="false" [checked]="!isEmail()" (change)="onInputChange(false)">
              {{ "phone" | translate }}
            </mat-radio-button>
          </mat-radio-group>
        </div>

        <mat-form-field appearance="outline" subscriptSizing="dynamic">
          @if(isEmail()){
          <mat-label>{{
            "sign_step_one.enter_your_email" | translate
            }}</mat-label>
          } @else {
          <mat-label>
            {{ "sign_step_one.enter_your_phone" | translate }}*
          </mat-label>
          }
          <div class="flex w-full inputwith-btn flex-align-end mb-16">
            @if(isEmail()){
            <input matInput formControlName="userName" (input)="resetUserEmailPhone()" />
            } @else {
            <ngx-mat-intl-tel-input formControlName="phone" class="w-full" [preferredCountries]="preferredCountries()"
              [enablePlaceholder]="true" [enableSearch]="true" name="userNamePhone" #userNamePhone
              (input)="resetUserEmailPhone()">
            </ngx-mat-intl-tel-input>
            }
            <a mat-stroked-button (click)="sendOTP()" [disabled]="sendOTPDisabled()" class="flex-shrink-0 sendotp-btn">
              {{ "send_otp" | translate }}
            </a>
          </div>
        </mat-form-field>

        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
          <mat-label>
            {{ "sign_step_one.enter_vendor_name" | translate }}
          </mat-label>
          <input matInput matNativeControl formControlName="vendorName" />
        </mat-form-field>
        <div class="grid-col2">
          <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
            <mat-label>{{
              "sign_step_one.create_password" | translate
              }}</mat-label>
            <div class="password-field w-full" [class.active]="passwordFieldType() === 'text'">
              <input matInput matNativeControl [type]="passwordFieldType()" formControlName="password" />
              <span class="eye-icon" (click)="togglePasswordVisibility('password')"></span>
            </div>
          </mat-form-field>

          <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
            <mat-label>{{
              "sign_step_one.confirm_password" | translate
              }}</mat-label>
            <div class="password-field w-full" [class.active]="confirmPasswordFieldType() === 'text'">
              <input matInput matNativeControl [type]="confirmPasswordFieldType()" formControlName="confirmPassword" />
              <span class="eye-icon" (click)="togglePasswordVisibility('confirmPassword')"></span>
            </div>
          </mat-form-field>
        </div>

        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-24">
          <mat-label>{{ "sign_step_one.location" | translate }}</mat-label>
          <div class="w-full relative input-rightspace">
            <input matInput matNativeControl formControlName="location" #placesRef="ngx-places" ngx-gp-autocomplete
              #location (onAddressChange)="handleAddressChange($event)" />
            <span class="icon-ic-target ic-input cursor-pointer"></span>
          </div>
        </mat-form-field>
        <mat-checkbox class="mb-24" formControlName="acceptTerm" required>
          {{ "sign_step_one.terms_1" | translate }}
          <a mat-button class="text-link fw-medium" routerLink="/terms-and-conditions" target="_blank">
            {{ "sign_step_one.terms_2" | translate }}
          </a>
          {{ "sign_step_one.terms_3" | translate }}
        </mat-checkbox>
        <button mat-flat-button class="w-full mb-24" type="submit" [disabled]="submitDisabled()"
          (click)="formSubmit(signupForm.value)">
          {{ "continue" | translate }}
        </button>
        <div class="text-center text-grey">
          {{ "already_have_account" | translate }}
          <a mat-button routerLink="/login" class="text-link">{{
            "sign_in" | translate
            }}</a>
        </div>
      </div>
    </form>
  </div>

  <!-- Ends Signup Step 1 -->

  <!-- Starts Signup Step 2 -->
  @if(currentStep()!=1 && currentStep()!=2){
  <button mat-flat-button class="btn-dark skip-btn"  (click)="redirectToDashboard()">
    {{ "skip" | translate }}
  </button>
  }

  <ng-container *ngIf="currentStep() === 2">
    <app-signup-business-details [preferredCountries]="preferredCountries()"
      [businessDetailFormData]="businessDetailFormData()"
      (submittedBusinessDetails)="submittedBusinessDetails($event)"></app-signup-business-details>
  </ng-container>
  <!-- Ends Signup Step 2 -->

  <ng-container *ngIf="currentStep() === 3">
    <app-account-protection [preferredCountries]="preferredCountries()" [phone]="businessDetailFormData()?.phone"
      (backButtonPressed)="setCurrentStep(2)"
      (submittedAccountProtection)="setCurrentStep(4)"></app-account-protection>
  </ng-container>

  <ng-container *ngIf="currentStep() === 4">
    <app-primary-setup> </app-primary-setup>
  </ng-container>

  <!-- <ng-container *ngIf="currentStep() === 5">
    <div class="authentication-box">
      <form [formGroup]="bankAccountForm">
        <div class="auth-headerwrapper flex">
          <div class="authlogo-box">
            <div class="auth-box flex flex-align-center flex-justify-center">
              <img src="./assets/images/logo-small.svg" alt="MuyBi" />
            </div>
          </div>
          <div class="auth-header">
            <h1>{{ "sign_step_five.title" | translate }}</h1>
            <p class="text-grey">
              {{ "sign_step_five.sub_title" | translate }}
            </p>
          </div>
        </div>
        <div class="auth-innerbox">
          <div class="mb-20" [ngClass]="{ 'mat-form-field-invalid': fileTypeError }">
            <label for="upload-image" class="form-label">
              {{ "sign_step_five.upload_your_id_proof" | translate }}
            </label>
            <label for="upload-image" class="form-control w-full flex flex-align-center cursor-pointer relative">
              <span>{{ imageFileName }}</span>
              <input type="file" class="form-control" id="upload-image" (change)="uploadImage($event)"
                accept=".png, .jpg, .jpeg" formControlName="idProof" #fileInput hidden (click)="imageCheck()" />
              @if (!imageFileName) {
              <span class="text-white file-text">
                {{ "browse" | translate }}
              </span>
              }
              <span class="icon-image-plus ic-input"></span>
            </label>
            <span class="mat-mdc-form-field-error">
              {{ fileTypeError | translate }}
            </span>
          </div>
          <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
            <mat-label>
              {{ "sign_step_five.bank_name" | translate }}
            </mat-label>
            <input matInput matNativeControl formControlName="bankName" />
          </mat-form-field>
          <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
            <mat-label>
              {{ "sign_step_five.routing_number" | translate }}
            </mat-label>
            <input matInput matNativeControl formControlName="routingNumber" allowNumberOnly />
          </mat-form-field>
          <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
            <mat-label>
              {{ "sign_step_five.account_number" | translate }}
            </mat-label>
            <input matInput matNativeControl formControlName="accountNumber" allowNumberOnly />
          </mat-form-field>
          <div class="grid-col2">
            <mat-form-field subscriptSizing="dynamic" appearance="outline"
              class="filter-location filterbox select-bgwrap small-select">
              <mat-label>
                {{ "sign_step_five.account_type" | translate }}
              </mat-label>
              <mat-select formControlName="accountType">
                @for (type of bankAccountType; track type) {
                <mat-option value="{{ type.value }}">
                  {{ type.translateLabel | translate }}
                </mat-option>
                }
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
              <mat-label>
                {{ "sign_step_five.account_holder_name" | translate }}
              </mat-label>
              <input matInput matNativeControl formControlName="accountHolderName" />
            </mat-form-field>
          </div>
          <div class="flex flex-justify-between gap-8px">
            <button mat-flat-button class="w-full  mb-24" (click)="setCurrentStep(4)">
              {{ "back" | translate }}
            </button>
            <button mat-flat-button class="w-full mb-24" type="submit" [disabled]="submitBankAccountDisabled()"
              (click)="bankAccountFormSubmit(bankAccountForm.value)">
              {{ "submit" | translate }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </ng-container> -->
</div>