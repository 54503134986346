import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogActions, MatDialogClose, MatDialogContent, MatDialogModule, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ConstantService } from '../../../shared/services/constant.service';
import { AuthService } from '../auth.service';
import { catchError, Subscription, throwError } from 'rxjs';
import { ResponseMessage } from '../../../model/interfaces/req.res.interface';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@Component({
  selector: 'app-logout',
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatButtonModule,
    MatDialogModule,
    TranslateModule,
    MatProgressBarModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './logout.component.html',
  styleUrl: './logout.component.scss'
})
export class LogoutComponent {

  private toastr = inject(ToastrService);
  private constantService = inject(ConstantService);
  private authService = inject(AuthService);
  private router = inject(Router);
  private dialogRef = inject(MatDialogRef<LogoutComponent>);
  private subscription!: Subscription;
  language = 'selected_language';
  theme = 'selected_theme';
  logout() {
    this.logoutAPI();
  }

  logoutAPI() {
    if (this.subscription) this.subscription.unsubscribe();
    this.constantService.progressLoader.set(true);
    this.subscription = this.authService.logOut().pipe(catchError((error) => {
      this.constantService.progressLoader.set(false);
      this.toastr.error(error.error.message);
      return throwError(() => error.error.message)
    })).subscribe((res: ResponseMessage) => {
      this.constantService.progressLoader.set(false);
      if (res.code === 200) {
        this.checkThemeLanguage(res.message)
      } else {
        this.toastr.error(res.message);
      }
    })
  }

  checkThemeLanguage(message: string) {
    this.dialogRef.close();
    this.constantService.logOutUser();
    this.toastr.success(message);
  }

  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
  }


}
