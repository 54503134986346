import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-view-order-history',
  standalone: true,
  imports: [
    RouterModule,
    MatDialogModule,
    MatButtonModule
  ],
  templateUrl: './view-order-history.component.html',
  styleUrl: './view-order-history.component.scss'
})
export class ViewOrderHistoryComponent {

}
