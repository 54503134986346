import { CommonModule } from '@angular/common';
import { Component, ElementRef, inject, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogContent, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { TakePicturePopupComponent } from '../../take-picture-popup/take-picture-popup.component';
import { ConstantService } from '../../../../shared/services/constant.service';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-upload-image-popup',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogContent,
    MatButtonModule,
    TranslateModule
  ],
  templateUrl: './upload-image-popup.component.html',
  styleUrl: './upload-image-popup.component.scss'
})
export class UploadImagePopupComponent {
  imageFile: any;
  fileTypeError: any;
  platform: any;
  dialogModule = inject(MatDialogRef<UploadImagePopupComponent>);
  dialog = inject(MatDialog);
  toastr = inject(ToastrService);
  private constantService = inject(ConstantService);
  @ViewChild('fileInput') fileInput!: ElementRef;

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.platform = this.constantService.getFlutterAppPlatform();
  }

  takePicture() {
    let dialogRef = this.dialog.open(TakePicturePopupComponent);
    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        this.dialogModule.close(res)
      }
    })
  }
  uploadImage(event: any) {
    const file = event.target.files[0];
    const fileSizeMB = file.size / (1024 * 1024);
    const imageExtensions = ['image/png', 'image/jpeg', 'image/jpg'];

    if (fileSizeMB > 5) {
      this.fileTypeError = 'Please ensure your image file is no larger than 5MB for upload.';
      this.toastr.error(this.fileTypeError);
      return;
    }

    if (!imageExtensions.includes(file.type.toLowerCase())) {
      this.fileTypeError = 'You can only upload files in .jpg, .jpeg, or .png formats.';
      this.toastr.error(this.fileTypeError);
      return;
    }

    const reader = new FileReader();
    this.imageFile = file;
    this.dialogModule.close(this.imageFile)
  }
  imageCheck() {
    this.fileInput.nativeElement.value = "";
    return null;
  }
}
