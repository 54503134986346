import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { AuthService } from '../auth.service';
import { FormBuilder, FormGroup, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { catchError, Subscription, throwError } from 'rxjs';
import { ResponseMessage } from '../../../model/interfaces/req.res.interface';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { passwordMatchValidator } from '../../../shared/validators/password-match-validator.directive';
import { passwordValidator } from '../../../shared/validators/password-validator.directive';
import { ConstantService } from '../../../shared/services/constant.service';


@Component({
  selector: 'app-change-password',
  standalone: true,
  imports: [
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    CommonModule,
    TranslateModule,
    MatDialogModule,
  ],
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  private authService = inject(AuthService);
  private fb = inject(FormBuilder);
  private toastr = inject(ToastrService);
  passwordFormGroup!: FormGroup;
  subscribeApi!: Subscription;
  hideOldPass: boolean = false;
  hidePass: boolean = false;
  hideCPass: boolean = false;
  constantService = inject(ConstantService);
  private dialogRef!: MatDialogRef<ChangePasswordComponent>;
  constructor() { }

  initialPasswordForm() {
    this.passwordFormGroup = this.fb.group({
      oldPassword: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6), passwordValidator()]],
      confirmPassword: ['', Validators.required],
    },
      { validators: passwordMatchValidator })
  }

  ngOnInit() {
    this.initialPasswordForm();
  }

  changePassword() {
    this.passwordFormGroup.markAllAsTouched();
    if (this.passwordFormGroup.invalid) return;
    let formData = new FormData();
    formData.append('old_password', this.passwordFormGroup.value.oldPassword);
    formData.append('password', this.passwordFormGroup.value.password);
    formData.append('password_confirmation', this.passwordFormGroup.value.confirmPassword);
    this.constantService.progressLoader.set(true);
    if (this.subscribeApi) this.subscribeApi.unsubscribe();
    this.subscribeApi = this.authService.changePassword(formData).pipe(
      catchError((error) => {
        this.constantService.progressLoader.set(false);
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message)
      })).subscribe((res: ResponseMessage) => {
        this.constantService.progressLoader.set(false);
        if (res.code === 200) {
          this.toastr.success(res.message);
        } else {
          this.toastr.error(res.message);
        }
      })
  }

  cancel() {
    this.dialogRef.close();
  }

}
