<div class="authentication-page flex flex-align-center flex-justify-center">
  <div class="authentication-box">
    <div class="auth-headerwrapper flex">
      <div class="authlogo-box">
        <div class="auth-box flex flex-align-center flex-justify-center">
          <img src="./assets/images/logo-small.svg" alt="MuyBi" />
        </div>
      </div>
      <div class="auth-header">
        <h1>{{ "login_title" | translate }}</h1>
        <p class="text-grey">{{ "login_sub_title" | translate }}</p>
      </div>
    </div>
    <div class="auth-innerbox">
      <form [formGroup]="signInForm" (ngSubmit)="login()">
        <mat-radio-group formControlName="type" class="mb-16" (change)="onInputChange()">
          <mat-radio-button value="1">{{
            "email" | translate
            }}</mat-radio-button>
          <mat-radio-button value="2">{{
            "phone" | translate
            }}</mat-radio-button>
        </mat-radio-group>
        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
          @if(signInForm.controls.type.value=='1'){
          <mat-label>{{ "login_form.enter_your_email" | translate }}</mat-label>
          }@else {
          <mat-label>
            {{ "login_form.enter_your_phone" | translate }}*
          </mat-label>
          }
          <div class="flex w-full inputwith-btn flex-align-end">
            @if(signInForm.controls.type.value=='2'){
            <ngx-mat-intl-tel-input formControlName="phone" class="w-full" [preferredCountries]="preferredCountries()"
              [enablePlaceholder]="true" [enableSearch]="true" name="userNamePhone" #userNamePhone>
              <!-- (input)="onPhoneChange($event)" -->
            </ngx-mat-intl-tel-input>
            } @if(signInForm.controls.type.value=='1'){
            <input matInput formControlName="userName" />
            }
          </div>
          <!-- @if(signInForm.controls.email_phone_number.errors &&
          signInForm.controls.email_phone_number.errors['required']){
          <mat-error>Please
            enter email or phone</mat-error>
          } -->
          <!-- @if(signInForm.controls.email_phone_number.errors &&
          signInForm.controls.email_phone_number.errors['emailOrNumber']){
          <mat-error>Please
            enter valid email or phone</mat-error>
          }
         -->
        </mat-form-field>
        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-6">
          <mat-label>{{ "password" | translate }}</mat-label>
          <div class="password-field w-full" [class.active]="passwordFieldType === 'text'">
            <input matInput matNativeControl [type]="passwordFieldType" formControlName="password" />
            <span class="eye-icon" (click)="togglePasswordVisibility('password')"></span>
          </div>
          <!-- @if(signInForm.controls.password.errors && signInForm.controls.password.errors['required']){
            <mat-error>Please enter password</mat-error>
          } -->
        </mat-form-field>
        <div class="text-end mb-24">
          <a mat-button routerLink="/forgot-password" class="fs-13">
            {{ "login_form.forgot_password" | translate }}
          </a>
        </div>
        <button mat-flat-button type="submit" class="w-full mb-24 btn-lg">
          {{ "sign_in" | translate }}
        </button>
        <div class="text-grey text-center">
          {{ "login_form.dont_have_account" | translate }}
          <a mat-button routerLink="/signup" class="text-link">{{
            "sign_up" | translate
            }}</a>
        </div>
      </form>
    </div>
  </div>
</div>