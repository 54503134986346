<mat-dialog-content class="text-center">
  <div class="mb-24 radius-12">
    <img src="assets/images/update-ic.png" alt="Update Waiting List" class="dialog-smallimg" />
  </div>
  <div class="block text-grey-light mb-24">
    <h3 class="h1 mb-24 text-white">Update Waiting List</h3>
    <p>Edit below details to modify your waiting.</p>
  </div>
  <div class="mb-24 updatelist-field">
    <mat-form-field appearance="outline" subscriptSizing="dynamic">
      <mat-label>Enter Customer Name</mat-label>
      <input matInput>
    </mat-form-field>
    <mat-form-field appearance="outline" subscriptSizing="dynamic">
      <mat-label>Queue Number</mat-label>
      <input matInput type="number">
    </mat-form-field>
    <mat-form-field appearance="outline" subscriptSizing="dynamic">
      <mat-label>No. of Guests</mat-label>
      <input matInput type="number">
    </mat-form-field>
    <mat-form-field appearance="outline" subscriptSizing="dynamic">
      <mat-label>Enter Mobile Number</mat-label>
      <input matInput>
    </mat-form-field>
  </div>
  <div class="btn-box text-center">
    <button mat-flat-button  mat-dialog-close class="btn-outline-primary">Cancel</button>
    <button mat-flat-button  mat-dialog-close>Update Now</button>
  </div>
</mat-dialog-content>