<div class="section-box mb-24 flex flex-wrap flex-justify-between flex-align-center header-section gap-8px backbtn-wrap">
  <h1 class="text-white mb-0 text-truncate">Add Lines Product</h1>
  
    <a mat-flat-button class="btn-outline-secondary backbtn mobile-full" routerLink="/purchase-order/create">
      <svg width="7" height="15" viewBox="0 0 7 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.2876 13.4386L1.3976 8.54859C0.820097 7.97109 0.820097 7.02609 1.3976 6.44859L6.2876 1.55859" stroke="currentColor" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      Back</a>
  
</div>

<form class="section-box block edit-formbox">
  <div class="">
    <div class="grid-2">
      <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-20">
        <mat-label class="fs-14">Enter Item Name</mat-label>
        <input matInput>
      </mat-form-field>
      <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-20">
        <mat-label class="fs-14">Quantity</mat-label>
        <input matInput type="number">
      </mat-form-field>
      <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-20">
        <mat-label class="fs-14">Price</mat-label>
        <input matInput>
      </mat-form-field>
      <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-20">
        <mat-label class="fs-14">Total</mat-label>
        <input matInput>
      </mat-form-field>
    </div>
    <div class="text-end addremove-btn mb-20">
        <span class="text-danger fs-14 cursor-pointer">x Remove</span>
    </div>
  </div>
  
  <div class="">
    <div class="grid-2">
      <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-20">
        <mat-label class="fs-14">Enter Item Name</mat-label>
        <input matInput>
      </mat-form-field>
      <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-20">
        <mat-label class="fs-14">Quantity</mat-label>
        <input matInput type="number">
      </mat-form-field>
      <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-20">
        <mat-label class="fs-14">Price</mat-label>
        <input matInput>
      </mat-form-field>
      <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-20">
        <mat-label class="fs-14">Total</mat-label>
        <input matInput>
      </mat-form-field>
    </div>
    <div class="text-end addremove-btn mb-20">
        <span class="text-success-light fs-14 cursor-pointer">+ Add More</span>
    </div>
  </div>
  
  <div class="">
    <div class="grid-2">
      <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-20">
        <mat-label class="fs-14">Add Discount</mat-label>
        <input matInput>
      </mat-form-field>
      <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-20">
        <mat-label class="fs-14">Add Subtotal</mat-label>
        <input matInput>
      </mat-form-field>
      <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-20">
        <mat-label class="fs-14">Add Shipping Fee</mat-label>
        <input matInput>
      </mat-form-field>
      <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-20">
        <mat-label class="fs-14">Add Service Charge</mat-label>
        <input matInput>
      </mat-form-field>
    </div>
    <div class="mb-20 fs-14">
      <label id="example-radio-group-label" class="text-grey-medium d-block mb-10 fs-14 form-label">Payment Option</label>
      <mat-radio-group aria-label="Select an option">
        <mat-radio-button value="1">Credit Card</mat-radio-button>
        <mat-radio-button value="2">Debit Card</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

  <div class="text-end btn-boxfooter flex-justify-end flex flex-align-center flex-wrap">
    <button mat-flat-button class="btn-lg mobile-full btn-outline-primary" routerLink="/purchase-order/add-lines-product">Share Purchase Order</button>
    <button mat-flat-button class="btn-lg mobile-full btn-outline-primary">Preview Purchase Order</button>
    <button mat-flat-button class="btn-lg mobile-full btn-outline-primary">Save as Draft</button>
    <button mat-flat-button class="btn-lg mobile-full" routerLink="/purchase-order">Send Purchase Order</button>
  </div>
</form>