import { V } from '@angular/cdk/keycodes';
import { CommonModule, DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, computed, effect, inject, Signal, signal } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE, provideNativeDateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { EncryptionService } from '../../../../shared/services/encryption.service';
import { InvoiceService } from '../service/invoice.service';
import { catchError, throwError } from 'rxjs';
import { ConstantService } from '../../../../shared/services/constant.service';
import { ResponseMessage } from '../../../../model/interfaces/req.res.interface';
import { ToastrService } from 'ngx-toastr';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { ProductListingComponent } from '../../checkout/product-listing/product-listing.component';
import { monthList, MY_DATE_FORMATS } from '../../../../model/const/common.const';
import { TrimValueDirective } from '../../../../shared/directives/trim-value.directive';
import { CardMaskDirective } from '../../../../shared/directives/card-mask.directive';
import { FlotNumberDirective } from '../../../../shared/directives/flot-number.directive';
import { ShareViaEmailMobileComponent } from '../../../../shared/components/dialog/share-via-mobile-email/share-via-mobile-email.component';
import { DayMonthYearFormatDirective } from '../../../../shared/directives/day-month-year-format.directive';
export interface PeriodicElement {
  items: any;
  price: string;
  itemTotal: string;

}
@Component({
  selector: 'app-create-invoice',
  standalone: true,
  providers: [provideNativeDateAdapter(), DatePipe, EncryptionService,{provide: MAT_DATE_LOCALE, useValue: 'en-GB'}],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatRadioModule,
    MatSelectModule,
    MatTableModule,
    MatExpansionModule,
    MatTooltipModule,
    TranslateModule,
    CardMaskDirective,
    FlotNumberDirective,
    TrimValueDirective,
    DayMonthYearFormatDirective
  ],
  templateUrl: './create-invoice.component.html',
  styleUrl: './create-invoice.component.scss',
})
export class CreateInvoiceComponent {
  fb = inject(FormBuilder);
  route = inject(Router);
  dialog = inject(MatDialog);
  datePipe = inject(DatePipe);
  encDecObj = inject(EncryptionService);
  invoiceService = inject(InvoiceService);
  toastr = inject(ToastrService);
  cdr = inject(ChangeDetectorRef);
  ac = inject(ActivatedRoute);
  editId = this.ac.snapshot.params['id'];
  selectedBranchLocationId = 0;
  constantService = inject(ConstantService);
  addedItem: any = this.route.getCurrentNavigation()?.extras.state;
  createInvoiceForm!: FormGroup;
  paymentMethodForm1!: FormGroup;
  paymentMethodForm2!: FormGroup;
  displayedColumns: string[] = ['action', 'items', 'price', 'qty', 'itemTotal'];
  discount = signal(0);
  creditCardProcessingFee = signal(0);
  shippingFee = signal(0);
  lateFee = signal(0);
  menuCode: any;
  paymentMethod = '2';
  selectedBranchLocation = 'select_business_location';
  monthsList = monthList;
  yearsList: number[] = [];
  currentYear = new Date().getFullYear();
  currentMonth = new Date().getMonth() + 1;
  orderItems: any = new MatTableDataSource([]);
  initialSubTotal: any = 0;
  calulatedSubtotal = signal<any>(0);
  sharViaFlag: boolean = false
  gift_card: any = '';
  subTotal: Signal<any> = computed(() => this.calulatedSubtotal());
  totalMain: any = 0;
  total: Signal<any> = computed(() => parseFloat(Math.round((((+this.creditCardProcessingFee()) + (+this.shippingFee()) + (+this.lateFee()) + (+this.subTotal())) + (+this.tax()) - (+this.discount())) * 100) / 100 as any).toFixed(2))
  toDay = new Date();
  taxPar = 5;
  tax = signal(0)
  minDueDate: any;
  readonly panelOpenState = signal(true);
  constructor() {
    effect(() => {
      let total = this.total() > 0 ? this.total() : 0
      this.totalMain = total;
    })
  }
  initForm() {
    this.createInvoiceForm = this.fb.group({
      id:[''],
      customer_first_name: ['', Validators.required],
      customer_last_name: ['', Validators.required],
      customer_email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]],
      invoice_title: ['', Validators.required],
      invoice_id: ['', Validators.required],
      message: [''],
      service_date: ['', Validators.required],
      due_date: ['', Validators.required],
      invoice_status: [''],
      send_option: ['1', Validators.required],
      schedule_custom_date: [''],
      frequency: ['1', Validators.required],
      recurring_type: [''],
      order_items: [],
      discount: [''],
      processing_fee: [''],
      late_fee: [''],
      total_amount: [''],
      payment_method: ['1', Validators.required],
      giftcard_id: [''],
      share_email_phone_number: [''],
      share_via: [''],
      user_business_location_id: [''],
      sub_total: [],
      tax:[''],
    });
    this.paymentMethodForm1 = this.fb.group(({
      card_number: ['',],
      card_holder_name: [''],
      mm: [''],
      yy: [''],
      cvv: ['']
    }))
    this.paymentMethodForm2 = this.fb.group({
      gift_card: ['']
    });
    if (this.editId) {
      this.minDueDate='';
      this.createInvoiceForm.patchValue({id:this.editId})
      this.getInvoice();
    }
    this.generateYearRange();
    this.onYearChange();
  }
  private generateYearRange() {
    for (let i = 0; i <= 10; i++) {
      this.yearsList.push(this.currentYear + i);
    }
  }
  checkServiceDate(){
    if(this.createInvoiceForm.value.service_date && this.createInvoiceForm.value.due_date){
      let serviceDate = new Date(this.createInvoiceForm.value.service_date).getTime();
      let dueDate = new Date(this.createInvoiceForm.value.due_date).getTime();
      if(serviceDate>dueDate){
        this.createInvoiceForm.patchValue({due_date:''})
      }
    }
  }
  getInvoice() {
    this.constantService.progressLoader.set(true);
    this.invoiceService.getInvoiceDetail(this.editId).pipe(catchError((err) => {
      this.constantService.progressLoader.set(false);
      this.toastr.error(err.error.message);
      return throwError(() => err.error.message)
    })).subscribe((invoice: ResponseMessage) => {
      if (invoice.code == 200) {
        this.constantService.progressLoader.set(false);
        invoice.data.send_option = JSON.stringify(invoice.data.send_option);
        invoice.data.frequency = JSON.stringify(invoice.data.frequency);
        this.createInvoiceForm.patchValue(invoice.data);
        this.creditCardProcessingFee.set(invoice.data.processing_fee?invoice.data.processing_fee:0);
        this.discount.set(invoice.data.discount?invoice.data.discount:0);
        this.lateFee.set(invoice.data.late_fee?invoice.data.late_fee:0);
        this.createInvoiceForm.patchValue({discount:invoice.data.discount?invoice.data.discount:0})
        this.shippingFee.set(invoice.data.shipping_fee ? invoice.data.shipping_fee : 0);
        invoice.data.order_items.forEach((item: any) => {
          item.itemTotal = parseFloat(Math.round(+item.price * item.quantity * 100) / 100 as any).toFixed(2)
        })
        this.orderItems = new MatTableDataSource(invoice.data.order_items);
        setTimeout(() => {
          this.calculateSubTotal()
        }, 500)
      } else {
        this.constantService.progressLoader.set(false);
        this.toastr.error(invoice.message);
      }
    })
  }
  private onYearChange(): void {
    this.paymentMethodForm1.controls['yy'].valueChanges.subscribe((selectedYear: number) => {
      this.updateMonthListBasedOnYear(selectedYear);
    });
  }

  private updateMonthListBasedOnYear(selectedYear: number): void {
    this.monthsList.forEach((month) => {
      month.disable = selectedYear === this.currentYear && parseInt(month.value) < this.currentMonth;
    });
    this.paymentMethodForm1.controls['mm'].setValue('');
  }
  getInvoiceId(id: any) {
    this.invoiceService.getInvoiceId(id).pipe(
      catchError((error) => {
        this.constantService.progressLoader.set(false);
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message);
      })
    )
      .subscribe((res: ResponseMessage) => {
        this.constantService.progressLoader.set(false);
        if (res.code === 200) {
          this.createInvoiceForm.patchValue({ invoice_id: res.data.code })
        } else {
          this.constantService.progressLoader.set(false);
          this.toastr.error(res.message);
        }
      });
  }
  ngOnInit() {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    this.minDueDate = tomorrow
    this.initForm();
    this.calculateSubTotal();
    if (!this.editId) {
      if (localStorage.getItem(this.selectedBranchLocation)) {
        const id = this.encDecObj.getDecrypt(localStorage.getItem(this.selectedBranchLocation));
        this.selectedBranchLocationId = Number(id);

        this.getInvoiceId(this.selectedBranchLocationId);
      }
    }
    
  }
  get order_items() {
    return this.createInvoiceForm.get('order_items') as any
  }
  calculateSubTotal() {
    this.initialSubTotal = 0;
    let iniSubtotal: number;
    for (let i = 0; i < this.orderItems?.data?.length; i++) { this.initialSubTotal += +this.orderItems?.data[i].itemTotal }; iniSubtotal = this.initialSubTotal;
    this.calulatedSubtotal.set(parseFloat(Math.round(+iniSubtotal * 100) / 100 as any).toFixed(2));
    let tax = (((parseFloat(Math.round(+iniSubtotal * 100) / 100 as any).toFixed(2) as any) * this.taxPar) / 100 as any).toFixed(2)
    this.tax.set(tax);
    this.createInvoiceForm.patchValue({tax:this.tax(),discount:this.discount()});
  }
  removeproduct(j: any) {
    this.orderItems.data = this.orderItems?.data?.filter((res: any, i: any) => { if (i != j) { return res } });
    this.calculateSubTotal();
  }
  redirectToProductAdd() {
    let invoiceData = JSON.parse(JSON.stringify(this.createInvoiceForm.value));
    let dialogRef = this.dialog.open(ProductListingComponent, {
      width: '95vw',
      autoFocus: false,
      panelClass: ['modal-wrapper'],
      data: { invoice: true, orderItems: this.orderItems?.data }
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        this.orderItems = [];
        this.orderItems = new MatTableDataSource(res);
        this.calculateSubTotal();
        this.cdr.detectChanges();
        console.log(this.orderItems)
      }
    })
  }
  applyGiftCard() {
    this.paymentMethodForm2.markAllAsTouched();
    if (this.paymentMethodForm2.valid) {
      this.constantService.progressLoader.set(true);
      let giftCardDetail = {
        code: this.paymentMethodForm2.value.gift_card,
        coupon_type: 2,
        user_business_location_id: this.editId?this.createInvoiceForm.value.user_business_location_id:this.selectedBranchLocationId
      }
      this.invoiceService.applyCoupon(giftCardDetail).pipe(catchError((err) => {
        this.constantService.progressLoader.set(false);
        this.toastr.error(err.error.message);
        // this.paymentMethodForm2.patchValue({ gift_card: '' });
        return throwError(() => err.error.message);
      })).subscribe((coupon: ResponseMessage) => {
        if (coupon.code == 200) {
          this.constantService.progressLoader.set(false);
          this.createInvoiceForm.patchValue({ giftcard_id: coupon.data.id })
          this.discount.set(coupon.data.value);
          // this.calculateSubTotal();
          this.toastr.success(coupon.message);
        } else {
          this.constantService.progressLoader.set(false);
          // this.paymentMethodForm2.patchValue({ gift_card: '' });
          this.toastr.error(coupon.message)
        }
      })
    }
  }
  changeItemQuantity(i: any, action: any) {
    let item = this.orderItems?.data[i];
    
    if (item.quantity > 0) {
      if (action === 'inc') {
        if (+item.quantity < +item.available_stock) {
          item.quantity += 1;
        } else {
          // Display out of stock message
          this.toastr.error('Stock limit reached for this item.', 'Out of Stock');
        }
      } else if (action === 'dec') {
        if (item.quantity != 1) {
          item.quantity -= 1;
        }
      }
  
      item.itemTotal = parseFloat((+item.price * item.quantity).toFixed(2));
    }
  
    this.calculateSubTotal();
  }
  
  changeSendOption(event: any) {
    if (event.value == '2') {
      this.createInvoiceForm.get('schedule_custom_date')?.addValidators(Validators.required);
    } else {
      this.createInvoiceForm.get('schedule_custom_date')?.removeValidators(Validators.required);
      this.createInvoiceForm.patchValue({ frequency: '1' });
      this.createInvoiceForm.get('recurring_type')?.removeValidators(Validators.required);
      this.createInvoiceForm.get('recurring_type')?.updateValueAndValidity();
    }
    this.createInvoiceForm.get('schedule_custom_date')?.updateValueAndValidity();
  }
  changeFrequency(event: any) {
    if (event.value == '2') {
      this.createInvoiceForm.get('recurring_type')?.addValidators(Validators.required);
    } else {
      this.createInvoiceForm.get('recurring_type')?.removeValidators(Validators.required);
    }
    this.createInvoiceForm.get('recurring_type')?.updateValueAndValidity();
  }
  shareVia(type: any) {
    this.createInvoiceForm.markAllAsTouched();
    // this.paymentMethodForm1.markAllAsTouched();
    // this.paymentMethodForm2.markAllAsTouched();

    if (this.createInvoiceForm.invalid) {
      return;
    }
    // if (this.paymentMethod == '2' && this.paymentMethodForm1.invalid) {
    //   return;
    // }
    // if (this.paymentMethodForm1.invalid && this.paymentMethodForm2.invalid) {
    //   return;
    // }
    let data = {
      title: type == 1 ? 'share_via_email' : 'share_via_text',
      enter_mobile: 'enter_mobile',
      enter_email: 'enter_email',
      share_via: type
    }
    let dialogRef = this.dialog.open(ShareViaEmailMobileComponent, { width: '500px', data: data });
    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        this.createInvoiceForm.patchValue({ share_email_phone_number: res, share_via: type });
        this.sendOrDraftInvoice(1)
      }
    })
  }
  sendOrDraftInvoice(invoice_status: any) {
    this.createInvoiceForm.markAllAsTouched();
    this.createInvoiceForm.patchValue({ sub_total: this.subTotal() })
    // this.paymentMethodForm1.markAllAsTouched();
    // this.paymentMethodForm2.markAllAsTouched();
    console.log(this.createInvoiceForm)
    if (this.createInvoiceForm.invalid) {
      return;
    }
    // if (this.paymentMethodForm1.invalid && this.paymentMethodForm2.invalid) {
    //   return;
    // }
    if (this.orderItems.data.length == 0) {
      this.toastr.error('Please add atleast  one product')
      return;
    }
    let paymentDetail: any = this.paymentMethodForm1.value;

    let service_date = this.datePipe.transform(new Date(this.createInvoiceForm.value.service_date), 'YYYY-MM-dd');
    let due_date = this.datePipe.transform(new Date(this.createInvoiceForm.value.due_date), 'YYYY-MM-dd');
    this.createInvoiceForm.patchValue({
      discount: 0,
      processing_fee: this.creditCardProcessingFee(),
      total_amount: this.totalMain,
      late_fee: this.lateFee(),
      service_date: service_date,
      due_date: due_date,
      payment_method: 1
    });
    if (this.createInvoiceForm.value.schedule_custom_date) {
      let custom_date = this.datePipe.transform(new Date(this.createInvoiceForm.value.schedule_custom_date), 'YYYY-MM-dd');
      this.createInvoiceForm.patchValue({ schedule_custom_date: custom_date });
    }
    let invoicePayload: any = JSON.parse(JSON.stringify(this.createInvoiceForm.value));
    invoicePayload.order_items = [];
    this.orderItems.data.forEach((res: any) => {
      invoicePayload.order_items.push(res)
    })

    invoicePayload.order_items = invoicePayload.order_items.map((res: any) => ({
      item_id: res.item_id,
      quantity: res.quantity
    }))
    invoicePayload.order_items = JSON.stringify(invoicePayload.order_items)
    invoicePayload.invoice_status = invoice_status;
    invoicePayload.user_business_location_id = this.editId ? this.createInvoiceForm.value.user_business_location_id : this.selectedBranchLocationId;
    this.constantService.progressLoader.set(true);
    invoicePayload = { ...invoicePayload, ...paymentDetail }
    this.invoiceService.createInvoice(invoicePayload).pipe(
      catchError((error) => {
        this.constantService.progressLoader.set(false);
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message);
      })
    ).subscribe((res: ResponseMessage) => {
      this.constantService.progressLoader.set(false);
      if (res.code === 200) {
        this.toastr.success(res.message);
        this.createInvoiceForm.reset();
        this.paymentMethodForm1.reset();
        this.paymentMethodForm2.reset();
        this.route.navigate(['/invoice'])
        this.createInvoiceForm.patchValue({ share_email_phone_number: '', share_via: '' })
      } else {
        this.constantService.progressLoader.set(false);
        this.toastr.error(res.message);
      }
    });
  }
}

