import { AbstractControl, ValidatorFn } from '@angular/forms';
import { emailPattern, numberPattern } from '../../model/const/common.const';


export function passwordValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    if (!value) {
      return null; // Don't validate empty values to allow for required validator to handle this case
    }
    const hasCapitalAlphabet = /[a-z]/.test(value);
    const hasNormalAlphabet = /[A-Z]/.test(value);
    const hasNumber = /\d/.test(value);
    const hasSpecial = /[!@#$%^&*(),.?":{}|<>]/.test(value);

    const valid = hasCapitalAlphabet && hasNormalAlphabet && hasNumber && hasSpecial;

    return !valid ? { passwordComplexity: true } : null;
  };

}
