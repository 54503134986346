<div class="section-box flex flex-justify-between flex-wrap gap-8px mb-24 flex-align-center ">
  <h1 class="text-white mb-0 mobile-full">Vendor Management</h1>
  <div class="flex gap-24px flex-grow-1 flex-justify-end flex-align-center">
    <mat-form-field appearance="outline" subscriptSizing="dynamic" class="small-input search-inputbx">
      <div class="w-full relative input-rightspace">
        <input type="search" class="form-control" matInput placeholder="Search Menu"  />
        <mat-icon class="ic-input cursor-pointer">search</mat-icon>
      </div>
    </mat-form-field>
    <button routerLink="/vendor-list/add" mat-flat-button class="btn-lg flex-shrink-0" >Add Vendor</button>
  </div>
</div>

<div class="table-sectionbox">
  <div class="flex flex-justify-between flex-wrap gap-8px flex-align-center section-box table-sectionbx">
    <h1 class="mb-0 mobile-full h3 mobile-full">Vendor List</h1>
    <div class="flex gap-16px flex-grow-1 flex-justify-end flex-align-center mobile-full filter-optionswrap flex-wrap">
      <mat-radio-group aria-label="Select an option" class="vendor-radiocheck">
        <mat-radio-button value="1">Weekly</mat-radio-button>
        <mat-radio-button value="2">Monthly </mat-radio-button>
        <mat-radio-button value="2">Yearly</mat-radio-button>
      </mat-radio-group>
      <button mat-flat-button class="flex-shrink-0 export-btn btn-lg" >Export</button>
    </div>
  </div>
    <div class="table-responsive custom-scroll table-wrapper">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    
        <!-- Position Column -->
        <ng-container matColumnDef="vendorName">
          <th mat-header-cell *matHeaderCellDef>VENDOR NAME</th>
          <td mat-cell *matCellDef="let element" class="nowrap"> {{element.vendorName}} </td>
        </ng-container>
      
        <!-- Name Column -->
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef>EMAIL</th>
          <td mat-cell *matCellDef="let element" class="nowrap"> {{element.email}} </td>
        </ng-container>
      
        <!-- Weight Column -->
        <ng-container matColumnDef="phoneNumber">
          <th mat-header-cell *matHeaderCellDef>PHONE NUMBER</th>
          <td mat-cell *matCellDef="let element" class="nowrap"> {{element.phoneNumber}} </td>
        </ng-container>
      
        <!-- Symbol Column -->
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef>DESCRIPTION</th>
          <td mat-cell *matCellDef="let element" class="nowrap"> 
            <div class="text-truncate description-coltext" [innerHTML]="element.description"></div>
          </td>
        </ng-container>
        <ng-container matColumnDef="businessDetail">
          <th mat-header-cell *matHeaderCellDef>BUSINESS DETAILS</th>
          <td mat-cell *matCellDef="let element" >{{element.businessDetail}}</td>
        </ng-container>
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef class="action-col">ACTION</th>
          <td mat-cell *matCellDef="let element">
            <div class="flex table-actionbtn flex-justify-center">
                <button mat-icon-button aria-label="View" matTooltip="View" ><span class="icon-eye"></span></button>
                <button (click)="openDialog()" mat-icon-button aria-label="Delete" matTooltip="Delete" ><span class="icon-ic-trash"></span></button>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
</div>