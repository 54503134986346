import { CommonModule, DOCUMENT } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  Inject,
  signal,
  OnInit,
  inject,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { Router, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { ConstantService } from '../../../services/constant.service';
import { languageArray } from '../../../../model/const/common.const';
import { ThemeType } from '../../../../model/types/GlobalTypes';
import { catchError, Subscription, throwError } from 'rxjs';
import { ResponseMessage } from '../../../../model/interfaces/req.res.interface';
import { ToastrService } from 'ngx-toastr';
import { TranslateModule } from '@ngx-translate/core';
import { EncryptionService } from '../../../services/encryption.service';
import { environment } from '../../../../../environments/environment';
import { BranchLocationComponent } from '../branch-location/branch-location.component';

// Services

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    RouterModule,
    CommonModule,
    FormsModule,
    TranslateModule,
    BranchLocationComponent
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  providers: [EncryptionService],
})
export class HeaderComponent {
  @Input() isOpenSideBar: boolean = false;

  @Output() openCloseSideMenu = new EventEmitter();
  private constantService = inject(ConstantService);
  private toastr = inject(ToastrService);
  private encDecObj = inject(EncryptionService);
  private router = inject(Router);
  languages = languageArray;
  selectedLanguage: String = '';
  subscribe!: Subscription;
  subscribeLogin!: Subscription;
  load = true;
  selectedBranchLocationId: any;
  userDetails:any='';
  deviceType = this.constantService.getDeviceType();

  //#region Localstorage
  language = 'selected_language';
  theme = 'selected_theme';
  selectedBranchLocation = 'select_business_location';
  branchListData: any;

  toggleMenuClicked() {
    this.openCloseSideMenu.emit();
  }

  /**
   * Hold theme mode
   */
  themeType = signal<ThemeType>('light');
  constructor(@Inject(DOCUMENT) private document: Document) {
    this.initializeTheme();
    this.initializeLanguage();
  }

  ngOnInit(): void {
    // this.getBranches();
    if(localStorage.getItem('userDetail')){
      let userDetail = this.encDecObj.getDecrypt(localStorage.getItem('userDetail'));
      this.userDetails = JSON.parse(userDetail)
    }
  }
  private initializeTheme(): void {
    const storedTheme = localStorage.getItem(this.theme) as ThemeType;
    const theme = storedTheme || 'light';
    this.changeTheme(theme);
  }

  private initializeLanguage() {
    const lang = localStorage.getItem(this.language) || environment.defaultLang; // Default is 'en' if no language is set
    this.selectedLanguage = lang;
    this.constantService.translateData(lang);
    // this.changeLanguage(lang);
  }

  changeLanguage(lan: string) {
    let lang = {
      language: lan === 'es' ? 1 : 2, // Use 1 for 'es' (Spanish) and 2 for (English)
    };
    this.load = true;
    this.subscribe = this.constantService
      .setLanguageAPI(lang)
      .pipe(
        catchError((error) => {
          this.load = false;
          this.toastr.error(error.error.message);
          return throwError(() => error.error.message);
        })
      )
      .subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          this.constantService.translateData(lan);
          this.constantService.langChangeSub.next(lan);
        } else {
          this.toastr.error(res.message);
        }
      });
  }

  changeTheme(theme: any) {
    this.constantService.setTheme(theme);
    this.themeType.set(theme);
  }

  // getBranches() {
  //   this.constantService
  //     .getBranchList()
  //     .pipe(
  //       catchError((error) => {
  //         this.toastr.error(error.error.message);
  //         return throwError(() => error.error.message);
  //       })
  //     )
  //     .subscribe((res: ResponseMessage) => {
  //       if (res.code === 200) {
  //         this.branchListData = res.data.data;
  //         this.setBranchLocation()
  //       } else {
  //         this.toastr.error(res.message);
  //       }
  //     });
  // }

  setBranchLocation() {
    if (localStorage.getItem(this.selectedBranchLocation)) {
      const id = this.encDecObj.getDecrypt(
        localStorage.getItem(this.selectedBranchLocation)
      );
      this.selectedBranchLocationId = Number(id);
    } else if (this.branchListData?.length > 0) {
      this.selectLocaiton(this.branchListData[0].business_location_id);
    }
  }

  selectLocaiton(id: number) {
    this.selectedBranchLocationId = id;
    const temp = this.encDecObj.setEncrypt(id.toString());
    localStorage.setItem(this.selectedBranchLocation, temp);
  }

  redirectToModule() {
    let res = {
      success: true,
      title: 'Product Management'
    }
    this.constantService.selectMenu.next(res);
    this.router.navigate(['/product-list/add'])
  }

  swicthToVendorAdmin() {
    // if (this.subscribeLogin) this.subscribeLogin.unsubscribe();
    // this.constantService.progressLoader.set(true);
    // this.subscribeLogin = this.constantService.swicthToVendorAdmin('').pipe(
    //   catchError((error) => {
    //     this.toastr.error(error.error.message);
    //     this.constantService.progressLoader.set(false);
    //     return throwError(() => error.error.message);
    //   })
    // ).subscribe((res: ResponseMessage) => {
    //   this.constantService.progressLoader.set(false);
    //   if (res.code === 200) {

    //   } else {
    //     this.toastr.error(res.message);
    //   }
    // });
    const token = localStorage.getItem('token');
    const url = environment.vendorAdminUrl+`?token=${token}`;
    this.constantService.redirectToURL(url, 'true');
  }

  ngOnDestroy(): void {
    if (this.subscribe) this.subscribe.unsubscribe();
    if (this.subscribeLogin) this.subscribeLogin.unsubscribe();
  }
}
