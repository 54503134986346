import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
export interface PeriodicElement {
  id: any;
  customerName: string;
  customerNumber: string;
  itemTotal: number;
  status: string
}

const ELEMENT_DATA: PeriodicElement[] = [
  {
    id: '#12345',
    customerName: 'Corey Calzoni',
    customerNumber: '(406) 555-0120',
    itemTotal: 150,
    status: 'Ready to Serve'
  },
];
@Component({
  selector: 'app-my-orders',
  standalone: true,
  imports: [
    MatTableModule,
    MatRadioModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule
  ],
  templateUrl: './my-orders.component.html',
  styleUrl: './my-orders.component.scss'
})
export class MyOrdersComponent {
  displayedColumns: string[] = ['id','customerName',  'customerNumber', 'itemTotal', 'status', 'action'];
  dataSource = ELEMENT_DATA;

  isClassAdded = false;

  toggleClass() {
    this.isClassAdded = !this.isClassAdded;
  }
}
