import { Directive, ElementRef, HostListener, forwardRef } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: '[appCardMask]',
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CardMaskDirective),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CardMaskDirective),
      multi: true
    }
  ]
})
export class CardMaskDirective implements ControlValueAccessor, Validator {

  private onChange: (value: string) => void = () => { };
  private onTouched: () => void = () => { };
  private previousValue: string = '';

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event: Event): void {
    const input = this.el.nativeElement as HTMLInputElement;
    let inputValue = input.value.replace(/\D/g, ''); // Remove all non-digit characters

    // Limit to 16 digits
    if (inputValue.length > 16) {
      inputValue = inputValue.slice(0, 16);
    }

    // Add hyphens every 4 digits
    const formattedValue = inputValue.replace(/(\d{4})(?=\d)/g, '$1-');

    // Update the input value with the formatted value
    input.value = formattedValue;

    // Notify Angular of the change
    this.onChange(inputValue);
  }

  // Implement ControlValueAccessor methods
  writeValue(value: string): void {
    if (value) {
      // Format value and set it to the input
      const formattedValue = value.replace(/\D/g, '').replace(/(\d{4})(?=\d)/g, '$1-');
      this.el.nativeElement.value = formattedValue;
      this.previousValue = formattedValue;
    }
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.el.nativeElement.disabled = isDisabled;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const value = control.value || '';
    const digitsOnly = value.replace(/\D/g, '');

    if (digitsOnly.length !== 16) {
      return { 'cardMask': 'Credit card number must be exactly 16 digits' };
    }

    return null;
  }
}
