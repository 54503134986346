<div class="section-box mb-24 flex flex-wrap flex-justify-between flex-align-center header-section gap-8px">
  <h1 class="text-white mb-0 ">{{this.action | translate}} {{"category" | translate}}</h1>
  <div class="flex">
    <button mat-flat-button class="btn-outline-secondary backbtn" (click)="backToCategoryList()">
      <svg width="7" height="15" viewBox="0 0 7 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.2876 13.4386L1.3976 8.54859C0.820097 7.97109 0.820097 7.02609 1.3976 6.44859L6.2876 1.55859"
          stroke="currentColor" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round"
          stroke-linejoin="round" />
      </svg>
      {{"back" | translate}}
    </button>
  </div>
</div>
<div>

  <div class="form-box section-box card-boxblock" [ngClass]="{'pointer-none': constantService.progressLoader()}">
    <form [formGroup]="categoryFormGroup">
      <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-20">
        <mat-label>{{"category_name" | translate}}</mat-label>
        <input matInput formControlName="categoryName" [maxlength]="49"/>
      </mat-form-field>
      <div class="mb-20" [ngClass]="{'mat-form-field-invalid': fileTypeError }">
        <label for="upload-image" class="form-label">{{"upload_image" | translate}}</label>
        <label for="upload-image" class="form-control w-full flex flex-align-center cursor-pointer relative">
          <span>{{imageFileName}}</span>
          <input type="file" class="form-control" id="upload-image" (change)="uploadCategoryImage($event)"
            accept=".png, .jpg, .jpeg" formControlName="categoryImage" #fileInput hidden (click)="imageCheck()" />

          @if (!imageFileName) {
          <span class="text-white file-text">{{"browse" | translate}}</span>
          }
          <span class="icon-image-plus ic-input"></span>

        </label>
        <span class="mat-mdc-form-field-error">{{fileTypeError | translate}}</span>
      </div>
    </form>
    <div class="text-end">
      <button mat-flat-button class="btn-lg" (click)="addUpdateCategory()">
        {{action == 'Add' ? ('add_now'| translate):('update' | translate)}}
      </button>
    </div>
  </div>
</div>