import { Component, Inject, inject, OnInit, Optional } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { FlotNumberDirective } from '../../../../../shared/directives/flot-number.directive';

@Component({
  selector: 'app-add-tip',
  standalone: true,
  imports: [
    RouterModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    FlotNumberDirective,
  ],
  templateUrl: './add-tip.component.html',
  styleUrls: ['./add-tip.component.scss']
})
export class AddTipComponent implements OnInit {

  localData: any;
  amount: any;
  private dialogRef = inject(MatDialogRef<AddTipComponent>);

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: AddTipComponent) {
    this.localData = { ...data };
  }

  ngOnInit(): void {
    if (this.localData.action == 'Edit') {
      this.amount = this.localData.amount
    }
  }

  submit() {
    this.dialogRef.close({
      success: true,
      type: this.localData.type,
      tipAmount: this.amount ? parseFloat(this.amount).toFixed(2) : 0
    })
  }



}
