<mat-dialog-content class="text-center">
  <div class="mb-24 product-smallimg radius-12">
    <img src="https://images.pexels.com/photos/1337825/pexels-photo-1337825.jpeg?auto=compress&cs=tinysrgb&w=600" alt="Watermelon Mint Cooler" class="img-fit" />
  </div>
  <div class="flex flex-justify-between gap-8px mb-24 product-infobx">
    <h3 class="h1 mb-0 text-white text-truncate">Watermelon Mint Cooler</h3>
    <span class="h1 text-primary flex-shrink-0 fw-bold">$25.29</span>
  </div>
  <div class="flex flex-wrap flex-justify-between mb-4 small-infobxwrap">
    <div class="gap-12px fs-14 block mb-20 block-box small-infobx text-left">
      <span class="text-block mb-4">Category</span>
      <h5 class="text-grey-medium fs-16 fw-bold text-truncate">Food</h5>
    </div>
    <div class="gap-12px fs-14 block mb-20 block-box small-infobx text-left">
      <span class="text-block mb-4">Branch Location</span>
      <h5 class="text-grey-medium fs-16 fw-bold text-truncate">New York</h5>
    </div>
    <div class="gap-12px fs-14 block mb-20 block-box small-infobx text-left">
      <span class="text-block mb-4">Stock</span>
      <h5 class="text-grey-medium fs-16 fw-bold text-truncate">150</h5>
    </div>
    <div class="gap-12px w-full fs-14 block mb-20 block-box text-left mobile-smallbx">
      <span class="text-block mb-4">Available to Sell</span>
      <h5 class="text-grey-medium fs-16 fw-bold text-truncate">Available</h5>
    </div>
  </div>
 
  <div class="btn-box mb-24">
    <button mat-stroked-button  mat-dialog-close class="w-full btn-outline-primary">Edit</button>
  </div>
</mat-dialog-content>