<mat-dialog-content class="text-center">
  <div class="mb-24 radius-12">
    <img src="assets/images/send-icon.png" alt="Send Payment Link" class="dialog-smallimg" />
  </div>
  <div class="block text-grey-light mb-24">
    <h3 class="h1 text-white">Send Payment Link</h3>
  </div>
  <div class="mb-20 text-center">
    <label
      id="example-radio-group-label"
      class="text-grey-light d-block mb-12 option-label"
      >Choose Option</label
    >
    <mat-radio-group aria-label="Select an option" class="flex-justify-center">
      <mat-radio-button value="1">Via Email</mat-radio-button>
      <mat-radio-button value="2">Via Text Message</mat-radio-button>
    </mat-radio-group>
  </div>
  <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-20">
    <mat-label>Enter Email</mat-label>
    <input matInput />
  </mat-form-field>
  <div class="btn-box text-center">
    <button mat-flat-button mat-dialog-close class="btn-outline-primary">Cancel</button>
    <button mat-flat-button mat-dialog-close>Send Now</button>
  </div>
</mat-dialog-content>