import { AbstractControl, ValidatorFn } from '@angular/forms';
import { emailPattern, numberPattern } from '../../model/const/common.const';


export function emailValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    if (!value) return null;

    const isValidEmail = emailPattern.test(value);

    return isValidEmail  ? null : { email: true };
    
  };
}
