import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
export interface PeriodicElement {
  itemname: any;
  price: number;
  quantity: number;
  totalGuest: number;
  total: number,
  status: string
}

const ELEMENT_DATA: PeriodicElement[] = [
  {
    itemname:
      'Watermelon Mint Cooler',
    price: 25.29,
    quantity: 1,
    totalGuest: 150,
    total: 25.29,
    status: 'paid'
  },
  {
    itemname:'Balinese Betutu Duck',
    price: 25.29,
    quantity: 1,
    totalGuest: 150,
    total: 25.29,
    status: 'paid'
  },
  {
    itemname: 'Spaghetti Bolognese',
    price: 25.29,
    quantity: 1,
    totalGuest: 150,
    total: 25.29,
    status: 'paid'
  },
  {
    itemname: 'Gourmet Wagyu Burger',
    price: 25.29,
    quantity: 1,
    totalGuest: 150,
    total: 25.29,
    status: 'paid'
  },
];

@Component({
  selector: 'app-manage-online-order',
  standalone: true,
  imports: [
    RouterModule,
    MatTableModule,
    MatButtonModule
  ],
  templateUrl: './manage-online-order.component.html',
  styleUrl: './manage-online-order.component.scss'
})
export class ManageOnlineOrderComponent {
  displayedColumns: string[] = ['itemname','price',  'quantity', 'totalGuest', 'total', 'status', 'action'];
  dataSource = ELEMENT_DATA;
}
