<div class="table-sectionbox">
  <div class="flex flex-justify-between flex-wrap gap-8px flex-align-center section-box table-sectionbx">
    <h1 class="mb-0 mobile-full h3">Vendor List</h1>
    
    <button mat-flat-button class="flex-shrink-0 addvendor-btn mobile-full">Add Vendor</button>
    
  </div>
    <div class="table-responsive custom-scroll table-wrapper">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    
        <!-- NAME Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>NAME</th>
          <td mat-cell *matCellDef="let element" class="nowrap"> {{element.name}} </td>
        </ng-container>
      
        <!-- EMAIL Column -->
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef>EMAIL</th>
          <td mat-cell *matCellDef="let element" class="nowrap"> {{element.email}} </td>
        </ng-container>
      
        <!-- Action Column -->
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef class="action-col">ACTION</th>
          <td mat-cell *matCellDef="let element">
            <div class="flex table-actionbtn flex-justify-center">
              <button mat-icon-button aria-label="Edit" matTooltip="Edit"><span class="icon-development"> </span>
            </button>
            <button mat-icon-button aria-label="Delete" matTooltip="Delete"><span class="icon-ic-trash"></span></button>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
</div>