<div class="table-responsive custom-scroll table-wrapper">
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

    <!-- Position Column -->
    <ng-container matColumnDef="itemname">
      <th mat-header-cell *matHeaderCellDef class="item-namecol"> ITEM NAME </th>
      <td mat-cell *matCellDef="let element" class="nowrap">
        <div [innerHTML]="element.itemname" class="item-infobox cursor-pointer text-decoration-underline hover-link"></div>
      </td>
    </ng-container>
  
    <!-- Weight Column -->
    <ng-container matColumnDef="price">
      <th mat-header-cell *matHeaderCellDef>PRICE</th>
      <td mat-cell *matCellDef="let element"> ${{element.price}} </td>
    </ng-container>
  
    <!-- Symbol Column -->
    <ng-container matColumnDef="quantity">
      <th mat-header-cell *matHeaderCellDef  class="text-center">QTY.</th>
      <td mat-cell *matCellDef="let element" class="text-center"> {{element.quantity}} </td>
    </ng-container>
    <ng-container matColumnDef="totalGuest">
      <th mat-header-cell *matHeaderCellDef class="text-center">TOTAL GUESTS</th>
      <td mat-cell *matCellDef="let element" class="text-center">{{ element.totalGuest }}</td>
    </ng-container>
    <ng-container matColumnDef="total">
      <th mat-header-cell *matHeaderCellDef>ITEM TOTAL</th>
      <td mat-cell *matCellDef="let element">${{element.total}} </td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>PAYMENT STATUS</th>
      <td mat-cell *matCellDef="let element" >
        <span class="badge badge-light-success badge-small">{{element.status}}</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef class="action-col">ACTION</th>
      <td mat-cell *matCellDef="let element">
        <div class="flex table-actionbtn">
          <span class="badge badge-success cursor-pointer">Approve</span>
          <span class="badge badge-danger cursor-pointer">Reject</span>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>