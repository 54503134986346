import { CommonModule } from '@angular/common';
import { Component, inject, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { AuthService } from '../auth.service';
import { ToastrService } from 'ngx-toastr';
import { catchError, Subscription, throwError } from 'rxjs';
import { MatRadioModule } from '@angular/material/radio';
import { NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { PasswordFieldType } from '../../../model/interfaces/auth.interface';
import { passwordMatchValidator } from '../../../shared/validators/password-match-validator.directive';
import { ResponseMessage } from '../../../model/interfaces/req.res.interface';
import { ConstantService } from '../../../shared/services/constant.service';
import { passwordValidator } from '../../../shared/validators/password-validator.directive';
import { TranslateModule } from '@ngx-translate/core';



@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrl: '../authentication.scss',
  imports: [
    CommonModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    RouterModule,
    ReactiveFormsModule,
    MatRadioModule,
    TranslateModule
  ],
  standalone: true
})
export class ResetPasswordComponent {
  private authService = inject(AuthService);
  private fb = inject(NonNullableFormBuilder);
  private ac = inject(ActivatedRoute);
  private route = inject(Router);
  private constantService = inject(ConstantService);
  private token = signal<string>(this.ac.snapshot.params['token']);
  private toastr = inject(ToastrService);
  private resetPasswordSubscription!:Subscription 
  passwordFieldType: PasswordFieldType = 'password';
  confirmPasswordFieldType: PasswordFieldType = 'password';


  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.setModuleTitle();
  }
  /**
   * reset-password form initialisation
   */
  resetPasswordForm = this.fb.group({
    password: ['', [Validators.required, Validators.minLength(6), passwordValidator()]],
    confirmPassword: ['', Validators.required]
  },
    { validators: passwordMatchValidator })

  /**
   * Calls the reset password api and resets the password
   */
  resetPass() {
    if(this.resetPasswordSubscription)this.resetPasswordSubscription.unsubscribe()
    if (this.resetPasswordForm.invalid) {
      return;
    }
    let obj = {
      token: this.token(),
      password: this.resetPasswordForm.value.password,
      password_confirmation: this.resetPasswordForm.value.confirmPassword
    }
   this.resetPasswordSubscription = this.authService.resetPassword(obj).pipe(catchError((err) => {
      this.toastr.error(err.error.message)
      return throwError(() => err.error.message)
    }
    )).subscribe((res: ResponseMessage) => {
      if (res.code == 200) {
        this.toastr.success(res.message);
        this.route.navigate(['/login']);
      } else {
        this.toastr.error(res.message)
      }
    })
  }
   /**
   *  Password visibility change
   */
   togglePasswordVisibility(field: 'password' | 'confirmPassword'): void {
    if (field === 'password') {
      this.passwordFieldType =
        this.passwordFieldType === 'password' ? 'text' : 'password';
    } else if (field === 'confirmPassword') {
      this.confirmPasswordFieldType =
        this.confirmPasswordFieldType === 'password' ? 'text' : 'password';
    }
  }

  setModuleTitle() {
    this.constantService.setModuleTitle('Reset Password');
  }
}
