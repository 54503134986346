<div class="authentication-page flex flex-align-center flex-justify-center">
    <div class="authentication-box">
        <div class="auth-headerwrapper flex">
            <div class="authlogo-box">
                <div class="auth-box flex flex-align-center flex-justify-center">
                    <img src="assets/images/logo-small.svg" alt="MuyBi" />
                </div>
            </div>
            <div class="auth-header">
                <h1>{{'reset_password' | translate}}</h1>
                <p class="text-grey">{{'dont_worry' | translate}}</p>
            </div>
        </div>
        <div class="auth-innerbox">
            <form [formGroup]="resetPasswordForm" (ngSubmit)="resetPass()">
                <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
                    <mat-label>{{'new_password' | translate}}</mat-label>
                    <div class="password-field w-full" [class.active]="passwordFieldType === 'text'">
                        <input matInput matNativeControl [type]="passwordFieldType" formControlName="password" />
                        <span class="eye-icon" (click)="togglePasswordVisibility('password')"></span>
                      </div>
                </mat-form-field>
                <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
                    <mat-label>{{'confirm_pass' | translate}}</mat-label>
                    <div class="password-field w-full" [class.active]="confirmPasswordFieldType === 'text'">
                        <input matInput matNativeControl [type]="confirmPasswordFieldType" formControlName="confirmPassword" />
                        <span class="eye-icon" (click)="togglePasswordVisibility('confirmPassword')"></span>
                      </div>
                </mat-form-field>
                <button mat-flat-button class="w-full mb-24 btn-lg" (click)="resetPass()">{{'submit' | translate}}</button>
            </form>
        </div>
    </div>
</div>