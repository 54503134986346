<header class="flex header-wrapper">
  <a routerLink="/" class="header-logo flex-align-center flex flex-justify-center">
    <img src="assets/images/logo-main.svg" alt="MuyBi" class="logo-dark" />
    <img src="assets/images/logo-main-light.svg" alt="MuyBi" class="logo-light" />
  </a>
  <div class="nav-wrapper flex flex-align-center flex-justify-between flex-grow-1">
    <div class="flex navbarleft-wrap hide-mobile">
      <button mat-flat-button class="flex-shrink-0 createorder-btn" (click)="redirectToModule()">
        {{ 'add_new_item'|translate }}
      </button>
    </div>
    <div class="flex flex-align-center navbarright-wrap">
      <div class="theme-buttons flex">
        <span class="icon-sun cursor-pointer" [ngClass]="{'active': themeType() == 'light'}"
          (click)="changeTheme('light')"></span>
        <span (click)="changeTheme('dark')" [ngClass]="{'active': themeType() == 'dark'}"
          class="icon-dark-mode cursor-pointer text-grey-light active"></span>
      </div>
      @if(userDetails?.user_type == 2){
      <div class="flex">
        <button mat-flat-button class="btn-outline-primary hide-mobile take-paymentbtn" (click)="swicthToVendorAdmin()">
          {{'switch_to_vendor_admin' | translate}}
        </button>
      </div>
      }
      <mat-form-field appearance="outline" subscriptSizing="dynamic" class="header-selection lang-selection">
        <mat-select [(ngModel)]="selectedLanguage" panelClass="lang-menu">
          @for (lang of languages; track $index) {
          <mat-option (click)="changeLanguage(lang.prefix)" [value]="lang.prefix">{{lang.name}}</mat-option>
          }
        </mat-select>
      </mat-form-field>
      <!-- <mat-form-field appearance="outline" subscriptSizing="dynamic"
        class="hide-mobile header-selection location-selection">
        <mat-select placeholder="{{'branch_location' | translate}}" [(ngModel)]="selectedBranchLocationId">
          @for (branch of branchListData; track $index) {
          <mat-option (click)="selectLocaiton(branch.business_location_id)"
            [value]="branch.business_location_id">{{branch.name}}</mat-option>
          }
        </mat-select>
      </mat-form-field> -->
      <!-- @if (deviceType =='desktop') { -->
      <div class="hide-mobile header-selection location-selection">
        <app-branch-location></app-branch-location>
      </div>
      <!-- } -->
      <button mat-flat-button class="btn-outline-primary hide-mobile take-paymentbtn">
        {{'take_payment' | translate}}
      </button>
      <button mat-mini-fab aria-label="Notification" class="text-theme">
        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4.01393 8.38943C4.01393 7.73465 4.1429 7.08627 4.39347 6.48133C4.64405 5.87639 5.01132 5.32672 5.47432 4.86372C5.93733 4.40072 6.48699 4.03344 7.09193 3.78287C7.69688 3.53229 8.34525 3.40332 9.00004 3.40332C9.65482 3.40332 10.3032 3.53229 10.9081 3.78287C11.5131 4.03344 12.0627 4.40072 12.5257 4.86372C12.9888 5.32672 13.356 5.87639 13.6066 6.48133C13.8572 7.08627 13.9861 7.73465 13.9861 8.38943V8.38943C13.9861 10.8755 14.507 12.32 14.9653 13.1117C15.014 13.196 15.0397 13.2916 15.0397 13.3889C15.0398 13.4863 15.0143 13.582 14.9658 13.6664C14.9173 13.7508 14.8474 13.8209 14.7632 13.8698C14.6791 13.9187 14.5835 13.9446 14.4861 13.945H3.51393C3.41657 13.9446 3.32102 13.9187 3.23683 13.8698C3.15265 13.8209 3.0828 13.7508 3.03427 13.6664C2.98574 13.582 2.96024 13.4863 2.96033 13.3889C2.96041 13.2916 2.98608 13.196 3.03476 13.1117C3.49309 12.32 4.01393 10.8755 4.01393 8.38943Z"
            stroke="currentColor" stroke-width="1.16667" stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M6.77771 13.9443V14.4999C6.77771 15.0893 7.01184 15.6545 7.42858 16.0712C7.84533 16.488 8.41056 16.7221 8.99993 16.7221C9.5893 16.7221 10.1545 16.488 10.5713 16.0712C10.988 15.6545 11.2222 15.0893 11.2222 14.4999V13.9443"
            stroke="currentColor" stroke-width="1.16667" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M12.8472 2.27832C13.9783 2.99236 14.8912 4.00379 15.4861 5.20193" stroke="currentColor"
            stroke-width="1.16667" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M2.51379 5.20193C3.1086 4.00379 4.02154 2.99236 5.15268 2.27832" stroke="currentColor"
            stroke-width="1.16667" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </button>
      <button id="mobileMenu" (click)="toggleMenuClicked()" class="menu-btn" mat-mini-fab aria-label="Menu">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="20px" height="20px">
          <path
            d="M 5 8 A 2.0002 2.0002 0 1 0 5 12 L 45 12 A 2.0002 2.0002 0 1 0 45 8 L 5 8 z M 5 23 A 2.0002 2.0002 0 1 0 5 27 L 45 27 A 2.0002 2.0002 0 1 0 45 23 L 5 23 z M 5 38 A 2.0002 2.0002 0 1 0 5 42 L 45 42 A 2.0002 2.0002 0 1 0 45 38 L 5 38 z" />
        </svg>
      </button>
    </div>
  </div>
</header>