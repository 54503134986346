import { Component, inject } from '@angular/core';
import { HeaderComponent } from '../header/header.component';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { RouterModule } from '@angular/router';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ConstantService } from '../../../services/constant.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss',
  standalone: true,
  imports: [
    HeaderComponent,
     RouterModule, 
     SidebarComponent,
     MatProgressBarModule,
     CommonModule
    ],
})
export class LayoutComponent {
  constantService = inject(ConstantService)
  load = this.constantService.progressLoader; 
  public isOpenSideBar = false;
}
