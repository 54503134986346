import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ManageRefundService } from '../service/manage-refund.service';
import { ToastrService } from 'ngx-toastr';
import { ConstantService } from '../../../../shared/services/constant.service';
import { EncryptionService } from '../../../../shared/services/encryption.service';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { catchError, Subscription, throwError } from 'rxjs';
import { ResponseMessage } from '../../../../model/interfaces/req.res.interface';
import { PaymentMethodTypePipe } from '../../../../shared/pipes/payment-method-type.pipe';

@Component({
  selector: 'app-refund-request',
  standalone: true,
  imports: [
    MatTableModule,
    MatFormFieldModule,
    RouterModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    FormsModule,
    PaymentMethodTypePipe
  ],
  templateUrl: './refund-request.component.html',
  styleUrls: ['./refund-request.component.scss'], // Fixed typo here
  providers: [EncryptionService]
})
export class RefundRequestComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['action', 'name', 'price', 'quantity', 'total_amount'];
  dataSourceRefundDetail!: MatTableDataSource<any>;
  private tempRefundDetail = 'temp_refund_detail';
  selectedBranchLocation = 'select_business_location';
  selectedBranchLocationId = 0;
  protected taxPer = 5;
  protected refundDetailData: any;
  private modifiedRefundItems: any[] = [];
  refundFormGroup!: FormGroup;
  isSelectOtherReason: boolean = false;
  subscribeAPI!: Subscription;
  questionList: any;

  // Inject services
  private refundService = inject(ManageRefundService);
  private toastr = inject(ToastrService);
  private router = inject(Router);
  private fb = inject(FormBuilder);
  private activateRoute = inject(ActivatedRoute);
  private encDecObj = inject(EncryptionService);
  public constantService = inject(ConstantService);

  constructor() {
    const id = this.activateRoute.snapshot.params['id'];
    if (!id) {
      this.goToRefundList();
      return;
    }
    this.initializeForm();
  }


  private initializeForm(): void {
    this.refundFormGroup = this.fb.group({
      refund_to: [{ value: '', disabled: true }, Validators.required],
      refund_amount: [{ value: '', disabled: true }, Validators.required],
      select_reason: ['', Validators.required],
      reason: ['',]
    });
  }

  ngOnInit(): void {
    this.loadRefundDetailData();
    this.getRefundQuestion();
  }

  getRefundQuestion() {
    this.constantService.progressLoader.set(true);
    this.refundService.getRefundQuestion().pipe(
      catchError((error) => {
        this.constantService.progressLoader.set(false);
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message);
      })
    ).subscribe((res: ResponseMessage) => {
      this.constantService.progressLoader.set(false);
      if (res.code === 200) {
        this.questionList = res.data;
      } else {
        this.toastr.error(res.message);
      }
    });
  }
  selectQuestion(que: any) {
    const reasonControl = this.refundFormGroup.get('reason');

    if (que.id === 9 && que.question.toLowerCase() === 'others') {
      this.isSelectOtherReason = true;
      reasonControl?.setValidators([Validators.required]);
    } else {
      this.isSelectOtherReason = false;
      reasonControl?.clearValidators();
    }
    reasonControl?.updateValueAndValidity();
  }


  private loadRefundDetailData(): void {
    const tempOrderData = localStorage.getItem(this.tempRefundDetail);
    if (tempOrderData) {
      this.refundDetailData = JSON.parse(this.encDecObj.getDecrypt(tempOrderData));
      this.setFromData();
    }
  }

  private setFromData(): void {
    this.refundFormGroup.patchValue({
      refund_to: `${this.refundDetailData.customer_first_name} ${this.refundDetailData.customer_last_name}`,
    });

    this.modifiedRefundItems = this.refundDetailData.items.map((item: any) => ({
      ...item,
      isSelected: false
    }));

    this.updateDataSource();
  }

  isAllSelected(): boolean {
    return this.modifiedRefundItems.every(item => item.isSelected);
  }

  isIndeterminate(): boolean {
    const numSelected = this.modifiedRefundItems.filter(item => item.isSelected).length;
    return numSelected > 0 && numSelected < this.modifiedRefundItems.length;
  }

  masterToggle(): void {
    const isSelected = this.isAllSelected();
    this.modifiedRefundItems = this.modifiedRefundItems.map(item => ({
      ...item,
      isSelected: !isSelected
    }));
    this.updateDataSource();
  }

  onCheckboxChange(item: any): void {
    this.modifiedRefundItems = this.modifiedRefundItems.map(existingItem =>
      existingItem.item_id === item.item_id
        ? { ...existingItem, isSelected: item.isSelected }
        : existingItem
    );
    this.updateDataSource();
  }


  incrementQuantity(item: any): void {
    const targetItem = this.modifiedRefundItems.find(existingItem => existingItem.item_id === item.item_id);
    const originalItem = this.refundDetailData.items.find((original: { item_id: any; }) => original.item_id === item.item_id);

    if (targetItem && originalItem && targetItem.quantity < originalItem.quantity) {
      targetItem.quantity += 1;
      this.updateDataSource();
    } else {
      this.toastr.error("You can't increase the quantity more than you ordered.");
    }
  }


  decrementQuantity(item: any): void {
    const targetItem = this.modifiedRefundItems.find(existingItem => existingItem.item_id === item.item_id);
    if (targetItem && targetItem.quantity > 1) {
      targetItem.quantity -= 1;
      this.updateDataSource();
    }
  }

  private updateDataSource(): void {
    this.dataSourceRefundDetail = new MatTableDataSource(this.modifiedRefundItems);
    this.calculateAndUpdateRefundAmount();
  }

  private calculateAndUpdateRefundAmount(): void {
    const selectedItemsTotal = this.modifiedRefundItems
      .filter(item => item.isSelected)
      .reduce((total, item) => total + (item.price * item.quantity), 0);

    const selectedQuantityTotal = this.modifiedRefundItems
      .filter(item => item.isSelected)
      .reduce((total, item) => total + item.quantity, 0);

    let discount = 0;

    // Calculate Coupon Discount // 1 = Fix amount 2 = Percentage
    if (this.refundDetailData.coupon) {
      const couponValue = this.refundDetailData.coupon.value;
      if (this.refundDetailData.coupon.type == 1) {
        // fix amount
        // discount = this.refundDetailData.total_amount / (couponValue * selectedItemsTotal)
        discount = (couponValue * selectedItemsTotal) / this.refundDetailData.sub_total
      } else if (this.refundDetailData.coupon.type == 2) {
        // percentage 
        discount = (selectedItemsTotal * couponValue) / 100;
      }
    }
    // Calculate total deductions
    const totalDeduction = discount;

    const refundAmount = selectedItemsTotal > 0 ? selectedItemsTotal - totalDeduction : 0;

    this.refundFormGroup.patchValue({
      refund_amount: `$${refundAmount.toFixed(2)}`
    });
  }


  goToRefundList(): void {
    localStorage.removeItem(this.tempRefundDetail);
    this.router.navigate(['/refunds'])
  }

  backToRefundList() {
    localStorage.removeItem(this.tempRefundDetail);
    this.router.navigate(['/refunds'], { queryParams: { back: this.refundDetailData.id } });
  }

  getBusinesslocation() {
    if (localStorage.getItem(this.selectedBranchLocation)) {
      const id = this.encDecObj.getDecrypt(localStorage.getItem(this.selectedBranchLocation));
      this.selectedBranchLocationId = Number(id);
    }
  }

  sendRefundRequest() {
    this.refundFormGroup.markAllAsTouched();

    if (this.refundFormGroup.invalid) return;

    if (this.subscribeAPI) {
      this.subscribeAPI.unsubscribe();
    }

    const selectedItems = this.modifiedRefundItems.filter(item => item.isSelected);

    const obj = {
      order_items: JSON.stringify(selectedItems),
      order_id: this.refundDetailData.order_id,
      reason_id: this.refundFormGroup.value.select_reason,
      reason_text: this.refundFormGroup.value.reason,
      refund_amount: this.refundFormGroup.getRawValue().refund_amount.slice(1),
    };

    this.constantService.progressLoader.set(true);

    this.subscribeAPI = this.refundService.sendRefundRequest(obj).pipe(
      catchError((error) => {
        this.constantService.progressLoader.set(false);
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message);
      })
    ).subscribe((res: ResponseMessage) => {
      this.constantService.progressLoader.set(false);

      if (res.code === 200) {
        this.toastr.success(res.message);
        this.goToRefundList();
      } else {
        this.toastr.error(res.message);
      }
    });
  }




  ngOnDestroy(): void {
    if (this.subscribeAPI) this.subscribeAPI.unsubscribe();
    // Implement cleanup if needed
  }
}
