<mat-dialog-content class="text-center">
  <div class="mb-24 radius-12">
    <img src="assets/images/delete-icon.png" alt="Delete Waiting" class="dialog-smallimg" />
  </div>
  <div class="block text-grey-light mb-24">
    <h3 class="h1 mb-24 text-white">Delete Waiting</h3>
    <p>Are you sure you want to delete this reservation?</p>
  </div>
  <div class="btn-box text-center">
    <button mat-flat-button mat-dialog-close class="btn-outline-primary">Cancel</button>
    <button mat-flat-button mat-dialog-close>Yes, Confirm</button>
  </div>
</mat-dialog-content>