<mat-dialog-content class="text-center">
  <div class="mb-24 radius-12">
    <img src="assets/images/verification.png" alt="Message Sent Successfully" class="dialog-smallimg" />
  </div>
  <div class="block text-grey-light mb-24">
    <h3 class="h1 mb-24 text-white">Message Sent Successfully</h3>
    <p>Your message has been delivered to the recipient. Thank you!</p>
  </div>
  <div class="text-center">
    <button mat-flat-button  mat-dialog-close class="mobile-full backto-listbtn">Back to List</button>
  </div>
</mat-dialog-content>