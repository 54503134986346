<app-header
  [isOpenSideBar]="isOpenSideBar"
  (openCloseSideMenu)="isOpenSideBar = !isOpenSideBar"
></app-header>
<div class="main-wrapper flex">
  <app-sidebar
    [isOpenSideBar]="isOpenSideBar"
    (openCloseSideMenu)="isOpenSideBar = false"
  ></app-sidebar>
  <div class="page-wrapper flex-grow-1" [ngClass]="{'pointer-none': load()}">
    @if (load()) {
      <mat-progress-bar mode="indeterminate" class="table-loader"></mat-progress-bar>
      }
    <router-outlet />
  </div>
</div>
