<div class="authentication-page flex flex-align-center flex-justify-center">
    @if(checkRouteExist){
    <button mat-flat-button class="btn-dark skip-btn" (click)="redirectToDashboard()">
        {{ "skip" | translate }}
    </button>
    }
    @if(step == 1){
    <div class="authentication-box">
        <ng-container *ngIf="profileCompleteStatus$ | async as profileStatus">
            <div class="auth-headerwrapper flex">
                <div class="authlogo-box">
                    <div class="auth-box flex flex-align-center flex-justify-center">
                        <img src="./assets/images/logo-small.svg" alt="MuyBi" />
                    </div>
                </div>
                <div class="auth-header">
                    <h1>{{ "sign_step_four.title" | translate }}</h1>
                    <p class="text-grey">
                        {{ "sign_step_four.sub_title" | translate }}
                    </p>
                </div>
            </div>
            <div class="auth-innerbox">
                <div class="primary-setupboxes">
                    <div class="setup-box flex flex-align-center">
                        <span class="flex-shrink-0 setup-ic">
                            <img src="./assets/images/icons/ic-identity.svg" alt="Verify your identity" />
                        </span>
                        <div class="flex-grow-1 block text-grey-medium fs-14 setup-textwrap">
                            <h3 class="text-grey-light mb-6">
                                {{ "sign_step_four.verify_your_identity" | translate }}
                            </h3>
                            <p>
                                {{ "sign_step_four.verify_your_identity_desc" | translate }}
                            </p>
                        </div>
                        <div class="flex-shrink-0 buttoninfo-box">
                            <!-- @if(profileStatus.data.is_identity_step_completed === '1'){
                      <span class="text-success complete-status fw-semibold">
                        {{ "complete" | translate }}
                      </span>
                      }@else {
                      <a mat-button class="link-btn" (click)="setCurrentStep(5)">{{
                        "edit" | translate
                        }}</a>
                      } -->
                            <a mat-button class="link-btn" (click)="redirectToSecondStep()">{{
                                "edit" | translate
                                }}</a>
                        </div>
                    </div>
                    <div class="setup-box flex flex-align-center">
                        <span class="flex-shrink-0 setup-ic">
                            <img src="./assets/images/icons/ic-bank.svg" alt="Link your bank accout" />
                        </span>
                        <div class="flex-grow-1 block text-grey-medium fs-14 setup-textwrap">
                            <h3 class="text-grey-light mb-6">
                                {{ "sign_step_four.link_your_bank_account" | translate }}
                            </h3>
                            <p>
                                {{ "sign_step_four.link_your_bank_account_desc" | translate }}
                            </p>
                        </div>
                        <div class="flex-shrink-0 buttoninfo-box">
                            <!-- @if(profileStatus.data.is_bank_account_step_completed === '1'){
                      <span class="text-success complete-status fw-semibold">
                        {{ "complete" | translate }}
                      </span>
                      }@else {
                      <a mat-button class="link-btn" (click)="setCurrentStep(5)">{{
                        "edit" | translate
                        }}</a>
                      } -->
                            <a mat-button class="link-btn" (click)="redirectToSecondStep()">{{
                                "edit" | translate
                                }}</a>
                        </div>
                    </div>
                    <div class="setup-box flex flex-align-center">
                        <span class="flex-shrink-0 setup-ic">
                            <img src="./assets/images/icons/ic-menu.svg" alt="Create Menu" />
                        </span>
                        <div class="flex-grow-1 block text-grey-medium fs-14 setup-textwrap">
                            <h3 class="text-grey-light mb-6">
                                {{ "sign_step_four.create_menu" | translate }}
                            </h3>
                            <p>{{ "sign_step_four.create_menu_desc" | translate }}</p>
                        </div>
                        <div class="flex-shrink-0 buttoninfo-box">
                            <!-- @if(profileStatus.data.is_create_menu_step_completed === '1'){
                      <span class="text-success complete-status fw-semibold">
                        {{ "complete" | translate }}
                      </span>
                      }@else {
                      <a mat-button class="link-btn" [routerLink]="['/dashboard']">{{
                        "edit" | translate
                        }}</a>
                      } -->
                            <a mat-button class="link-btn" (click)="redirectToMenu()">{{
                                "edit" | translate
                                }}</a>
                        </div>
                    </div>
                    <div class="setup-box flex flex-align-center">
                        <span class="flex-shrink-0 setup-ic">
                            <img src="./assets/images/icons/ic-workstation.svg" alt="Order Hardware" />
                        </span>
                        <div class="flex-grow-1 block text-grey-medium fs-14 setup-textwrap">
                            <h3 class="text-grey-light mb-6">
                                {{ "sign_step_four.order_hardware" | translate }}
                            </h3>
                            <p>{{ "sign_step_four.order_hardware_desc" | translate }}</p>
                        </div>
                        <div class="flex-shrink-0 buttoninfo-box">
                            <!-- @if(profileStatus.data.is_order_hardware_step_completed ===
                      '1'){
                      <span class="text-success complete-status fw-semibold">
                        {{ "complete" | translate }}
                      </span>
                      }@else {
                      <a mat-button class="link-btn" [routerLink]="['/dashboard']">{{
                        "edit" | translate
                        }}</a>
                      } -->
                            <a mat-button class="link-btn"
                                (click)="checkRouteExist?redirectToSetting():redirectToDashboard()">{{
                                "edit" | translate
                                }}</a>
                        </div>
                    </div>
                </div>

                @if(checkRouteExist){
                <div class="flex flex-justify-between gap-8px">
                    <button mat-flat-button class="w-full" (click)="redirectToSetting()">
                        {{ "back" | translate }}
                    </button>
                    <button mat-flat-button class="w-full" (click)="redirectToSetting()">
                        {{ "complete" | translate }}
                    </button>
                </div>
                }@else {
                <button mat-flat-button class="w-full" (click)="redirectToDashboard()">
                    {{ "skip" | translate }}
                </button>
                }


            </div>
        </ng-container>
    </div>
    }@else {
    <div class="authentication-box">
        <form [formGroup]="bankAccountForm">
            <div class="auth-headerwrapper flex">
                <div class="authlogo-box">
                    <div class="auth-box flex flex-align-center flex-justify-center">
                        <img src="./assets/images/logo-small.svg" alt="MuyBi" />
                    </div>
                </div>
                <div class="auth-header">
                    <h1>{{ "sign_step_five.title" | translate }}</h1>
                    <p class="text-grey">
                        {{ "sign_step_five.sub_title" | translate }}
                    </p>
                </div>
            </div>
            <div class="auth-innerbox">
                <div class="mb-20" [ngClass]="{ 'mat-form-field-invalid': fileTypeError }">
                    <label for="upload-image" class="form-label">
                        {{ "sign_step_five.upload_your_id_proof" | translate }}
                    </label>
                    <label for="upload-image"
                        class="form-control w-full flex flex-align-center cursor-pointer relative">
                        <span>{{ imageFileName }}</span>
                        <input type="file" class="form-control" id="upload-image" (change)="uploadImage($event)"
                            accept=".png, .jpg, .jpeg" formControlName="idProof" #fileInput hidden
                            (click)="imageCheck()" />
                        @if (!imageFileName) {
                        <span class="text-white file-text">
                            {{ "browse" | translate }}
                        </span>
                        }
                        <span class="icon-image-plus ic-input"></span>
                    </label>
                    <span class="mat-mdc-form-field-error">
                        {{ fileTypeError | translate }}
                    </span>
                </div>
                <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
                    <mat-label>
                        {{ "sign_step_five.bank_name" | translate }}
                    </mat-label>
                    <input matInput matNativeControl formControlName="bankName" />
                </mat-form-field>
                <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
                    <mat-label>
                        {{ "sign_step_five.routing_number" | translate }}
                    </mat-label>
                    <input matInput matNativeControl formControlName="routingNumber" allowNumberOnly />
                </mat-form-field>
                <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
                    <mat-label>
                        {{ "sign_step_five.account_number" | translate }}
                    </mat-label>
                    <input matInput matNativeControl formControlName="accountNumber" allowNumberOnly />
                </mat-form-field>
                <div class="grid-col2">
                    <mat-form-field subscriptSizing="dynamic" appearance="outline"
                        class="filter-location filterbox select-bgwrap small-select">
                        <mat-label>
                            {{ "sign_step_five.account_type" | translate }}
                        </mat-label>
                        <mat-select formControlName="accountType">
                            @for (type of bankAccountType; track type) {
                            <mat-option value="{{ type.value }}">
                                {{ type.translateLabel | translate }}
                            </mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
                        <mat-label>
                            {{ "sign_step_five.account_holder_name" | translate }}
                        </mat-label>
                        <input matInput matNativeControl formControlName="accountHolderName" />
                    </mat-form-field>
                </div>
                <div class="flex flex-justify-between gap-8px">
                    <button mat-flat-button class="w-full  mb-24" (click)="step=1;">
                        {{ "back" | translate }}
                    </button>
                    <button mat-flat-button class="w-full mb-24" type="submit" [disabled]="submitBankAccountDisabled()"
                        (click)="bankAccountFormSubmit(bankAccountForm.value)">
                        {{ "submit" | translate }}
                    </button>
                </div>
            </div>
        </form>
    </div>
    }
</div>