<!-- Starts Code For Create Order Drawer -->

<div class="create-orderwrap flex flex-column custom-scroll" [class.active]="isOpenOrder">
  <button class="flex-shrink-0 close-btn" mat-icon-button (click)="discardOrder('Discard')">
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.992" fill-rule="evenodd" clip-rule="evenodd"
        d="M7.29688 -0.015625C7.75522 -0.015625 8.21353 -0.015625 8.67188 -0.015625C11.2075 0.259457 13.2022 1.43654 14.6562 3.51562C15.4006 4.66028 15.8433 5.91028 15.9844 7.26562C15.9844 7.74478 15.9844 8.22397 15.9844 8.70312C15.7001 11.2258 14.523 13.2102 12.4531 14.6562C11.2993 15.4071 10.0389 15.8498 8.67188 15.9844C8.21353 15.9844 7.75522 15.9844 7.29688 15.9844C4.80813 15.7118 2.83416 14.566 1.375 12.5469C0.592031 11.3802 0.128491 10.0989 -0.015625 8.70312C-0.015625 8.22397 -0.015625 7.74478 -0.015625 7.26562C0.268627 4.74291 1.44571 2.75852 3.51562 1.3125C4.66941 0.561612 5.92981 0.118903 7.29688 -0.015625ZM4.98438 4.51562C5.221 4.47762 5.42934 4.53491 5.60938 4.6875C6.39625 5.47959 7.18794 6.26606 7.98438 7.04688C8.81294 6.2235 9.64628 5.40581 10.4844 4.59375C10.8816 4.42409 11.1889 4.52306 11.4062 4.89062C11.475 5.093 11.4645 5.29091 11.375 5.48438C10.5629 6.32247 9.74525 7.15581 8.92188 7.98438C9.74525 8.81294 10.5629 9.64628 11.375 10.4844C11.5447 10.8816 11.4457 11.1889 11.0781 11.4062C10.8321 11.4891 10.6029 11.4578 10.3906 11.3125C9.59331 10.51 8.79125 9.71309 7.98438 8.92188C7.15581 9.74525 6.32247 10.5629 5.48438 11.375C5.08716 11.5447 4.77987 11.4457 4.5625 11.0781C4.49378 10.8757 4.50422 10.6778 4.59375 10.4844C5.40581 9.64628 6.2235 8.81294 7.04688 7.98438C6.2235 7.15581 5.40581 6.32247 4.59375 5.48438C4.41631 5.03159 4.5465 4.70869 4.98438 4.51562Z"
        fill="#03A7F6" />
    </svg>
  </button>
  <div class="flex-shrink-0 mb-24 text-grey-medium fs-14 block">
    <h3 class="h1 mb-4 text-white">{{'create_order' | translate}}</h3>
  </div>
  <div class="flex flex-justify-between mb-24 block">
    <h3 class="mb-0 text-grey-light">{{'item_list' | translate}}</h3>
    <span class="icon-ic-trash flex-shrink-0 fs-20 cursor-pointer flex flex-align-center"
      (click)="discardOrder('Discard')"></span>
  </div>

  <div class="createorder-innerscroll custom-scroll flex-grow-1 flex flex-column">
    <div class="block flex-grow-1">

      <div class="block mb-24">
        @for (item of orderItemsData; track item; let ind = $index) {
        <div class="flex gap-20px mb-24">
          <div class="ordered-image">
            <img [src]="item.url" class="img-fit" [alt]="item.image_name" />
          </div>
          <div class="flex flex-justify-between gap-16px flex-grow-1 product-cartinfowrap">
            <div class="block text-grey-light fs-14 flex-grow-1 ordered-info">
              <h3 class=" mb-4 text-truncate">{{item.name}}</h3>
              <span class="text-grey text-truncate" [matTooltip]="item.desciption">{{item.desciption}}</span>
            </div>
            <div class="flex-shrink-0 text-end product-counterbx">
              <h3 class="text-primary fw-bold mb-12">${{(item.price * item?.quantity)}}</h3>
              <div class="product-updatewrap flex">
                <span class="flex-shrink-0 counterupdate-btn decrese-btn" (click)="itemQuantity(ind,'decrease')">
                  <svg width="12" height="2" viewBox="0 0 12 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.04999 1H10.95" stroke="white" stroke-width="1.2" stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </span>
                <input class="form-control counter-updater flex-shrink-0" [value]="item?.quantity" readonly />
                <span class="flex-shrink-0 counterupdate-btn increase-btn" (click)="itemQuantity(ind,'increase')">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.04999 8H12.95" stroke="white" stroke-width="1.2" stroke-linecap="round"
                      stroke-linejoin="round" />
                    <path d="M8 3.0498V12.9498" stroke="white" stroke-width="1.2" stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </span>
              </div>
            </div>
          </div>
        </div>
        }
      </div>
      <div class="mb-24 text-end">
        <a mat-button (click)="addNewProduct()" target="_blank" class="fw-normal">
          + {{'add_more_product' | translate}}
        </a>
      </div>

      <form [formGroup]="orderFormGroup">
        <div class="totalcount-wrapper mb-12">
          <div class="text-grey-medium fs-16 ordered-totalbx mb-12 flex flex-justify-between">
            <span>{{'subtotal' | translate}}</span>
            <span>${{orderFormGroup.value.subTotal > 0?orderFormGroup.value.subTotal:'00.00'}}</span>
          </div>
          <div class="text-grey-medium fs-16 ordered-totalbx mb-12 flex flex-justify-between">
            <span>{{'discount' | translate}}</span>
            <span>${{orderFormGroup.value.discount > 0?orderFormGroup.value.discount:'00.00'}}</span>
          </div>
          <div class="text-grey-medium fs-16 ordered-totalbx mb-12 flex flex-justify-between">
            <span>{{'tax' | translate}} ({{taxPer}}%)</span>
            <span>${{orderFormGroup.value.tax > 0?orderFormGroup.value.tax:'00.00'}}</span>
          </div>
          <div class="text-grey-medium fs-16 ordered-totalbx mb-12 flex flex-justify-between">
            <span>{{'tip' | translate}}</span>
            @if (orderFormGroup.value.tip && orderFormGroup.value.tip > 0) {
            <div class="flex gap-6px flex-align-center">
              <span (click)="addTip('Edit')" class="icon-edit-pencil cursor-pointer"></span>
              <span>${{orderFormGroup.value.tip}}</span>
            </div>
            } @else{
            <span matTooltip="Add Tip" class="text-gredient fs-16 cursor-pointer" aria-label="Add Tip"
              (click)="addTip('Add')">{{'add_tip' | translate}}</span>
            }
          </div>
          <div class="text-grey-medium fs-16 ordered-totalbx mb-12 flex flex-justify-between">
            <span>{{'additional_amount' | translate}}</span>
            <span>${{orderFormGroup.value.additionalAmount > 0?orderFormGroup.value.additionalAmount:'00.00'}}</span>
          </div>
        </div>
        <div class="text-grey-medium fs-16 ordered-totalbx mb-12 flex flex-justify-between mb-24">
          <span class="h3 text-grey-light fw-bold">{{'total' | translate}}</span>
          <span class="text-primary h3 fw-bold">
            ${{orderFormGroup.value.grandTotal > 0 ? orderFormGroup.value.grandTotal:'00.00'}} </span>
        </div>

        <div class="gridcontainer-2">
          <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-20">
            <mat-label>{{'customer_first_name' | translate}}</mat-label>
            <input matInput formControlName="firstName">
          </mat-form-field>
          <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-20">
            <mat-label>{{'customer_last_name' | translate}}</mat-label>
            <input matInput formControlName="lastName">
          </mat-form-field>
          <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-20">
            <mat-label>{{'customer_phone_number' | translate}}</mat-label>
            <input matInput formControlName="phoneNumber">
          </mat-form-field>
          <mat-form-field appearance="outline" subscriptSizing="dynamic" class="discount-code">
            <mat-label>{{'discount_code' | translate}}</mat-label>
            <div class="relative w-full">
              <input matInput formControlName="discountCode" appTrim appUppercaseAlphaNumeric>
              @if (appliedCoupon) {
              <button mat-flat-button class="apply-btn input-btn pointer-event" type="button"
                (click)="removeCouponCode()">{{'remove' | translate}}</button>
              }@else {
              <button mat-flat-button class="apply-btn input-btn pointer-event" type="button"
                [disabled]="!orderFormGroup.value.discountCode" (click)="applyCouponCode()">{{'apply' | translate}}</button>
              }
            </div>
          </mat-form-field>
        </div>
        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-20">
          <mat-label>{{'additional_amount_if_any' | translate}}</mat-label>
          <input matInput formControlName="additionalAmount" allowFlotNumberOnly>
        </mat-form-field>
      </form>
    </div>

    <div class="flex gap-16px order-btns flex-wrap">
      <button mat-flat-button class="btn-outline-secondary" (click)="discardOrder('Discard')">{{'discard' | translate}}</button>
      @if (orderFormGroup.value.grandTotal > 0) {
      <button mat-flat-button class="btn-primary sendlink-btn" (click)="createOrder(1)">
        {{'send_payment_link' | translate}}</button>
      <button mat-flat-button (click)="createOrder(2)">{{'proceed_to_pay' | translate}}</button>
      }@else {
      <button mat-flat-button (click)="createOrder(3)">{{'place_order' | translate}}</button>
      }
    </div>
  </div>

</div>
<div class="overlay-wrapper "></div>