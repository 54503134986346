import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseMessage } from '../../../../model/interfaces/req.res.interface';
import { param } from 'jquery';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  constructor(private http: HttpClient) { }

  createInvoice(data: any): Observable<ResponseMessage> {
    return this.http.post<ResponseMessage>('invoice-create', data)
  }
  getInvoiceId(id: any): Observable<ResponseMessage> {
    return this.http.get<ResponseMessage>(`get-invoice-id/${id}`)
  }
  getInvoiceList(data: any): Observable<ResponseMessage> {
    let params = new HttpParams();
    params = params.append('page', data.page);
    params = params.append('per_page', data.per_page);
    params = params.append('search', data.search);
    params = params.append('user_id', data.user_id);
    params = params.append('item_id', data.item_id);
    params = params.append('sort_by', data.sort_by);
    params = params.append('from_date', data.from_date);
    params = params.append('to_date', data.to_date);
    params = params.append('user_business_location_id',data.user_business_location_id);
    return this.http.get<ResponseMessage>('invoice-list', { params: params })
  }
  getVendorList():Observable<ResponseMessage>{
    return this.http.get<ResponseMessage>('get-business-branch-user');
  }
  getProductList():Observable<ResponseMessage>{
    return this.http.get<ResponseMessage>('get-items');
  }
  applyCoupon(data:any):Observable<ResponseMessage>{
    return this.http.post<ResponseMessage>('apply-coupon',data);
  }
  getInvoiceDetail(id:any):Observable<ResponseMessage>{
    return this.http.get<ResponseMessage>(`invoice-details/${id}`);
  }
}
