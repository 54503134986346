import { Component, Inject, inject, OnInit, Optional } from '@angular/core';
import { EncryptionService } from '../../../../../shared/services/encryption.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { ConstantService } from '../../../../../shared/services/constant.service';
import { TransactionsService } from '../Service/transactions.service';
import { catchError, Subscription, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ResponseMessage } from '../../../../../model/interfaces/req.res.interface';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule, MatIconButton } from '@angular/material/button';
import { DateTime24HrPipe } from '../../../../../shared/pipes/date-time-24hr.pipe';
import { PaymentMethodTypePipe } from '../../../../../shared/pipes/payment-method-type.pipe';
import { DatePipe } from '@angular/common';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  { position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H' },
  { position: 2, name: 'Helium', weight: 4.0026, symbol: 'He' },
  { position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li' },
  { position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be' },
];


@Component({
  selector: 'app-view-transaction-detail',
  templateUrl: './view-transaction-detail.component.html',
  styleUrls: ['./view-transaction-detail.component.scss'],
  standalone: true,
  providers: [EncryptionService, DatePipe],
  imports: [
    MatDialogContent,
    MatTableModule,
    MatButtonModule,
    MatIconButton,
    DateTime24HrPipe,
    PaymentMethodTypePipe
  ]
})
export class ViewTransactionDetailComponent implements OnInit {
  displayedColumns: string[] = ['no', 'price', 'quantity', 'total'];
  dataSource = ELEMENT_DATA;

  transactionDetail: any;
  localData: any
  subscribeAPI!: Subscription;
  private transactionsService = inject(TransactionsService);
  private toastr = inject(ToastrService);
  private encDecObj = inject(EncryptionService);
  public constantService = inject(ConstantService);
  private dialog = inject(MatDialog);
  private dialogRef = inject(MatDialogRef<ViewTransactionDetailComponent>);
  getSelectedTransctionDetail:any;
  commaSeperatedPaymentMethod:any;
  total:any=0;
  selectedTheme = this.constantService.themeType;
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: ViewTransactionDetailComponent) {
    this.localData = { ...data };
  }


  ngOnInit() {
    this.getTransactionDetail(this.localData.orderId);
  }

  getTransactionDetail(id: number) {
    if (this.subscribeAPI) this.subscribeAPI.unsubscribe();
    this.constantService.progressLoader.set(true);
    this.subscribeAPI = this.transactionsService.getOrderTransactionDetail(id).pipe(
      catchError((error) => {
        this.constantService.progressLoader.set(false);
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message);
      })
    ).subscribe((res: ResponseMessage) => {
      this.constantService.progressLoader.set(false);
      if (res.code === 200) {
        this.transactionDetail = res.data;
        this.getSelectedTransctionDetail = this.transactionDetail.transactions.data.filter((transaction:any)=>{return transaction.id==this.localData.transactionId})[0]
        this.commaSeperatedPaymentMethod = this.transactionDetail.transactions.data.map((transaction:any)=>{ return transaction.payment_method==1?'Cash':transaction.payment_method==2?'Card':'Gift Card'}).filter((res:any)=>res!='Gift Card').join(',');
        console.log(this.commaSeperatedPaymentMethod)
        console.log(this.getSelectedTransctionDetail)
        // const sub_total = this.transactionDetail?.sub_total?+this.transactionDetail?.sub_total:0;
        // const tax = this.transactionDetail?.tax?+this.transactionDetail?.tax:0;
        // const processing_fee = this.transactionDetail?.processing_fee?+this.transactionDetail?.processing_fee:0;
        // const shipping_fee = this.transactionDetail?.shipping_fee?+this.transactionDetail?.shipping_fee:0;
        // const late_fee = this.transactionDetail?.late_fee?+this.transactionDetail?.late_fee:0;
        // const tip = this.transactionDetail?.tip?+this.transactionDetail?.tip:0;
        // const additional_amount = this.transactionDetail?.additional_amount?+this.transactionDetail?.additional_amount:0;
        // const discount = this.transactionDetail?.discount?+this.transactionDetail?.discount:0;
        // const gift_card = this.transactionDetail?.giftcard?.value?+this.transactionDetail?.giftcard?.value:0;
        // this.total = (sub_total+tax+processing_fee+shipping_fee+late_fee+tip+additional_amount-discount-gift_card).toFixed(2);
        // this.total = +this.total>=0?this.total:0;
        // Added By Vaibhav Ved Not to remove for now
      } else {
        this.toastr.error(res.message);
      }
    });
  }
  close() {
    this.dialogRef.close();
  }
  ngOnDestroy(): void {
    if (this.subscribeAPI) this.subscribeAPI.unsubscribe();
  }
}


