<div class="section-box mb-24 flex flex-wrap flex-align-center gap-16px header-bx mb-25 backbtn-wrap">
  <h1 class="text-white mb-0 text-truncate">Edit Menu</h1>
  <button  routerLink="/food-menu" mat-flat-button class="btn-outline-secondary backbtn"><svg width="7" height="15" viewBox="0 0 7 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.2876 13.4386L1.3976 8.54859C0.820097 7.97109 0.820097 7.02609 1.3976 6.44859L6.2876 1.55859" stroke="currentColor" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/></svg>Back</button>
  <button mat-flat-button class="createmenu-btn btn-lg mobile-full">Create Menu</button>
</div>

<form  class="form-box section-box">
  <div class="mb-24">
    <div class="mb-20">
      <label id="example-radio-group-label" class="text-grey-medium d-block mb-10 fs-14">Item For</label>
      <mat-radio-group aria-label="Select an option">
        <mat-radio-button value="option1">Restaurant</mat-radio-button>
        <mat-radio-button value="option2">Bar</mat-radio-button>
      </mat-radio-group>
    </div>
    
    <div class="mb-20">
      <label id="example-radio-group-label" class="text-grey-medium d-block mb-10 fs-14">Menu Type</label>
      <mat-radio-group aria-label="Select an option">
        <mat-radio-button value="option3">Regular</mat-radio-button>
        <mat-radio-button value="option4">Combo</mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="menu-grid3 mb-20">
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-label class="fs-14">Menu Title</mat-label>
        <input matInput />
      </mat-form-field>
      <mat-form-field appearance="outline" subscriptSizing="dynamic" class="small-select">
        <mat-label class="fs-14">Branch Location</mat-label>
        <mat-select >
          <mat-option value="option1">Option 1</mat-option>
          <mat-option value="option2">Option 2</mat-option>
          <mat-option value="option3">Option 3</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-label class="fs-14">Code</mat-label>
        <input matInput type="number" />
      </mat-form-field>
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-label class="fs-14">Menu Category</mat-label>
        <input matInput />
      </mat-form-field>
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-label class="fs-14">Stock Detail</mat-label>
        <input matInput />
      </mat-form-field>
     <div>
        <label id="example-radio-group-label" class="text-grey-medium d-block mb-10 fs-14">Enable to Sale</label>
        <mat-radio-group aria-label="Select an option">
          <mat-radio-button value="option5">Yes</mat-radio-button>
          <mat-radio-button value="option6">No</mat-radio-button>
        </mat-radio-group>
     </div>
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-label class="fs-14">Menu Description</mat-label>
        <input matInput />
      </mat-form-field>
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-label class="fs-14">Sale Price for Dine in</mat-label>
        <input matInput />
      </mat-form-field>
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-label class="fs-14">Sale Price for Delivery</mat-label>
        <input matInput />
      </mat-form-field>
    </div>
    <div>
      <label id="example-radio-group-label" class="text-grey-medium d-block mb-10 fs-14">Food Type</label>
      <mat-radio-group aria-label="Select an option">
        <mat-radio-button value="option7">Veg</mat-radio-button>
        <mat-radio-button value="option8">Non-veg</mat-radio-button>
        <mat-radio-button value="option9">Other</mat-radio-button>
      </mat-radio-group>
    </div>

  </div>
  <div class="gap-20px flex flex-justify-end footer-btns">
    <button mat-flat-button class="btn-danger btn-lg half-btn" (click)="openDialog()">Delete Menu</button>
    <button mat-flat-button class="btn-lg half-btn">Update Menu</button>
  </div>
</form>