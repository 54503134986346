import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[allowNumberOnly]',
    standalone: true
})
export class NumberOnlyDirective {

    constructor(private el: ElementRef) { }

    @HostListener('input', ['$event'])
    onInputChange(event: Event): void {
        const input = event.target as HTMLInputElement;
        const filteredValue = input.value.replace(/[^0-9]/g, '');

        if (input.value !== filteredValue) {
            input.value = filteredValue;

            // Dispatching a new 'input' event to update the model
            const event = new Event('input', { bubbles: true });
            input.dispatchEvent(event);
        }
    }
}
