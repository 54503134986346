import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { RouterModule } from '@angular/router';
import {  paymentViaOption } from '../../../../model/const/common.const';
import { TranslateModule } from '@ngx-translate/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ConstantService } from '../../../../shared/services/constant.service';
import { ToastrService } from 'ngx-toastr';
import { catchError, Subscription, throwError } from 'rxjs';
import { PaymentService } from '../payment.service';
import { ResponseMessage } from '../../../../model/interfaces/req.res.interface';
import { emailValidator } from '../../../../shared/validators/email-validator.directive';
import { numberValidator } from '../../../../shared/validators/number-validator.directive';

@Component({
  selector: 'app-send-payment-link',
  standalone: true,
  imports: [
    RouterModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    TranslateModule,
    ReactiveFormsModule
  ],
  templateUrl: './send-payment-link.component.html',
  styleUrl: './send-payment-link.component.scss'
})
export class SendPaymentLinkComponent {
  paymentOptions = paymentViaOption;
  paymentFormGroup!: FormGroup;
  subscribeAPI!: Subscription;
  public paymentService = inject(PaymentService);
  public constantService = inject(ConstantService);
  private dialog = inject(MatDialog);
  private fb = inject(FormBuilder);
  private toastr = inject(ToastrService);

  initialPaymentForm() {
    this.paymentFormGroup = this.fb.group({
      selectedPaymentOption: [1, Validators.required],
      email: [''],
      phoneNumber: [''],
    })
  }

  constructor() {

  }

  ngOnInit(): void {
    this.initialPaymentForm();
    this.setDynamicValidation(this.paymentFormGroup.value.selectedPaymentOption)
  }

  // 1= Email , 2= Phone Number
  setDynamicValidation(type: number) {
    const phoneNumberControl = this.paymentFormGroup.controls['phoneNumber'];
    const emailControl = this.paymentFormGroup.controls['email'];

    if (type === 1) {
      phoneNumberControl.setValue('');
      phoneNumberControl.clearValidators();
      emailControl.setValidators([Validators.required, emailValidator()]);
    } else if (type === 2) {
      emailControl.setValue('');
      emailControl.clearValidators();
      phoneNumberControl.setValidators([Validators.required, numberValidator()]);
    }

    phoneNumberControl.updateValueAndValidity();
    emailControl.updateValueAndValidity();
  }

  paymentLink() {
    this.paymentFormGroup.markAllAsTouched();
    if (this.paymentFormGroup.invalid) return;
    let obj = {
      type: this.paymentFormGroup.value.selectedPaymentOption,
      email_phone: this.paymentFormGroup.value.selectedPaymentOption == 1 ?
        this.paymentFormGroup.value.email : this.paymentFormGroup.value.phoneNumber,
    }
    if (this.subscribeAPI) this.subscribeAPI.unsubscribe();
    // this.subscribeAPI = this.paymentService.sendPaymentLink(obj).pipe(
    //   catchError((error) => {
    //     this.constantService.progressLoader.set(false);
    //     this.toastr.error(error.error.message);
    //     return throwError(() => error.error.message)
    //   })).subscribe((res: ResponseMessage) => {
    //     this.constantService.progressLoader.set(false);
    //     if (res.code === 200) {
    //       this.toastr.success(res.message);
    //     } else {
    //       this.toastr.error(res.message);
    //     }
    //   })
  }

  ngOnDestroy(): void {
    if (this.subscribeAPI) this.subscribeAPI.unsubscribe();
  }
}
