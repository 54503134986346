<div class="section-box mb-24 flex flex-wrap flex-align-center gap-16px header-bx mb-25">
  <h1 class="text-white mb-0 ">Inventory Management</h1>
  <div class="flex filter-wrapper flex-align-center flex-grow-1 flex-justify-end filter-section flex-wrap">
    <mat-form-field subscriptSizing="dynamic" class="select-category select-dark">
      <mat-select placeholder="Category">
        <mat-option value="option1">Option 1</mat-option>
        <mat-option value="option2">Option 2</mat-option>
        <mat-option value="option3">Option 3</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field subscriptSizing="dynamic" class="select-branchlocation select-dark">
      <mat-select placeholder="Branch Location">
        <mat-option value="option1">Option 1</mat-option>
        <mat-option value="option2">Option 2</mat-option>
        <mat-option value="option3">Option 3</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field subscriptSizing="dynamic" class="select-inventorystatus select-dark">
      <mat-select placeholder="Inventory Status">
        <mat-option value="option1">Option 1</mat-option>
        <mat-option value="option2">Option 2</mat-option>
        <mat-option value="option3">Option 3</mat-option>
      </mat-select>
    </mat-form-field>
  
  </div>
</div>

<div>
  <div class="flex flex-justify-between flex-wrap gap-8px mb-16 flex-align-center section-box">
    <h1 class="mb-0 mobile-full h3">Items</h1>
    <div class="flex gap-16px flex-grow-1 flex-justify-end flex-wrap items-box">
      <mat-form-field appearance="outline" subscriptSizing="dynamic" class="small-input search-inputbx input-lg">
        <div class="w-full relative input-rightspace">
          <input type="search" class="form-control" matInput placeholder="Search Item"  />
          <mat-icon class="ic-input cursor-pointer">search</mat-icon>
        </div>
      </mat-form-field>
      <button mat-flat-button class="flex-shrink-0 half-btn btn-lg" routerLink="/inventory-list/add">Add Item</button>
      <button mat-flat-button class="flex-shrink-0 half-btn btn-lg" routerLink="/food-menu/edit">Create Menu</button>
    </div>
  </div>
  <div class="table-responsive custom-scroll table-wrapper">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
  
      <!-- ITEM NAME Column -->
      <ng-container matColumnDef="itemname">
        <th mat-header-cell *matHeaderCellDef class="item-namecol"> ITEM NAME </th>
        <td mat-cell *matCellDef="let element" class="nowrap">
          <div [innerHTML]="element.itemname" class="item-infobox"></div>
        </td>
      </ng-container>
    
      <!-- CATEGORY Column -->
      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef> CATEGORY </th>
        <td mat-cell *matCellDef="let element"> {{element.category}} </td>
      </ng-container>
    
      <!-- BRANCH LOCATION Column -->
      <ng-container matColumnDef="branchLocation">
        <th mat-header-cell *matHeaderCellDef> BRANCH LOCATION </th>
        <td mat-cell *matCellDef="let element"> {{element.branchLocation}} </td>
      </ng-container>
    
      <!-- STOCK Column -->
      <ng-container matColumnDef="stock">
        <th mat-header-cell *matHeaderCellDef> STOCK </th>
        <td mat-cell *matCellDef="let element"> {{element.stock}} </td>
      </ng-container>
  
       <!-- AVAILABLE TO SELL Column -->
      <ng-container matColumnDef="availability">
        <th mat-header-cell *matHeaderCellDef> AVAILABLE TO SELL </th>
        <td mat-cell *matCellDef="let element" [innerHTML]="element.availability"></td>
      </ng-container>
  
       <!-- PRICE Column -->
      <ng-container matColumnDef="price">
        <th mat-header-cell *matHeaderCellDef>PRICE</th>
        <td mat-cell *matCellDef="let element">${{element.price}} </td>
      </ng-container>
  
      <!-- ACTION Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="action-col text-center">ACTION</th>
        <td mat-cell *matCellDef="let element">
          <div class="flex table-actionbtn flex-justify-center">
              <button  mat-icon-button aria-label="View" matTooltip="View"><span class="icon-eye"></span></button>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  
</div>