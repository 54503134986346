<!-- Starts Header -->
<div class="section-box mb-24 flex flex-wrap flex-justify-between flex-align-center header-section gap-8px header-box">
  <h1 class="text-white mb-0 text-truncate">Billing</h1>
</div>

<div class="billing-grid2 flex-align-start">

  <!-- Starts Added Product Billing Section -->
  <div class="section-box">
    <form class="block">
      <div class="mb-16 formgrid-2">
        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
          <mat-label>Enter Table Name</mat-label>
          <input matInput>
        </mat-form-field> 
        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
          <mat-label>Enter Customer Name</mat-label>
          <input matInput>
        </mat-form-field> 
      </div>
      <div class="billinginfo-box grid-2 w-full mb-24">
          <div class="gap-12px w-full fs-13 block mb-12">
            <span class="text-block mb-4 d-block text-grey-light">Recipent</span>
            <h5 class="text-grey-medium fs-14 fw-bold">Akhmad Maariz</h5>
          </div>
          <div class="gap-12px w-full fs-13 block mb-12">
            <span class="text-block mb-4 d-block text-grey-light">Customer Number</span>
            <h5 class="text-grey-medium fs-14 fw-bold">(316) 555-0116</h5>
          </div>
          <div class="gap-12px w-full fs-13 block mb-12">
            <span class="text-block mb-4 d-block text-grey-light">Transaction ID</span>
            <h5 class="text-grey-medium fs-14 fw-bold">#231128001</h5>
          </div>
          <div class="gap-12px w-full fs-13 block mb-12">
            <span class="text-block mb-4 d-block text-grey-light">Table Number</span>
            <h5 class="text-grey-medium fs-14 fw-bold">19</h5>
          </div>
      </div>
      <div class="block mb-24">
        <div class="flex gap-20px mb-24 flex-align-center">
          <div class="ordered-image">
            <img src="https://images.pexels.com/photos/842571/pexels-photo-842571.jpeg?auto=compress&cs=tinysrgb&w=600" class="img-fit" alt="American Style Burger" />
          </div>
          <div class="flex flex-justify-between gap-16px flex-grow-1">
            <div class="block text-grey-light fs-14 flex-grow-1 ordered-info">
              <h3 class=" mb-4 text-truncate fw-medium">American Style Burger</h3>
              <span class="text-grey text-truncate">Lorem ipsum dolor sit amet.</span>
            </div>
            <div class="flex-shrink-0 text-end">
              <h3 class="text-primary fw-bold mb-10">$27.09</h3>
              <div class="product-updatewrap flex">
                <span class="flex-shrink-0 counterupdate-btn decrese-btn">
                  <svg width="12" height="2" viewBox="0 0 12 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.04999 1H10.95" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </span>
                <input class="form-control counter-updater flex-shrink-0" value="1" />
                <span class="flex-shrink-0 counterupdate-btn increase-btn">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.04999 8H12.95" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M8 3.0498V12.9498" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>  
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="totalcount-wrapper mb-12">
        <div class="text-grey-medium fs-16 ordered-totalbx mb-12 flex flex-justify-between">
          <span>Total Guest</span>
          <span>4</span>
        </div>
        <div class="text-grey-medium fs-16 ordered-totalbx mb-12 flex flex-justify-between">
          <span>Subtotal</span>
          <span>$54.18</span>
        </div>
        <div class="text-grey-medium fs-16 ordered-totalbx mb-12 flex flex-justify-between">
          <span>Discount</span>
          <span>$00.00</span>
        </div>
        <div class="text-grey-medium fs-16 ordered-totalbx mb-12 flex flex-justify-between">
          <span>Tax</span>
          <span>$1.99</span>
        </div>
        <div class="text-grey-medium fs-16 ordered-totalbx mb-12 flex flex-justify-between">
          <span>Tip</span>
          <span matTooltip="Add Tip" class="text-gredient fs-16 cursor-pointer" aria-label="Add Tip">Add Tip</span>
        </div>
      </div>

      <div class="text-grey-medium fs-16 ordered-totalbx mb-12 flex flex-justify-between mb-24">
        <span class="h3 text-grey-light fw-bold">Total</span>
        <span class="text-primary h3 fw-bold">$55.17</span>
      </div>

      <div class="formgrid-3">
        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="discount-code">
          <mat-label>Discount Code</mat-label>
          <div class="relative w-full">
            <input matInput>
            <button mat-flat-button class="apply-btn input-btn">Apply</button>
          </div>
        </mat-form-field>
        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="amount-input">
          <mat-label>Additional Amount (if any)</mat-label>
          <div class="relative w-full">
            <input matInput>
            <button mat-flat-button class="add-btn input-btn">Add</button>
          </div>
        </mat-form-field>
        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="amount-input">
          <mat-label>Add Tip</mat-label>
          <div class="relative w-full">
            <input matInput>
            <button mat-flat-button class="add-btn input-btn">Add</button>
          </div>
        </mat-form-field>
      </div>

    </form>
  </div>
  <!-- Ends Added Product Billing Section -->

  <!-- Starts Choose Payment Method Section -->
  <div class="section-box payment-methodbox">
    <div class="text-grey-medium paymentmethod-header fs-12">
      <h5 class="mb-4 text-grey-light d-block">Choose Payment Method</h5>
      <span class="">Select a payment method below. Please double check before finishing your payment.</span>
    </div>
    <mat-accordion>
      <mat-expansion-panel expanded (opened)="panelOpenState.set(true)" (closed)="panelOpenState.set(false)">
        <mat-expansion-panel-header>
          <mat-panel-title class="">
            <h5 class="text-grey-light mb-0">Credit/Debit Card</h5>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div>
          <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
            <mat-label>Enter Name on Card</mat-label>
            <input matInput>
          </mat-form-field> 
          <div>
            <label class="form-label">Card Number</label>
            <div class="cardgrid-4">
              <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
                <input matInput>
              </mat-form-field> 
              <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
                <input matInput>
              </mat-form-field> 
              <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
                <input matInput>
              </mat-form-field> 
              <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
                <input matInput>
              </mat-form-field> 
            </div>
          </div>
          <div class="cardgrid-2">
            <div class="">
              <label class="form-label">Expiry Date</label>
              <div class="dategrid-2">
                <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
                  <input matInput placeholder="MM" type="number">
                </mat-form-field> 
                <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
                  <input matInput placeholder="YY" type="number">
                </mat-form-field> 
              </div>
            </div>
            <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
              <mat-label>CVV</mat-label>
              <div class="w-full relative input-rightspace">
                <input matInput placeholder="---" type="number">
                <span class="ic-input cursor-pointer" matTooltip="CVV" >
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 0C4.02571 0 0 4.02525 0 9C0 13.9742 4.02525 18 9 18C13.9743 18 18 13.9747 18 9C18 4.02571 13.9747 0 9 0ZM8.73369 13.2124C8.22428 13.2124 7.83334 12.7859 7.83334 12.3002C7.83334 11.8026 8.23612 11.388 8.73369 11.388C9.23129 11.388 9.64586 11.8026 9.64586 12.3002C9.64586 12.7859 9.24307 13.2124 8.73369 13.2124ZM10.0842 8.604C9.43263 9.11341 9.42075 9.46881 9.42075 10.0848C9.42075 10.31 9.30227 10.5706 8.72181 10.5706C8.23605 10.5706 8.07026 10.3929 8.07026 9.77685C8.07026 8.75802 8.52043 8.2723 8.86398 7.97614C9.25492 7.64441 9.91835 7.2772 9.91835 6.6375C9.91835 6.09251 9.44448 5.83189 8.85213 5.83189C7.64378 5.83189 7.90443 6.74413 7.26465 6.74413C6.9448 6.74413 6.55386 6.53084 6.55386 6.06885C6.55386 5.42914 7.28835 4.48137 8.88768 4.48137C10.404 4.48137 11.411 5.32252 11.411 6.43609C11.411 7.54966 10.404 8.35523 10.0842 8.604Z" fill="#989898"/>
                    </svg>      
                </span>
              </div>
            </mat-form-field> 
          </div>

          
        
        </div>
      </mat-expansion-panel>
    </mat-accordion>

    <div class="card-bottombtns block">
      <div class="btns-box btns2-box flex-wrap mb-20">
        <button mat-flat-button class="btn-outline-primary">Gift Card</button>
        <button mat-flat-button class="btn-outline-primary">Cash</button>
      </div>
      <div class="btns-box btns3-box flex-wrap">
        <button mat-flat-button class="btn-outline-primary" (click)="openDialog()">Split Payment</button>
        <button mat-flat-button class="btn-outline-primary" (click)="sendPaymentLinkDialog()">Send Payment Link</button>
        <button mat-flat-button class="">Proceed to Pay</button>
      </div>
    </div>
    
  </div>
  <!-- Ends Choose Payment Method Section -->

</div>