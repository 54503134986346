<mat-dialog-content class="text-center">
  <div class="block text-grey-medium mb-24 modal-header">
    <h3 class="h1 mb-12 text-grey-light">QR Code Generated</h3>
    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum </p>
  </div>
  <div class="qr-codebx">
    <img src="assets/images/scanner.png" alt="QR Code" />
  </div>
  <div class="btn-box text-center">
    <button mat-flat-button mat-dialog-close class="btn-outline-primary">Cancel</button>
    <button mat-flat-button mat-dialog-close>Download QR Code</button>
  </div>
</mat-dialog-content>