import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from '../../../shared/components/common/header/header.component';
import { SidebarComponent } from '../../../shared/components/common/sidebar/sidebar.component';
import { ViewProductComponent } from '../../../shared/components/dialog/view-product/view-product.component';

export interface PeriodicElement {
  itemname: any;
  category: string;
  branchLocation: string;
  stock: number;
  availability: string,
  price: number
}

const ELEMENT_DATA: PeriodicElement[] = [
  {
    itemname:
      '<span class="item-image"><img src="https://images.pexels.com/photos/1337825/pexels-photo-1337825.jpeg?auto=compress&cs=tinysrgb&w=600" alt="Watermelon Mint Cooler" class="img-fit" /></span><span class="item-name">Watermelon Mint Cooler</span>',
    category: 'Food',
    branchLocation: 'New York',
    stock: 150,
    availability: '<span class="availabletxt">Available</span>',
    price: 25.29
  },
  {
    itemname:
      '<span class="item-image"><img src="https://images.pexels.com/photos/376464/pexels-photo-376464.jpeg?auto=compress&cs=tinysrgb&w=600" alt="Balinese Betutu Duck" class="img-fit" /></span><span class="item-name">Balinese Betutu Duck</span>',
    category: 'Food',
    branchLocation: 'New York',
    stock: 150,
    availability: '<span class="text-grey-dark not-availabletxt">Not Available</span>',
    price: 25.29
  },
  {
    itemname:
      '<span class="item-image"><img src="https://images.pexels.com/photos/70497/pexels-photo-70497.jpeg?auto=compress&cs=tinysrgb&w=600" alt="Spaghetti Bolognese" class="img-fit" /></span><span class="item-name">Spaghetti Bolognese</span>',
    category: 'Food',
    branchLocation: 'New York',
    stock: 150,
    availability: '<span class="availabletxt">Available</span>',
    price: 25.29
  },
  {
    itemname:
      '<span class="item-image"><img src="https://images.pexels.com/photos/70497/pexels-photo-70497.jpeg?auto=compress&cs=tinysrgb&w=600" alt="Gourmet Wagyu Burger" class="img-fit" /></span><span class="item-name">Gourmet Wagyu Burger</span>',
    category: 'Food',
    branchLocation: 'New York',
    stock: 150,
    availability: '<span class="text-grey-dark not-availabletxt">Not Available</span>',
    price: 25.29
  },
  {
    itemname:
      '<span class="item-image"><img src="https://images.pexels.com/photos/26842849/pexels-photo-26842849/free-photo-of-mango-slices-with-syrup-in-cup.jpeg?auto=compress&cs=tinysrgb&w=100" alt="Mango Tango Smoothie" class="img-fit" /></span><span class="item-name">Mango Tango Smoothie</span>',
    category: 'Food',
    branchLocation: 'New York',
    stock: 150,
    availability: '<span class="availabletxt">Available</span>',
    price: 25.29
  },
  {
    itemname:
      '<span class="item-image"><img src="https://images.pexels.com/photos/1337825/pexels-photo-1337825.jpeg?auto=compress&cs=tinysrgb&w=600" alt="Watermelon Mint Cooler" class="img-fit" /></span><span class="item-name">Watermelon Mint Cooler</span>',
    category: 'Food',
    branchLocation: 'New York',
    stock: 150,
    availability: '<span class="availabletxt">Available</span>',
    price: 25.29
  },
  {
    itemname:
      '<span class="item-image"><img src="https://images.pexels.com/photos/376464/pexels-photo-376464.jpeg?auto=compress&cs=tinysrgb&w=600" alt="Balinese Betutu Duck" class="img-fit" /></span><span class="item-name">Balinese Betutu Duck</span>',
    category: 'Food',
    branchLocation: 'New York',
    stock: 150,
    availability: '<span class="text-grey-dark not-availabletxt">Not Available</span>',
    price: 25.29
  },
  {
    itemname:
      '<span class="item-image"><img src="https://images.pexels.com/photos/70497/pexels-photo-70497.jpeg?auto=compress&cs=tinysrgb&w=600" alt="Spaghetti Bolognese" class="img-fit" /></span><span class="item-name">Spaghetti Bolognese</span>',
    category: 'Food',
    branchLocation: 'New York',
    stock: 150,
    availability: '<span class="availabletxt">Available</span>',
    price: 25.29
  },
  {
    itemname:
      '<span class="item-image"><img src="https://images.pexels.com/photos/70497/pexels-photo-70497.jpeg?auto=compress&cs=tinysrgb&w=600" alt="Gourmet Wagyu Burger" class="img-fit" /></span><span class="item-name">Gourmet Wagyu Burger</span>',
    category: 'Food',
    branchLocation: 'New York',
    stock: 150,
    availability: '<span class="text-grey-dark not-availabletxt">Not Available</span>',
    price: 25.29
  },
  {
    itemname:
      '<span class="item-image"><img src="https://images.pexels.com/photos/26842849/pexels-photo-26842849/free-photo-of-mango-slices-with-syrup-in-cup.jpeg?auto=compress&cs=tinysrgb&w=100" alt="Mango Tango Smoothie" class="img-fit" /></span><span class="item-name">Mango Tango Smoothie</span>',
    category: 'Food',
    branchLocation: 'New York',
    stock: 150,
    availability: '<span class="availabletxt">Available</span>',
    price: 25.29
  },
];
@Component({
  selector: 'app-list',
  standalone: true,
  imports: [
    HeaderComponent,
    SidebarComponent,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTableModule,
    MatTooltipModule,
    MatDialogModule,
    RouterModule
  ],
  templateUrl: './list.component.html',
  styleUrl: './list.component.scss'
})
export class ListComponent {
  displayedColumns: string[] = ['itemname','category',  'branchLocation', 'stock', 'availability', 'price', 'action'];
  dataSource = ELEMENT_DATA;

  readonly dialog = inject(MatDialog);
  openDialog() {
    const dialogRef = this.dialog.open(ViewProductComponent, {
      width: '564px',
      panelClass: ['modal-wrapper'],
      scrollStrategy: new NoopScrollStrategy()
    });
  }
}
