import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { CreateEditSubVendorComponent } from './create-edit-sub-vendor/create-edit-sub-vendor.component';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
export interface PeriodicElement {
  name: string;
  email: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {
    name:
      'Carla Kenter',
    email: '7/18/2024 03:32PM',
  },
  {
    name:
      'Carla Kenter',
    email: '7/18/2024 03:32PM',
  },
  {
    name:
      'Carla Kenter',
    email: '7/18/2024 03:32PM',
  },
  {
    name:
      'Carla Kenter',
    email: '7/18/2024 03:32PM',
  },
  {
    name:
      'Carla Kenter',
    email: '7/18/2024 03:32PM',
  },
  {
    name:
      'Carla Kenter',
    email: '7/18/2024 03:32PM',
  },
  {
    name:
      'Carla Kenter',
    email: '7/18/2024 03:32PM',
  },
  {
    name:
      'Carla Kenter',
    email: '7/18/2024 03:32PM',
  },
  {
    name:
      'Carla Kenter',
    email: '7/18/2024 03:32PM',
  },
  {
    name:
      'Carla Kenter',
    email: '7/18/2024 03:32PM',
  },
];

@Component({
  selector: 'app-sub-vendor-management',
  standalone: true,
  imports: [
    CommonModule,
    CreateEditSubVendorComponent,
    MatTableModule,
    MatButtonModule,
    MatTooltipModule
  ],
  templateUrl: './sub-vendor-management.component.html',
  styleUrls: ['./sub-vendor-management.component.scss']
})
export class SubVendorManagementComponent implements OnInit {
  displayedColumns: string[] = ['name','email', 'action'];
  dataSource = ELEMENT_DATA;
  constructor() { }

  ngOnInit() {
  }

}
