<mat-dialog-content class="text-center">
  <div class="block text-grey-light mb-24">
    <h3 class="h1 mb-24 text-white">Purchase Order #12345</h3>
  </div>

  <div class="order-gridinfo order-grid3 text-left mb-24">
    <div>
      <div class="text-grey-light fs-16 orderinfo-bx">
        <span class="d-block mb-4 fs-14">Date</span>
        <span class="text-grey-medium text-truncate fw-bold">Food</span>
      </div>
    </div>
    <div>
      <div class="text-grey-light fs-16 orderinfo-bx">
        <span class="d-block mb-4 fs-14">Vendor Name</span>
        <span class="text-grey-medium text-truncate fw-bold">Makenna Schleifer</span>
      </div>
    </div>
    <div>
      <div class="text-grey-light fs-16 orderinfo-bx">
        <span class="d-block mb-4 fs-14">Title</span>
        <span class="text-grey-medium text-truncate fw-bold">Butter</span>
      </div>
    </div>
    <div>
      <div class="text-grey-light fs-16 orderinfo-bx">
        <span class="d-block mb-4 fs-14">Purchase Status</span>
        <span class="badge badge-light-danger badge-small">Not Billed</span>
      </div>
    </div>
    <div>
      <div class="text-grey-light fs-16 orderinfo-bx">
        <span class="d-block mb-4 fs-14">Total Amount</span>
        <span class="text-grey-medium text-truncate fw-bold">$540.55</span>
      </div>
    </div>
  </div>
  
  <button mat-flat-button mat-dialog-close class="btn-outline-primary w-full">Edit</button>
  
</mat-dialog-content>