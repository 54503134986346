import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { InvoiceService } from '../service/invoice.service';
import { catchError, throwError } from 'rxjs';
import { ResponseMessage } from '../../../../model/interfaces/req.res.interface';
import { ConstantService } from '../../../../shared/services/constant.service';
import { ToastrService } from 'ngx-toastr';
import { get, param } from 'jquery';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_LOCALE, provideNativeDateAdapter } from '@angular/material/core';
import { MenuService } from '../../menu/services/menu.service';
import { EncryptionService } from '../../../../shared/services/encryption.service';
import { FormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
export interface PeriodicElement {
  date: any;
  customerName: string;
  id: string;
  title: string;
  status: string,
  customerInvoice: number,
  amount: string
}

const ELEMENT_DATA: PeriodicElement[] = [
  {
    date: '7/18/2024',
    customerName: 'Alfredo Levin',
    id: '#12345',
    title: 'Lorem ipsum dolor',
    status: 'Paid',
    customerInvoice: 48,
    amount: '4,099'
  },
  {
    date: '7/18/2024',
    customerName: 'Alfredo Levin',
    id: '#12345',
    title: 'Lorem ipsum dolor',
    status: 'Paid',
    customerInvoice: 48,
    amount: '4,099'
  },
  {
    date: '7/18/2024',
    customerName: 'Alfredo Levin',
    id: '#12345',
    title: 'Lorem ipsum dolor',
    status: 'Paid',
    customerInvoice: 48,
    amount: '4,099'
  },
  {
    date: '7/18/2024',
    customerName: 'Alfredo Levin',
    id: '#12345',
    title: 'Lorem ipsum dolor',
    status: 'Paid',
    customerInvoice: 48,
    amount: '4,099'
  },
  {
    date: '7/18/2024',
    customerName: 'Alfredo Levin',
    id: '#12345',
    title: 'Lorem ipsum dolor',
    status: 'Paid',
    customerInvoice: 48,
    amount: '4,099'
  },
  {
    date: '7/18/2024',
    customerName: 'Alfredo Levin',
    id: '#12345',
    title: 'Lorem ipsum dolor',
    status: 'Paid',
    customerInvoice: 48,
    amount: '4,099'
  },
  {
    date: '7/18/2024',
    customerName: 'Alfredo Levin',
    id: '#12345',
    title: 'Lorem ipsum dolor',
    status: 'Paid',
    customerInvoice: 48,
    amount: '4,099'
  },
  {
    date: '7/18/2024',
    customerName: 'Alfredo Levin',
    id: '#12345',
    title: 'Lorem ipsum dolor',
    status: 'Paid',
    customerInvoice: 48,
    amount: '4,099'
  },
  {
    date: '7/18/2024',
    customerName: 'Alfredo Levin',
    id: '#12345',
    title: 'Lorem ipsum dolor',
    status: 'Paid',
    customerInvoice: 48,
    amount: '4,099'
  },
  {
    date: '7/18/2024',
    customerName: 'Alfredo Levin',
    id: '#12345',
    title: 'Lorem ipsum dolor',
    status: 'Paid',
    customerInvoice: 48,
    amount: '4,099'
  },
  {
    date: '7/18/2024',
    customerName: 'Alfredo Levin',
    id: '#12345',
    title: 'Lorem ipsum dolor',
    status: 'Paid',
    customerInvoice: 48,
    amount: '4,099'
  },
  {
    date: '7/18/2024',
    customerName: 'Alfredo Levin',
    id: '#12345',
    title: 'Lorem ipsum dolor',
    status: 'Paid',
    customerInvoice: 48,
    amount: '4,099'
  },
];

@Component({
  selector: 'app-invoice-list',
  standalone: true,
  providers: [provideNativeDateAdapter(), EncryptionService, DatePipe,{provide: MAT_DATE_LOCALE, useValue: 'en-GB'}],
  imports: [
    DatePipe,
    MatButtonModule,
    RouterModule,
    MatTableModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    TranslateModule,
    InfiniteScrollDirective,
    FormsModule
  ],
  templateUrl: './invoice-list.component.html',
  styleUrl: './invoice-list.component.scss'
})
export class InvoiceListComponent {
  invoiceService = inject(InvoiceService);
  constantService = inject(ConstantService);
  encDecObj = inject(EncryptionService);
  menuService = inject(MenuService);
  toastr = inject(ToastrService);
  datePipe = inject(DatePipe);
  route = inject(Router);
  invoiceList: any = [];
  pageNumber: any = 1;
  totalRecord: any;
  filterProductId: any;
  productList: any = [];
  filterVendorId: any;
  vendorList: any = [];
  fromDate: any;
  toDate: any;
  searchText: any;
  filterBranchLocationId: any;
  branchLocationList: any = this.constantService.branchList;
  dataSourceInvoice: any;
  sortBy: any;
  dateSelectedFrom: any;
  dateSelectedTo: any;
  draftAmount: any;
  last30DayAmount: any;
  invoiceListPayload: any = {
    page: 1,
    per_page: 15,
    sort_by: 1,
    search: '',
    user_id: '',
    item_id: '',
    from_date: '',
    to_date: '',
    user_business_location_id: ''
  }
  selectedBranchLocation = 'select_business_location';
  selectedBranchLocationId = 0;
  displayedColumns: string[] = ['date', 'customerName', 'id', 'title', 'status', 'amount', 'action'];
  dataSource = ELEMENT_DATA;
  toDay = new Date()
  getInvoiceList() {
    this.pageNumber = 1;
    this.invoiceService.getInvoiceList(this.invoiceListPayload).pipe(catchError((err: any) => {
      this.constantService.progressLoader.set(false);
      return throwError(() => err.error.message)
    }
    )).subscribe((res: ResponseMessage) => {
      if (res.code == 200) {
        this.invoiceList = res.data.data;
        this.last30DayAmount = res.data.paidAmount;
        this.draftAmount = res.data.draftAmount;
        this.last30DayAmount = parseFloat(Math.round(+this.last30DayAmount * 100) / 100 as any).toFixed(2)
        this.draftAmount = parseFloat(Math.round(+this.draftAmount * 100) / 100 as any).toFixed(2)
        this.totalRecord = res.data.pagination.total;
        this.dataSourceInvoice = new MatTableDataSource(res.data.data);
        this.constantService.progressLoader.set(false);
      } else {
        this.constantService.progressLoader.set(false);
        this.toastr.error(res.message);
      }
    })
  }
  ngOnInit() {
    this.constantService.progressLoader.set(true);
    this.getInvoiceList();
    if (localStorage.getItem(this.selectedBranchLocation)) {
      const id = this.encDecObj.getDecrypt(localStorage.getItem(this.selectedBranchLocation));
      this.selectedBranchLocationId = Number(id);
      this.getProductList(this.selectedBranchLocationId);
    }
    this.getVendorList();
  }
  getVendorList() {
    this.constantService.progressLoader.set(true);
    this.invoiceService.getVendorList().pipe(
      catchError((err: any) => {
        this.constantService.progressLoader.set(false);
        return throwError(() => err.error.message)
      })).subscribe((res: ResponseMessage) => {
        if (res.code == 200) {
          this.constantService.progressLoader.set(false);
          this.vendorList = res.data;
        } else {
          this.constantService.progressLoader.set(false);
          this.toastr.error(res.message);
        }
      })
  }
  InvoiceListScrollDown() {
    this.pageNumber += this.pageNumber;
    this.invoiceListPayload.page = this.pageNumber;
    this.constantService.progressLoader.set(true);
    this.invoiceService.getInvoiceList(this.invoiceListPayload).pipe(catchError((err: any) => {
      this.constantService.progressLoader.set(false);
      return throwError(() => err.error.message)
    }
    )).subscribe((res: ResponseMessage) => {
      if (res.code == 200) {
        this.invoiceList = [...this.invoiceList, ...res.data.data];
        this.totalRecord = res.data.pagination.total;
        this.dataSourceInvoice = new MatTableDataSource(this.invoiceList);

        this.constantService.progressLoader.set(false);
      } else {
        this.constantService.progressLoader.set(false);
        this.toastr.error(res.message);
      }
    })
  }
  getProductList(branchId: any) {
    let data = {
      business_location_id: branchId
    }
    this.constantService.progressLoader.set(true);
    this.menuService.getProduct(data).pipe(catchError((err) => {
      this.constantService.progressLoader.set(false);
      return throwError(() => err.error.message);
    })).subscribe((res: ResponseMessage) => {
      if (res.code == 200) {
        this.constantService.progressLoader.set(false);
        this.productList = res.data.data
      } else {
        this.toastr.error(res.message);
        this.constantService.progressLoader.set(false);
      }
    })
  }
  dateChange(key: any) {
    if (key == 'from') {
      this.dateSelectedFrom = this.datePipe.transform(new Date(this.fromDate), 'YYYY-MM-dd');
      this.invoiceListPayload.from_date = this.dateSelectedFrom;
    } else if (key == 'to') {
      this.dateSelectedTo = this.datePipe.transform(new Date(this.toDate), 'YYYY-MM-dd');
      this.invoiceListPayload.to_date = this.dateSelectedTo;
    }
    if (!this.invoiceListPayload.from_date) {
      return;
    }
    this.getInvoiceList();
  }
  filterBy(params: any) {
    if (params == 'product') {
      this.invoiceListPayload.item_id = this.filterProductId;
    } else if (params == 'vendor') {
      this.invoiceListPayload.user_id = this.filterVendorId;
    } else if (params == 'branch') {
      this.invoiceListPayload.user_business_location_id = this.filterBranchLocationId;
    } else if (params == 'sortby') {
      this.invoiceListPayload.sort_by = this.sortBy;
    }
    this.getInvoiceList();
  }

  searchByText() {
    this.searchText = this.searchText.trimStart();
    const searchText = this.searchText.trim();
    if (searchText !== this.invoiceListPayload.search) {
      if (searchText && searchText.length > 1) {
        this.invoiceListPayload.search = searchText;
        this.getInvoiceList();
      } else if (!searchText && this.invoiceListPayload.search !== '') {
        this.invoiceListPayload.search = '';
        this.getInvoiceList();
      }
    }
  }
  ediInvoice(id: any) {
    this.route.navigate(['/invoice/edit', id])
  }
  resetFilters() {
    if (!this.filterBranchLocationId && !this.filterProductId && !this.filterVendorId && !this.toDate && !this.fromDate && !this.sortBy && !this.searchText) {
      return;
    }
    this.invoiceListPayload = {
      page: 1,
      per_page: 15,
      sort_by: 1,
      search: '',
      user_id: '',
      item_id: '',
      from_date: '',
      to_date: '',
      user_business_location_id: ''
    }
    this.filterBranchLocationId = '';
    this.filterProductId = '';
    this.filterVendorId = '';
    this.toDate = '';
    this.fromDate = '';
    this.sortBy = '';
    this.searchText = '';
    this.getInvoiceList();
  }
}
