<div class="section-box mb-24 flex flex-wrap flex-justify-between flex-align-center header-section gap-8px backbtn-wrap">
  <h1 class="text-white mb-0 text-truncate">Create Purchase Order</h1>
  
    <a mat-flat-button class="btn-outline-secondary backbtn mobile-full" routerLink="/purchase-order">
      <svg width="7" height="15" viewBox="0 0 7 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.2876 13.4386L1.3976 8.54859C0.820097 7.97109 0.820097 7.02609 1.3976 6.44859L6.2876 1.55859" stroke="currentColor" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      Back</a>
  
</div>

<div class="section-box block edit-formbox">
  <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-20">
    <mat-label class="fs-14">Purchase Title</mat-label>
    <input matInput>
  </mat-form-field>
  <div class="grid-2">
    <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-20">
      <mat-label class="fs-14">Purchase ID</mat-label>
      <input matInput disabled value="#12345">
    </mat-form-field>
    <mat-form-field subscriptSizing="dynamic" appearance="outline" class="filter-date mb-20">
      <mat-label class="fs-14">Purchase Date</mat-label>
      <div class="w-full relative input-rightspace">
        <input matInput [matDatepicker]="picker2" >
        <mat-datepicker-toggle matIconSuffix [for]="picker2" class="ic-input cursor-pointer"></mat-datepicker-toggle>
      </div>
      <mat-datepicker #picker2></mat-datepicker>
    </mat-form-field>
  </div>
  <div class="mb-20 fs-14">
    <label
      id="example-radio-group-label"
      class="text-grey-medium d-block mb-10 fs-14 form-label"
      >Purchase Send Option</label
    >
    <mat-radio-group aria-label="Select an option">
      <mat-radio-button value="1">One Time</mat-radio-button>
      <mat-radio-button value="2">Recurring</mat-radio-button>
    </mat-radio-group>
  </div>
  <div class="grid-2">
    <div class="mb-20 fs-14">
      <label
        id="example-radio-group-label"
        class="text-grey-medium d-block mb-10 fs-14 form-label"
        >Choose Recurring Option</label
      >
      <mat-radio-group aria-label="Select an option">
        <mat-radio-button value="1">Weekly</mat-radio-button>
        <mat-radio-button value="2">Monthly</mat-radio-button>
        <mat-radio-button value="3">Quarterly</mat-radio-button>
        <mat-radio-button value="4">Yearly</mat-radio-button>
        <mat-radio-button value="5">Custom</mat-radio-button>
      </mat-radio-group>
    </div>
    <mat-form-field subscriptSizing="dynamic" appearance="outline" class="filter-date mb-20">
      <mat-label class="fs-14">Select Date</mat-label>
      <div class="w-full relative input-rightspace">
        <input matInput [matDatepicker]="picker3" >
        <mat-datepicker-toggle matIconSuffix [for]="picker3" class="ic-input cursor-pointer"></mat-datepicker-toggle>
      </div>
      <mat-datepicker #picker3></mat-datepicker>
    </mat-form-field>
  </div>
  <div class="text-end">
    <button mat-flat-button class="btn-lg mobile-full" routerLink="/purchase-order/add-lines-product">Next</button>
  </div>
</div>