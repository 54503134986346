import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioGroup, MatRadioModule } from '@angular/material/radio';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-add-lines-product',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    RouterModule,
    MatButtonModule,
    MatRadioGroup,
    MatRadioModule
  ],
  templateUrl: './add-lines-product.component.html',
  styleUrl: './add-lines-product.component.scss'
})
export class AddLinesProductComponent {

}
