<div class="section-box mb-24">
  <div class="category-filter flex gap-16px custom-scroll">
    <button *ngFor="let item of menuItems" type="button" class="category-filterbtn flex flex-align-center flex-shrink-0"
      [ngClass]="{'active': menuType === item.type}" (click)="selectSettingMenu(item.type)"
      [attr.aria-label]="item.label">
      <span class="product-icon">
        <img [src]="item.icon" [alt]="item.altText" />
        <!-- assets/images/food-image/star.png -->
      </span>
      {{ item.labelTransalte |translate }}
    </button>
  </div>
</div>


@if (menuType == 1) {
<app-image-library></app-image-library>
} @else if (menuType == 2) {
@if(isShowList){
<app-manage-category></app-manage-category>
} @else {
<app-add-category></app-add-category>
}
}@else if (menuType == 3) {
<app-profile></app-profile>
}@else if (menuType == 4) {
<!-- <app-sub-vendor-management></app-sub-vendor-management> -->
<app-under-development></app-under-development>
}@else if (menuType == 5) {
<app-under-development></app-under-development>
}